import * as Yup from "yup";
import { CorporateButton } from "Components/Buttons/CorporateButton";
import { MobileInput } from "Components/Inputs/MobileInput";
import { TextFieldInput } from "Components/Inputs/TextField";
import { InputMessage } from "Components/Messages/InputValidations/InputMessage";
import { Formik, useFormik } from "formik";
import { memo, useState } from "react";
import { ReactSVG } from "react-svg";
import { API_REQUEST } from "Request/API";
import { toast } from "react-toastify";

const Contact = () => (
  <>
    <div>
      <div className="mb-[8px] flex items-center justify-center w-[52px] h-[52px] rounded-[50%] bg-blue-10">
        <ReactSVG src="/images/icons/contactphone.svg" />
      </div>
      <h3 className="text-lg md:text-rg font-bold leading-[25px] mb-[8px] md:mb-[4px]">
        ტელეფონის ნომერი
      </h3>
      <span className="text-lg md:text-rg font-medium text-blackPrimary-60">
        032 218 55 77
      </span>
    </div>
    <div>
      <div className="mb-[8px] flex items-center justify-center w-[52px] h-[52px] rounded-[50%] bg-blue-10">
        <ReactSVG src="/images/icons/contactmail.svg" />
      </div>
      <h3 className="text-lg md:text-rg font-bold leading-[25px] mb-[8px] md:mb-[4px]">
        ელ-ფოსტა
      </h3>
      <span className="text-lg md:text-rg font-medium text-blackPrimary-60">
        Info@vitaapp.ge
      </span>
    </div>
  </>
);

export const ContactUs = memo(function ContactUsComponent({
  contactUsRef,
  type = "corp",
}: any) {
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      position: "",
      mail: "",
      companyName: "",
      companyIdentificationCode: "",
      comment: "",
      phone: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("სახელი აუცილებელია"),
      lastName: Yup.string().required("გვარი აუცილებელია"),
      position: Yup.string().required("პოზიცია აუცილებელია"),
      mail: Yup.string().required("მეილი აუცილებელია"),
      phone: Yup.number()
        .typeError("ნომერი უნდა შეიცავდეს მხოლოდ ციფრებს")
        .required("ტელეფონი აუცილებელია"),
      companyName: Yup.string().required("კომპანიის სახელი აუცილებელია"),
      companyIdentificationCode: Yup.string().required(
        "კომპანიის საიდენტიფიკაციო კოდი აუცილებელია"
      ),
    }),
    onSubmit: (values, { resetForm }) => {
      API_REQUEST(
        "POST",
        "clinic/sendFilledContactForm",
        values,
        null,
        "fullresponse"
      )
        .then(() => {
          toast("მესიჯი წარმატებიტ გაიგზავნა", {
            type: "success",
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            theme: "success",
          });
          resetForm();
        })
        .catch(() =>
          toast("მესიჯი ვერ გაიგზავნა", {
            type: "error",
            position: "bottom-right",
            autoClose: 50000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            theme: "light",
          })
        );
    },
  });
  return (
    <div
      ref={contactUsRef}
      className="container sm:max-w-full sm:w-full lg:block flex gap-[30px] mb-[170px] lg:mb-[60px] sm:mb-[26px]"
    >
      <div className="lg:hidden">
        <h3 className="font-bold z-10 text-sloganSize text-blackPrimary-100 relative after:content-[''] after:absolute after:top-0 after:left-0 after:bg-h2lineCorp after:w-full after:h-[83px] after:bg-contain after:bg-no-repeat">
          მოგვწერე
        </h3>
        <h2 className="font-regular text-sloganSize text-blackPrimary-100 mb-[30px]">
          გახდი{" "}
          <span className="text-raspberry-100 font-bold">ვიტა ბარათის</span>{" "}
          {type !== 'corp' ? 'პროვაიდერი' : 'მფლობელი'}
        </h2>
        <div>
          <p className="font-medium text-lg text-blackPrimary-60 leading-[25px] mb-[44px]">
            {type == "corp" ? (
              <>
                კორპორატიული VITA ბარათის თანამშრომლებისთვის შეძენისთვის
                დაგვიკავშირდით ცხელ ხაზზე ან ელ-ფოსტაზე.
                <br />
                ასევე , შეგიძლიათ შეავოთ ფორმა და გამოგვიგზავნოთ თქვენი
                საკონტაქტო ინფორმაცია, რომელზეც ჩვენ დაგიკავშირდებით.
              </>
            ) : (
              <>
                ერთად წავახალისოთ ჯანმრთელობაზე პრევენციულად ზრუნვის ახალი ქცევა
                ფართო აუდიტორიაში, რითაც უფრო ჯანსაღ საზოგადოებას შევქმნით!
              </>
            )}
          </p>
          <div className="h-[3px] w-[100px] bg-gray-100"></div>
          <div className="flex gap-[30px] mt-[44px]">
            <Contact />
          </div>
        </div>
      </div>
      <div className="bg-gray-30 p-[44px] lg:mb-4 sm:p-[16px] min-w-[616px] w-[616px] lg:w-full lg:min-w-full rounded-[12px] sm:rounded-none">
        <h2 className="text-blackPrimary-100 font-bold text-[24px] sm:text-[20px] mb-[34px]">
          შეავსე საკონტაქტო ფორმა
        </h2>
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col gap-5">
            <div className="flex gap-4">
              <div className="w-full">
                <TextFieldInput
                  name="firstName"
                  label="სახელი*"
                  className="bg-[#fff!important]"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <InputMessage message={formik.errors.firstName} />
                ) : null}
              </div>
              <div className="w-full">
                <TextFieldInput
                  name="lastName"
                  label="გვარი*"
                  className="bg-[#fff!important]"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.firstName && formik.errors.lastName ? (
                  <InputMessage message={formik.errors.lastName} />
                ) : null}
              </div>
            </div>
            <div className="flex gap-4">
              <div className="w-full">
                <TextFieldInput
                  name="position"
                  label="პოზიცია*"
                  className="bg-[#fff!important]"
                  value={formik.values.position}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.position && formik.errors.position ? (
                  <InputMessage message={formik.errors.position} />
                ) : null}
              </div>
              <div className="w-full">
                <TextFieldInput
                  name="phone"
                  label="ტელეფონი*"
                  className="bg-[#fff!important]"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <InputMessage message={formik.errors.phone} />
                ) : null}
              </div>
            </div>
            <div>
              <TextFieldInput
                name="mail"
                label="ელ.ფოსტა*"
                className="bg-[#fff!important]"
                value={formik.values.mail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.mail && formik.errors.mail ? (
                <InputMessage message={formik.errors.mail} />
              ) : null}
            </div>
            <div>
              <TextFieldInput
                name="companyName"
                label="კომპანიის სახელი*"
                className="bg-[#fff!important]"
                value={formik.values.companyName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.companyName && formik.errors.companyName ? (
                <InputMessage message={formik.errors.companyName} />
              ) : null}
            </div>
            <div>
              <TextFieldInput
                name="companyIdentificationCode"
                label="კომპანიის საიდენტიფიკაციო კოდი*"
                className="bg-[#fff!important]"
                value={formik.values.companyIdentificationCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.companyIdentificationCode &&
              formik.errors.companyIdentificationCode ? (
                <InputMessage
                  message={formik.errors.companyIdentificationCode}
                />
              ) : null}
            </div>
            <textarea
              name="comment"
              value={formik.values.comment}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="p-[20px] h-[137px] rounded-[12px] font-medium text-blackPrimary-60 outline-none border-none"
              placeholder="კომენტარი"
            ></textarea>
          </div>
          <div className="flex justify-end mt-[32px]">
            <CorporateButton
              className="sm:w-full"
              icon={
                <ReactSVG
                  className="svgfiltervol2"
                  src="/images/icons/explore.svg"
                />
              }
              label="გაგზავნა"
            />
          </div>
        </form>
      </div>
      <div className="hidden lg:flex px-[16px] lg:gap-4 md:gap-2">
        <Contact />
      </div>
    </div>
  );
});
