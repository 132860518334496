import { CorporateButton } from "Components/Buttons/CorporateButton";
import { memo } from "react";
import { ReactSVG } from "react-svg";

export const CorporateFeature = memo(function CorporateFeatureComponent({
  contactUsRef,
}: any) {
  return (
    <div className="bg-corporateBg sm:bg-corporateMobileBg bg-no-repeat bg-cover w-full h-[calc(100dvh-102px)] flex items-center">
      <div className="container max-w-[1260px] lg:px-[64px] md:px-[32px] sm:px-[16p] flex flex-col items-center">
        <div className="flex flex-col items-center mb-[68px] md:mb-[40px] gap-2">
          <h1 className="font-bold md:flex md:flex-wrap md:justify-center md:gap-3 sm:gap-2 text-[#fff] text-[40px] lg:text-sloganLgSize md:text-sloganMdSize leading-[58px] md:leading-6">
            VITA
            <span className="text-yellow relative ml-[6px] mr-[6px] md:ml-0 md:mr-0">
              კორპორატიული
              <ReactSVG
                className="absolute bottom-[-35px] left-0 md:hidden"
                src="/images/corporateh2line.svg"
              />
            </span>
            ბენეფიტი
          </h1>
        </div>
        <p className="font-medium text-lg md:text-rg text-[#fff] leading-[25.5px] mb-12 text-center">
          კომპანიებისა და ორგანიზაციებისთვის VITA არის თანამშრომლის კორპორატიული
          ბენეფიტი, რომლის საშუალებითაც ისინი ზრუნავენ თანამშრომლების ფიზიკურ,
          მენტალურ და ესთეტიკურ ჯანმრთელობაზე, რაც შემდგომში თანამშრომელთა
          მოტივაციისა და შრომის ნაყოფიერების მომატებაზე აისახება და ხელს უწყობს
          ორგანიზაციაში ჯანსაღი სამუშაო კულტურის შექმნას.
        </p>
        <CorporateButton
          onClick={() =>
            contactUsRef?.current?.scrollIntoView({
              behavior: "smooth",
              block: "end",
            })
          }
          className="sm:w-full"
          label="როგორ შევიძინო"
          icon={
            <ReactSVG
              className="svgfiltervol2"
              src="/images/icons/explore.svg"
            />
          }
        />
      </div>
    </div>
  );
});
