import { memo } from "react";

export const SectionHeader = memo(function SectionHeaderComponent({
  title,
}: {
  title: string;
}) {
  return (
    <h2 className="sm:mt-[16px] text-[18px] md:text-rg md:mt-4 font-bold text-blackPrimary-100 mb-[10px] md:mb-0">
      {title}
    </h2>
  );
});
