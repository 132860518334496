import { ContactUs } from "Components/PageSections/Corporate/ContactUs/ContactUs";
import { SatisfiedUsers } from "Components/PageSections/Corporate/SatisfiedUsers/SatisfiedUsers";
import { MedBenefit } from "Components/PageSections/MedSector/MedBenefit/MedBenefit";
import { MedSectorFeature } from "Components/PageSections/MedSector/MedSectorFeature/MedSectorFeature";
import { VitaForMedSector } from "Components/PageSections/MedSector/VitaForMedSector/VitaForMedSector";
import { useRef } from "react";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";

export const MedSector = () => {
  const contactUsRef = useRef(null);
  return (
    <>
          <Helmet>
        <title>
        VITA  პროვაიდერი კლინიკებისთვის | შეთავაზება სამედიცინო სექტორს
        </title>
        <meta
          name="description"
          content="ვიტა მუდმივად ასტიმულირებს თავის მომხმარებელს, რათა მათ აქტიურად გამოიყენონ პრევენციული მედიცინა და ჯანმრთელობის შენარჩუნებისთვის აუცილებელი მომსახურებები და პროდუქტები და განახორციელონ ვიზიტები სამედიცინო, სტომატოლოგიურ და ესთეტიკურ კლინიკებსა და აფთიაქებში."
        />
        {/* <link rel="canonical" href="http://vitaapp.ge/medsector" /> */}
      </Helmet>
      <ToastContainer />
      <MedSectorFeature contactUsRef={contactUsRef} />
      <VitaForMedSector />
      <MedBenefit />
      {/* <SatisfiedUsers title="კმაყოფილი პროვაიდერი ორგანიზაციის წარმომადგენელი" /> */}
      <ContactUs type="medsector" contactUsRef={contactUsRef} />
    </>
  );
};
