import { memo } from "react";

export const OffersMobileCard = memo(function OffersMobileCardComponent({
  title,
  discount,
}: {
  title: string;
  discount: number;
}) {
  return (
    <div className="rounded-[12px] md:w-full sm:w-[220px] min-w-[220px] bg-gray-30 p-4 text-rg font-bold overflow-hidden flex flex-col gap-2 items-start">
      <h2>{title}</h2>
      <span className="text-raspberry-100 font-bold text-rg bg-raspberry-10 rounded-[8px] px-3 py-2">
        {discount} %
      </span>
    </div>
  );
});
