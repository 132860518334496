import { Modal } from "@mui/material";
import { ServiceMobileListItem } from "Components/Cards/ServiceMobileListItem";
import { SearchClinic } from "Components/Search/SearchClinic";
import { useSubServicesQuery } from "Hooks/Queries/useSubServicesQuery";
import { memo, useEffect, useRef, useState } from "react";
import { ReactSVG } from "react-svg";
import { BasicModalTypes } from "types/MembersModal";

export const ServiceModal = memo(function ServiceModalComponent({
  open,
  close,
  state,
  slug,
  clinic,
  serviceName,
}: BasicModalTypes & any) {
  const containerRef = useRef(null);
  const [page, setPage] = useState(1);
  const [allSubServices, setAllSubServices] = useState<any[]>([]);
  const { subServices, isSuccessSubServices } = useSubServicesQuery(
    state,
    slug,
    10,
    page - 1
  );

  const clearData = () => {
    setPage(1);
    setAllSubServices([]);
  };

  useEffect(() => {
    if (subServices && isSuccessSubServices) {
      if (page === 1) {
        setAllSubServices(subServices?.content);
      } else {
        setAllSubServices((prev) => [...prev, ...subServices?.content]);
      }
    }
  }, [subServices, isSuccessSubServices, page]);

  const fetchNextPage = () => setPage((prev) => prev + 1);

  return (
    <>
      <div ref={containerRef}></div>
      <Modal
        open={open}
        onClose={() => {
          close();
          clearData();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        container={containerRef.current}
        className="h-[100dvh] z-[99999] fixed w-full overflow-y-auto bg-[#F5F6FA] top-0 left-0"
      >
        <div className="h-[100dvh] z-40 w-full overflow-y-auto bg-[#F5F6FA]">
          <div className="flex justify-between items-center p-4 bg-[#fff]">
            <h2 className="font-bold text-md text-blackPrimary-100">
              {serviceName}
            </h2>
            <div
              onClick={() => {
                clearData();
                close();
              }}
              className="cursor-pointer bg-gray-100 rounded-[8px] w-[36px] h-[36px] flex items-center justify-center"
            >
              <ReactSVG src="/images/icons/close.svg" />
            </div>
          </div>
          <div>
            {/* <SearchClinic /> */}
            <div className="px-[16px] flex flex-col gap-3 mt-[20px]">
              {allSubServices?.map((service: any, i: number) => (
                <ServiceMobileListItem
                  clinic={clinic}
                  service={service}
                  key={i}
                />
              ))}
            </div>
            {subServices?.totalPages > page && (
              <button
                className="text-center p-8 w-full"
                onClick={fetchNextPage}
              >
                მეტის ჩატვირთვა
              </button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
});
