import { memo } from "react";

export const SectionTitle = memo(function SectionTitleComponent({
  title,
}: {
  title: string;
}) {
  return (
    <h2 className="font-bold text-sloganSize lg:text-sloganLgSize md:text-sloganMdSize leading-[56px] lg:leading-[36px] md:leading-[26px] text-blackPrimary-100 mb-[34px]">
      {title}
    </h2>
  );
});
