import { useQuery } from "@tanstack/react-query";
import { API_REQUEST } from "Request/API";

export const useUserQuery = (isLogin: boolean) => {
  const {
    data: user,
    isError: isErrorUser,
    isLoading: isLoadingUser,
    isSuccess: isSuccessUser,
    refetch,
  } = useQuery({
    queryKey: [`USER`],
    queryFn: () => API_REQUEST("GET", `user`),
    retry: 0,
    enabled: isLogin == true,
    refetchOnWindowFocus: false,
  });

  return {
    user,
    isErrorUser,
    isLoadingUser,
    isSuccessUser,
    refetch,
  };
};
