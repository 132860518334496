import { memo, useRef } from "react";
import { Modal } from "@mui/material";
import { ReactSVG } from "react-svg";
import { VitaPercent } from "Components/Buttons/VitaPercent";
import { cats } from "JSON/PSP";
import { locations } from "Pages/Pharmacy/Bestpharma";

export const PharmacyLocation = memo(function PharmacyLocationComponent({
  open,
  close,
}: {
  open: boolean;
  close: () => void;
}) {
  const containerRef = useRef(null);
  return (
    <>
      <div ref={containerRef}></div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        container={containerRef.current}
        className="flex items-end justify-center"
      >
        <div className="w-[644px] rounded-t-[12px] sm:w-[100dvh] sm:max-w-full sm:max-h-[auto] sm:h-[auto] sm:bottom-0 overflow-hidden flex flex-col p-[24px] sm:p-[0] relative max-w-[90%] max-h-[90%] h-[604px] bg-[#fff] rounded-[12px] outline-none">
          <div className="mb-[14px] p-[12px] flex justify-between items-center">
            <h2 className="font-bold text-[20px] text-blackPrimary-100">
              ბესთ ფარმას ფილიალები
            </h2>
            <div
              onClick={close}
              className="cursor-pointer w-[44px] h-[44px] bg-gray-30 sm:bg-gray-30 rounded-[50%] flex items-center justify-center"
            >
              <ReactSVG src="/images/icons/close.svg" />
            </div>
          </div>
          <div className="customScrolllbar overflow-y-auto h-[100vh-40px]">
            {locations.map((item: any, index: number) => (
              <div
                key={index}
                className={`${index %2 == 0 ? 'bg-gray-30' : 'bg-[#fff]'}  rounded-[8px] px-[17px] py-[15px] flex items-center gap-2`}
              >
                <div className="bg-green-100 w-[30px] h-[30px] flex items-center justify-center rounded-[5px]">
                  <ReactSVG src="/images/icons/whitelocation.svg" />
                </div>
                <h3 className="text-rg text-blackPrimary-100">{item}</h3>
                <div className="w-[16px] h-[16px] ml-auto">
                  <ReactSVG src="/images/pharmacy/location.svg" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
});
