import { memo, useRef } from "react";
import { Modal } from "@mui/material";
import { Save } from "Components/Buttons/Save";
import { ReactSVG } from "react-svg";
import dayjs from "dayjs";
import "dayjs/locale/ka"; // Import Georgian locale

export const CongratsPaymentModal = memo(
  function CongratsPaymentModalComponent({
    open,
    close,
  }: {
    open: boolean;
    close: () => void;
  }) {
    const containerRef = useRef(null);
    const lastPaymentDate = dayjs();

    // Calculate the next payment date by adding 1 month
    const nextPaymentDate = lastPaymentDate.add(1, "month");

    // Format the date to "DD MMMM" (e.g., "10 August") in Georgian
    const formattedDate = nextPaymentDate.locale("ka").format("DD MMMM");

    return (
      <>
        <div ref={containerRef}></div>
        {open && (
          <div className="w-full left-0 top-0 h-full fixed z-[9999] flex items-center justify-center bg-blackPrimary-30">
            <div
              onClick={close}
              className="w-full h-full z-[11] absolute"
            ></div>
            <div className="z-[100] relative max-w-[492px] bg-[#fff] rounded-[12px] w-full flex flex-col items-center py-[60px] gap-7 px-[42px]">
              <h1 className="relative z-20 font-bold text-blackPrimary-100 text-[24px]">
                გილოცავ !
              </h1>
              <h2 className="relative z-20 font-bold text-blackPrimary-60 text-center">
                შენ წარმატებით შეიძინე ვიტა ბარათი
              </h2>
              <ReactSVG src="/images/icons/partingface.svg" />
              {/* <span className="relative z-20 text-md font-medium text-blackPrimary-100">
                შემდეგი გადახდის თარიღი {formattedDate}
              </span> */}
              <Save onClick={close} variant="green" label="დახურვა" />
              <div className="absolute z-10 top-[50%] translate-y-[-40%] left-[50%] translate-x-[-50%] bg-modalbackground bg-contain w-[240px] h-[151px]"></div>
            </div>
          </div>
        )}
      </>
    );
  }
);
