import { useQuery } from "@tanstack/react-query";
import { API_REQUEST } from "Request/API";

export const useRegionsQuery = (slug: any, pageProps: any) => {
  const {
    data: regions,
    isError: isErrorRegions,
    isLoading: isLoadingRegions,
    isSuccess: isSuccessRegions,
  } = useQuery({
    queryKey: [`regions-${slug}`],
    queryFn: () =>
      API_REQUEST("GET", `clinic/region`, null, {
        slug: slug !== "clinics" ? slug : undefined,
      }),
    retry: 0,
    enabled: pageProps.isSlugReady,
    refetchOnWindowFocus: false,
  });

  return { regions, isLoadingRegions, isErrorRegions, isSuccessRegions };
};
