import { memo, useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { theme } from "./TextFieldTheme";
import { ThemeProvider } from "@emotion/react";
import { ReactSVG } from "react-svg";
import { DefaultOptions, SelectInputType } from "types/InputTypes";

export const SelectInputComponent = memo(function SelectInputComponent({
  label,
  value,
  options,
  onChange,
  className = "bg-[#fff!important]",
  name = "",
  ...rest
}: SelectInputType<DefaultOptions[]>) {
  const [state, setState] = useState<string>(value.toString());

  const handleChange = (event: SelectChangeEvent) => {
    setState(event.target.value);
    onChange && onChange(event.target.value);
  };

  useEffect(() => {
    setState(value.toString());
  }, [value]);

  return (
    <ThemeProvider theme={theme}>
      <FormControl
        className={className}
        variant="filled"
        sx={{ minWidth: 120 }}
        size="small"
      >
        <InputLabel className="mt-1" id="demo-select-small-label">
          {label && label}
        </InputLabel>
        <div className="pt-[-5px]"></div>
        <Select
          name={name}
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={state}
          label={label}
          onChange={handleChange}
          IconComponent={(props) => (
            <ReactSVG
              style={{
                marginRight: "10px",
                marginTop: "-2px",
                transition: "0.3s",
              }}
              src="/images/icons/selectArrow.svg"
              {...props}
            />
          )}
          placeholder="something"
          {...rest}
        >
          {options?.map(({ title, value }, index: number) => (
            <MenuItem key={index} value={value}>
              {title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
});
