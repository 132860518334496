import { memo, useRef } from "react";
import { Modal } from "@mui/material";
import { Save } from "Components/Buttons/Save";
import { ReactSVG } from "react-svg";

export const SavedCardPayment = memo(function SavedCardPaymentComponent({
  open,
  close,
}: {
  open: boolean;
  close: () => void;
}) {
  const containerRef = useRef(null);
  return (
    <>
      <div className="absolute" ref={containerRef}></div>
      {open && (
        <div className="w-full left-0 top-0 h-full fixed z-[9999] flex items-center justify-center bg-blackPrimary-30">
          <div onClick={close} className="w-full h-full z-[11] absolute"></div>
          <div className="relative max-w-[492px] bg-[#fff] rounded-[12px] w-full flex flex-col items-center py-[30px] gap-7 px-[42px]">
            <ReactSVG src="/images/icons/savedcardsuccess.svg" />
            <span className="relative z-20 text-md font-medium text-blackPrimary-100">
              ბარათი წარმატებით შეინახა
            </span>
            <Save onClick={close} variant="gray" label="დახურვა" />
          </div>
        </div>
      )}
    </>
  );
});
