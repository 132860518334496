import { memo } from "react";
import { ReactSVG } from "react-svg";

export const Stars = memo(function StarsComponent({
  rating,
}: {
  rating: number;
}) {
  const filledStars = rating;
  const emptyStars = 5 - rating;
  return (
    <div className="flex items-center gap-3">
      {Array.from({ length: filledStars })?.map((item, i) => (
        <ReactSVG key={i} src="/images/icons/filledstar.svg" />
      ))}
      {emptyStars !== 0 &&
        Array.from({ length: emptyStars })?.map((item, i) => (
          <ReactSVG key={`${i}-empty`} src="/images/icons/emptystar.svg" />
        ))}
    </div>
  );
});
