import { useQuery } from "@tanstack/react-query";
import { API_REQUEST } from "Request/API";

export const useCardInfoQuery = (active: boolean) => {
  const {
    data: cardInfo,
    isError: isErrorCardInfo,
    isLoading: isLoadingCardInfo,
    isSuccess: isSuccessCardInfo,
    refetch,
  } = useQuery({
    queryKey: [`USER_CARD_INFO`],
    queryFn: () => API_REQUEST("GET", `user/user-card-detail`),
    retry: 0,
    refetchOnWindowFocus: false,
    enabled: active,
  });

  return {
    cardInfo,
    isErrorCardInfo,
    isLoadingCardInfo,
    isSuccessCardInfo,
    refetch
  };
};
