import { useState } from "react";
import { ReactSVG } from "react-svg";
import useWindowSize from "Hooks/useWindowSize";
import { MedGroups } from "JSON/BESTPHARMA";
import { MedObjects } from "Components/Pharmacy/MedObjects";
import { MedicamentsGroup } from "Components/Modals/Pharmacy/MedicamentsGroup";
import { PharmacyLocation } from "Components/Modals/Pharmacy/PharmacyLocation";
import { PharmacyContact } from "Components/Modals/Pharmacy/PharmacyContact";
import { Link } from "react-router-dom";
import { BasicBreadcrumbs } from "Components/Breadcrumbs/Breadcrumbs";
import { useGetUserQuery } from "Reducers/ApiSlice";

export let locations = [
  "მარშალ იოზეფ პილსუდსკის გამზ. N5",
  "წმ. ქეთევან დედოფლის გამზირი N44",
  "ვარკეთილი , ჯავახეთის ქ. N91",
  "ვარკეთილი - 3  N403 კორპუსი",
];

export const Bestpharma = () => {
  const { width } = useWindowSize();
  const [medicPagination, setMedicPagination] = useState(false);
  // mobile modals
  const [isOpenMedicamentsGroup, setIsOpenMedicamentsGroup] = useState(false);
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const [isContactOpen, setIsContactOpen] = useState(false);
  const { error } = useGetUserQuery();

  // pharma
  const [medicamentsPagination, setMedicamentsPagination] = useState(false);

  return (
    <div className="container mt-3 sm:mt-2 md:mt-5 sm:px-[0] sm:w-full sm:max-w-full overflow-hidden">
      <BasicBreadcrumbs
        breadcrumbs={[
          {
            title: "აფთიაქები",
            link: "/clinics/aptiakebi",
          },
          {
            title: "ბესტფარმა-აფთიაქი",
            link: "/",
          },
        ]}
      />
      <div className="flex sm:px-[16px] mt-6 xl:flex-col gap-4">
        <div className="sm:flex-col items-center xl:h-auto bg-green-10 rounded-[20px] w-full p-[49px] sm:p-[32px] flex gap-9">
          <div className="w-[77px] h-[77px] min-w-[77px] min-h-[77px] rounded-[50%] overflow-hidden flex items-center justify-center">
            <img
              width={77}
              height={77}
              className="object-contain min-w-[77px] min-h-[77px]"
              src="/images/pharmacy/bestpharmalogo.svg"
              alt="psp"
            />
          </div>
          <div className="flex flex-col gap-[20px]">
            <p className="text-blackPrimary-100 font-medium text-md sm:text-center">
              <span className="font-bold">შპს "ბესთ ფარმა"</span> დაარსდა 2018
              წელს და წარმოადგენს მზარდ და განვითარებად სააფთიაქო ქსელს, რომელიც
              ზრუნავს მომხმარებელთა ჯანმრთელობასა და სილამაზეზე.
            </p>
          </div>
          <div className="min-w-[190px] items-end flex flex-col py-2 gap-3 pl-5 border-l-[1px] border-l-[#070A141A] border-solid">
            <div className="sm:hidden flex items-center gap-3 font-regular text-blackPrimary-100 text-rg">
              <ReactSVG src="/images/icons/phoneicon.svg" />+ 995 592 188 799
            </div>
            <div className="sm:hidden flex items-center gap-3 font-regular text-blackPrimary-100 text-rg">
              <ReactSVG src="/images/icons/phoneicon.svg" />+ 995 568 820 424
            </div>
          </div>
        </div>
      </div>
      {width > 640 && (
        <div className="grid mt-[32px] grid-cols-4 xl:grid-cols-2 gap-[30px]">
          {locations?.map((location, index) => (
            <div
              key={index}
              className="bg-gray-30 rounded-[8px] px-[17px] py-[15px] flex items-center gap-2"
            >
              <div className="bg-green-100 w-[30px] h-[30px] flex items-center justify-center rounded-[5px]">
                <ReactSVG src="/images/icons/whitelocation.svg" />
              </div>
              <h3 className="text-rg text-blackPrimary-100">{location}</h3>
            </div>
          ))}
        </div>
      )}
      {width <= 640 && (
        <>
          <MedicamentsGroup
            open={isOpenMedicamentsGroup}
            close={() => setIsOpenMedicamentsGroup(false)}
            data={MedGroups}
          />
          <PharmacyLocation
            open={isLocationModalOpen}
            close={() => setIsLocationModalOpen(false)}
          />
          <PharmacyContact
            open={isContactOpen}
            close={() => setIsContactOpen(false)}
            data={["+ 995 592 188 799", "+ 995 568 820 424"]}
            img={"/images/pharmacy/pharma.svg"}
          />
        </>
      )}
      {width <= 640 && (
        <div className="px-[16px] my-[20px]">
          <button
            onClick={() => setIsLocationModalOpen(true)}
            className="w-full rounded-[12px] flex items-center justify-between gap-3 py-[20px] px-[17px] bg-gray-30"
          >
            <ReactSVG src="/images/icons/location.svg" />
            <span className="flex flex-col items-start mr-auto">
              <span className="text-blackPrimary-100 font-demi text-rg">
                ბესთ ფარმას ფილიალები
              </span>
              <span className="text-raspberry-100 font-demi text-sm">
                იხილე სრული ჩამონათვალი
              </span>
            </span>
            <ReactSVG src="/images/icons/arrowright.svg" />
          </button>
        </div>
      )}
      <div className="mt-[48px]">
        <div>
          <div className="flex sm:px-[16px] mb-[14px] items-center gap-1">
            <ReactSVG
              beforeInjection={(svg) => {
                svg.setAttribute("style", "width: 36px; height: 36px;");
              }}
              className="sm:hidden"
              src="/images/icons/medicon.svg"
            />
            <h2 className="text-[24px] sm:text-rg font-demi text-blackPrimary-100">
              მედიკამენტების ჯგუფები
            </h2>
          </div>
          <span className="sm:px-[16px] text-md sm:text-rg font-demi text-blackPrimary-60 block mb-[30px]">
            VITA - ბარათის მომხმარებელს{" "}
            <span className="text-raspberry-100">-20%</span> იანი ფასდაკლება
            აქვს ყველა ჯგუფის მედიკამენტსა და სამედიცინო დანიშნულების საგნებზე.
          </span>
          {width > 640 && (
            <>
              <div className="grid grid-cols-4 customxlsize:grid-cols-2 md:grid-cols-1 md:gap-4 mt-[30px] gap-8">
                {MedGroups.map(
                  (item, index) =>
                    (medicamentsPagination ||
                      index < (width > 640 ? 8 : 4)) && (
                      <div
                        className="bg-gray-30 gap-3 flex items-center justify-between p-4 rounded-[12px]"
                        key={index}
                      >
                        <h2 className="font-demi mr-auto text-rg text-blackPrimary-100">
                          {item.title}
                        </h2>
                        <button className="bg-raspberry-100 text-rg p-[8px] rounded-[8px] text-[#fff] font-bold whitespace-nowrap">
                          -{item.discount}%
                        </button>
                      </div>
                    )
                )}
              </div>
              <div className="flex sm:justify-center justify-end my-[20px]">
                <button
                  onClick={() =>
                    setMedicamentsPagination(!medicamentsPagination)
                  }
                  className="outline-none border-none flex items-center gap-2 underline font-demi text-[20px] sm:text-md text-raspberry-100"
                >
                  {width < 640 ? (
                    <>{medicPagination ? "ნაკლები" : "ყველა"}</>
                  ) : (
                    <>
                      <ReactSVG src="/images/icons/eyered.svg" wrapper="span" />
                      {medicPagination ? "იხილე ნაკლები" : "იხილე მეტი"}
                    </>
                  )}
                </button>
              </div>
            </>
          )}
        </div>
        {width <= 640 && (
          <div className="px-[16px] my-[20px]">
            <button
              onClick={() => setIsOpenMedicamentsGroup(true)}
              className="w-full rounded-[12px] flex items-center justify-between gap-3 py-[20px] px-[17px] bg-raspberry-10"
            >
              <ReactSVG src="/images/icons/medicon.svg" />
              <span className="flex flex-col items-start mr-auto">
                <span className="text-blackPrimary-100 font-demi text-rg">
                  მედიკამენტების ჯგუფები
                </span>
                <span className="text-raspberry-100 font-demi text-sm">
                  იხილე სრული ჩამონათვალი
                </span>
              </span>
              <ReactSVG src="/images/icons/arrowright.svg" />
            </button>
          </div>
        )}
      </div>
      <div className="mt-[48px]">
        <div>
          <div className="mb-[44px]">
            <div className="sm:px-[16px] flex mb-[14px] sm:mb-[6px] items-center gap-1">
              <ReactSVG
                beforeInjection={(svg) => {
                  svg.setAttribute("style", "width: 36px; height: 36px;");
                }}
                className="sm:hidden"
                src="/images/pharmacy/glasses.svg"
              />
              <h2 className="text-[24px] sm:text-[14px] font-demi text-blackPrimary-100">
                ოპტიკა
              </h2>
            </div>
            <div className="grid hidescroll sm:px-[16px] sm:flex sm:whitespace-nowrap sm:overflow-x-auto grid-cols-3 mb-[34px] sm:mb-[14px] mt-[30px] sm:gap-4 gap-8">
              <div className="sm:min-w-[298px]">
                <img src="/images/pharmacy/bestpharma/1charchoebi.png" />
              </div>
              <div className="sm:min-w-[298px]">
                <img src="/images/pharmacy/bestpharma/2mzis.png" />
              </div>
              <div className="sm:min-w-[298px]">
                <img src="/images/pharmacy/bestpharma/3nomriani.png" />
              </div>
            </div>
            <div className="w-full sm:px-[16px]">
              {width < 640 ? (
                <img
                  className="w-full"
                  src="/images/pharmacy/bestpharmabannermobile.png"
                />
              ) : (
                <img src="/images/pharmacy/bestpharma/banner.png" />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-[48px] mb-[48px]">
        <MedObjects />
      </div>
      <div className="h-[281px] sm:h-[316px] mb-[32px] sm:mx-[16px] relative rounded-[16px] overflow-hidden">
        {width > 640 ? (
          <img
            className="object-cover object-left w-full h-full"
            src="/images/pharmacy/pharmabanner.png"
          />
        ) : (
          <img
            className="object-cover sm:object-left-bottom object-left w-full h-full"
            src="/images/pharmacy/pharmabannermobile.png"
          />
        )}
        <div className="absolute sm:w-[90%] left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] flex flex-col gap-8 items-center">
          <span className="font-demi md:text-[14px] text-[24px] text-[#fff] text-center">
            ხელმისაწვდომი სერვისებით და პროდუქტებით სარგებლობისთვის გახდი VITA
            ბარათის მფლობელი
          </span>
          {error?.isAuthorized ? (
            <Link to="/checkout">
              <button className="py-[20px] text-nowrap px-[40px] text-blackPrimary-100 font-demi text-rg rounded-[12px] bg-[#fff]">
                ბარათის ყიდვა
              </button>
            </Link>
          ) : (
            <a href="/secured">
              <button className="py-[20px] text-nowrap px-[40px] text-blackPrimary-100 font-demi text-rg rounded-[12px] bg-[#fff]">
                ბარათის ყიდვა
              </button>
            </a>
          )}
        </div>
      </div>
      {width <= 640 && (
        <div
          onClick={() => setIsContactOpen(true)}
          className="fixed z-20 shadowofcontactbtn bottom-4 bg-[#fff] flex items-center justify-center right-4 w-[60px] h-[60px] rounded-[50%]"
        >
          <ReactSVG src="/images/icons/phoneicon.svg" />
        </div>
      )}
    </div>
  );
};
