import { memo } from "react";
import { CustomCheckbox } from "Components/Checkbox/Checkbox";
import { TextFieldInput } from "Components/Inputs/TextField";
import { InputMessage } from "Components/Messages/InputValidations/InputMessage";
import { WrongInfo } from "Components/Messages/Warnings/WrongInfo";
import { SectionHeader } from "./Layouts/SectionHeader";
import useWindowSize from "Hooks/useWindowSize";
import { useAppSelector } from "Store/store";

export const PersonalInfo = memo(function PersonalInfoComponent({
  formik,
}: any) {
  const { width } = useWindowSize();
  const state = useAppSelector((state) => state.user);

  return (
    <div>
      {width > 832 && <SectionHeader title="პირადი ინფორმაცია" />}
      <div className="bg-[#fff] md:bg-[transparent] rounded-[12px] px-[44px] md:p-[0] py-[32px]">
        {width > 640 && (
          <WrongInfo
            text={`არასწორი ინფორმაციის შეყვანის შემთხვევაში, გთხოვთ დარეკოთ ქოლ ცენტში <span class="text-[#3A6CE8]">0322185577</span>`}
          />
        )}
        {width <= 832 && <SectionHeader title="პირადი ინფორმაცია" />}
        <div className="grid grid-cols-2 md:grid-cols-1 gap-[32px] md:gap-[16px] md:mt-[16px] mt-[30px]">
          <div>
            <TextFieldInput
              name="firstName"
              label="სახელი"
              className="bg-gray-30 md:bg-[#fff]"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.firstName && formik.errors.firstName && (
              <InputMessage message={formik.errors.firstName} />
            )}
          </div>
          <div>
            <TextFieldInput
              name="lastName"
              label="გვარი"
              className="bg-gray-30 md:bg-[#fff]"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />{" "}
            {formik.touched.lastName && formik.errors.lastName && (
              <InputMessage message={formik.errors.lastName} />
            )}
          </div>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-1 items-start gap-[32px] md:gap-[16px] md:mt-[16px] mt-[30px]">
          <TextFieldInput
            label="მობილურის ნომერი*"
            className="bg-gray-30 md:bg-[#fff]"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled
          />
          <div>
            <TextFieldInput
              name="personalNumber"
              label="პირადი ნომერი*"
              className="bg-gray-30 md:bg-[#fff] md:mb-4"
              value={formik.values.personalNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={state?.user?.personalId !== null}
            />
            {formik.touched.personalNumber && formik.errors.personalNumber && (
              <InputMessage message={formik.errors.personalNumber} />
            )}
            <CustomCheckbox
              name="foreignCitizen"
              checked={formik.values.foreignCitizen}
              onChange={formik.handleChange}
              label="უცხო ქვეყნის მოქალაქე"
            />
          </div>
        </div>
      </div>
    </div>
  );
});
