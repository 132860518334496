import { memo, useEffect, useState } from "react";
import { SectionHeader } from "./Layouts/SectionHeader";
import { ReactSVG } from "react-svg";
import useWindowSize from "Hooks/useWindowSize";
import { useUserBankCardsQuery } from "Hooks/Queries/User/useUserBankCardsQuery";

export const BankCards = memo(function BankCardsComponent({
  setPaymentMethod,
  paymentRef,
  paymentMethod,
  fromProfile = false,
}: {
  setPaymentMethod?: any;
  paymentRef?: any;
  paymentMethod?: any;
  fromProfile?: boolean;
}) {
  const { bankCard, isLoadingBankCard, isErrorBankCard, isSuccessBankCard } =
    useUserBankCardsQuery();
  const { width } = useWindowSize();
  const [savedcards] = useState([
    {
      id: 0,
      card: "1230102392",
    },
    {
      id: 0,
      card: "1230102391",
    },
  ]);

  useEffect(() => {
    if (bankCard?.savedCards && fromProfile == true) {
      setPaymentMethod({
        method: "savedcard",
        card: bankCard?.subscriptionCard?.id,
        pan: bankCard?.subscriptionCard?.pan,
      });
    }
  }, [bankCard]);

  return (
    <div ref={paymentRef}>
      {width > 832 && !fromProfile && (
        <SectionHeader title="გადახდის მეთოდები" />
      )}
      <div
        className={`${
          !fromProfile && "p-[44px]"
        } flex flex-col gap-12 bg-[#fff] rounded-[12px] md:p-0`}
      >
        {bankCard?.savedCards?.length > 0 && (
          <div>
            <h2 className="text-[18px] font-bold mb-4">შენახული ბარათები</h2>
            <div className="bg-gray-30 px-[32px] py-[24px] rounded-[12px] flex flex-col gap-6">
              {bankCard?.subscriptionCard && (
                <div
                  onClick={() =>
                    setPaymentMethod({
                      method: "savedcard",
                      card: bankCard?.subscriptionCard?.id,
                      pan: bankCard?.subscriptionCard?.pan,
                    })
                  }
                  className={
                    bankCard?.savedCards?.filter(
                      (e: any) => e.id !== bankCard?.subscriptionCard?.id
                    ).length == 0
                      ? "cursor-pointer flex justify-between items-center"
                      : "cursor-pointer pb-[24px] flex justify-between items-center border-b-[1px] border-solid border-b-[#E8ECF4]"
                  }
                >
                  <div className="flex items-center gap-3">
                    <div className="w-[32px] h-[24px] rounded-[5px] border-[1px] border-solid border-[#E8ECF4] flex items-center justify-center">
                      <img
                        className="max-w-[24px]"
                        src={
                          bankCard?.subscriptionCard?.pan?.substring(0, 1) ==
                          "5"
                            ? "/images/icons/mastercard.svg"
                            : "/images/icons/visacard.svg"
                        }
                      />
                    </div>
                    <div className="flex flex-col">
                      <span className="font-demi text-rg text-blackPrimary-100">
                        {bankCard?.subscriptionCard?.pan?.substring(0, 1) == "5"
                          ? "MASTERCARD"
                          : "VISA"}
                      </span>
                      <span className="text-[12px] font-regular text-blackPrimary-100">
                        {bankCard?.subscriptionCard?.pan}
                      </span>
                    </div>
                  </div>
                  {paymentMethod?.card == bankCard?.subscriptionCard?.id ? (
                    <button className="w-[20px] h-[20px] bg-[#fff] border-[6px] border-solid border-green-100 rounded-[50%]" />
                  ) : (
                    <button className="w-[20px] h-[20px] bg-gray-70 rounded-[50%]" />
                  )}
                </div>
              )}
              {bankCard?.savedCards.map(
                (item: any, i: number) =>
                  bankCard?.subscriptionCard?.id !== item?.id && (
                    <div
                      key={i}
                      onClick={() =>
                        setPaymentMethod({
                          method: "savedcard",
                          card: item.id,
                          pan: item.pan,
                        })
                      }
                      className={
                        i ==
                        bankCard?.savedCards?.filter(
                          (e: any) => e.id !== bankCard?.subscriptionCard?.id
                        ).length -
                          1
                          ? "cursor-pointer flex justify-between items-center"
                          : "cursor-pointer pb-[24px] flex justify-between items-center border-b-[1px] border-solid border-b-[#E8ECF4]"
                      }
                    >
                      <div className="flex items-center gap-3">
                        <div className="w-[32px] h-[24px] rounded-[5px] border-[1px] border-solid border-[#E8ECF4] flex items-center justify-center">
                          <img
                            className="max-w-[24px]"
                            src={
                              item.pan.substring(0, 1) == "5"
                                ? "/images/icons/mastercard.svg"
                                : "/images/icons/visacard.svg"
                            }
                          />
                        </div>
                        <div className="flex flex-col">
                          <span className="font-demi text-rg text-blackPrimary-100">
                            {item.pan.substring(0, 1) == "5"
                              ? "MASTERCARD"
                              : "VISA"}
                          </span>
                          <span className="text-[12px] font-regular text-blackPrimary-100">
                            {item.pan}
                          </span>
                        </div>
                      </div>
                      {paymentMethod?.card == item.id ? (
                        <button className="w-[20px] h-[20px] bg-[#fff] border-[6px] border-solid border-green-100 rounded-[50%]" />
                      ) : (
                        <button className="w-[20px] h-[20px] bg-gray-70 rounded-[50%]" />
                      )}
                    </div>
                  )
              )}
            </div>
          </div>
        )}
        <div>
          <h2 className="text-[18px] font-bold mb-4">ახალი ბარათი</h2>
          <div className="bg-gray-30 px-[32px] py-[24px] rounded-[12px] flex flex-col gap-6">
            <div
              onClick={() =>
                setPaymentMethod({
                  method: "bank",
                  card: "bank",
                })
              }
              className={"cursor-pointer flex justify-between items-center"}
            >
              <div className="flex items-center gap-3">
                <span className="text-[16px] font-demi text-blackPrimary-100">
                  ბანკით გადახდა
                </span>
              </div>
              {paymentMethod?.card == "bank" ? (
                <button className="w-[20px] h-[20px] bg-[#fff] border-[6px] border-solid border-green-100 rounded-[50%]" />
              ) : (
                <button className="w-[20px] h-[20px] bg-gray-70 rounded-[50%]" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
