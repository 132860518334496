import { Modal } from "@mui/material";
import { SaerchContent, SearchBox } from "Components/Header/Search/SearchBox";
import { SearchInput } from "Components/Header/Search/SearchInput";
import { MobileSearchInput } from "Components/Inputs/MobileSearchInput";
import { debounce } from "Helpers/Debounce";
import { useClinicsSearchQuery } from "Hooks/Queries/useClinicsSearchQuery";
import { useCallback, useMemo, useRef, useState } from "react";
import { ReactSVG } from "react-svg";

export const SearchModal = ({ open, close }: any) => {
  const containerRef = useRef(null);
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const [isLoading, setIsLoading] = useState(false);

  const handler = debounce((value: string) => {
    setIsLoading(false);
    setDebouncedSearch(value);
  }, 500);

  const handleSearch = useMemo(() => debounce(handler, 1000), []);
  const clearSearch = useCallback(() => {
    setSearch("");
    setDebouncedSearch("");
  }, []);

  const { clinicResults, isLoadingClinicResults, isSuccessClinicResults } =
    useClinicsSearchQuery({
      page: 0,
      size: 100,
      key: debouncedSearch,
    });

  return (
    <>
      <div ref={containerRef}></div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        container={containerRef.current}
        className="max-h-[100dvh]"
      >
        <div className="w-[100%] h-[100%] max-w-[100%] min-h-[100dvh] bg-[#fff] overflow-y-auto">
          <div className="flex items-center p-4">
            <button className="w-[36px] h-[36px] min-w-[36px] min-h-[36px] flex items-center justify-start cursor-pointer">
              <ReactSVG src="/images/icons/arrowleft.svg" />
            </button>
            <MobileSearchInput
              search={search}
              onChange={(e: any) => {
                setIsLoading(true);
                setSearch(e.target.value);
                handleSearch(e.target.value);
              }}
              clear={() => clearSearch()}
              close={close}
            />
          </div>
          {search.length > 0 && (
            <div className="mt-[-16px]">
              <SaerchContent
                isLoading={isLoading}
                isLoadingClinicResults={isLoadingClinicResults}
                isSuccessClinicResults={isSuccessClinicResults}
                clinicResults={clinicResults}
                close={close}
                keyWord={search}
              />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
