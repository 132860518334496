import { memo } from "react";

export const OfferHeader = memo(function OfferHeaderComponent({
  title,
  description,
}: {
  title: string;
  description: string;
}) {
  return (
    <div className="flex flex-col md:gap-3 gap-[30px]">
      <h2 className="text-[40px] md:text-[20px] text-blackPrimary-100 font-demi">
        {title}
      </h2>
      <span className="text-[20px] md:text-rg font-medium text-blackPrimary-60">
        {description}
      </span>
    </div>
  );
});
