import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query/react";

interface User {
  id: number;
  name: string;
  email: string;
  // Add other properties as needed
}

interface UserResponse {
  isAuthorized: boolean;
  data?: User;
  error?: any; // You can define a more specific type for the error if needed
}

interface ApiError {
  status: number;
  data: any; // You can refine this type based on your error response
}

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_VITA_APP }),
  endpoints: (builder) => ({
    getUser: builder.query<UserResponse, number>({
      query: () => `/user/isAuth`,
      transformResponse: (response: User, meta: FetchBaseQueryMeta) => {
        if (meta && meta.response && meta.response.status === 401) {
          return { isAuthorized: false };
        }
        return { isAuthorized: true, data: response };
      },
      transformErrorResponse: (response: ApiError) => {
        // Always provide a default data structure
        if (response.status === 401) {
          return { isAuthorized: false };
        }
        if (response.status === 500) {
          return { isAuthorized: false, skeleton: true };
        }
        return { isAuthorized: true, error: response };
      },
    }),
  }),
});

export const { useGetUserQuery }: any = apiSlice;
