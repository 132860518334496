import { useQuery } from "@tanstack/react-query";
import { API_REQUEST } from "Request/API";

export const useSubServicesQuery = (
  parentId: number | null,
  clinicId: string,
  size: number,
  page: number
) => {
  const {
    data: subServices,
    isError: isErrorSubServices,
    isLoading: isLoadingSubServices,
    isSuccess: isSuccessSubServices,
  } = useQuery({
    queryKey: [
      `clinic-sub-services-${parentId}-${clinicId}`,
      [parentId, clinicId, size, page],
    ],
    queryFn: () =>
      API_REQUEST("GET", `clinic/services-by-parent-and-clinic`, null, {
        parentId,
        clinicId,
        size,
        page,
      }),
    retry: 0,
    enabled: parentId != undefined && clinicId !== undefined,
    refetchOnWindowFocus: false,
  });

  return {
    subServices,
    isErrorSubServices,
    isLoadingSubServices,
    isSuccessSubServices,
  };
};
