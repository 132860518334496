import { memo, useRef } from "react";
import { Modal } from "@mui/material";
import { Phone } from "Components/Phone/PhoneComponent";
import { SocialComponent } from "Components/Social/Social";
import { WorkHours } from "Components/PageSections/Clinics/WorkHours/WorkHours";
import { Location } from "Components/Location/Location";
import { BasicModalTypes } from "types/MembersModal";
import { ReactSVG } from "react-svg";

export const ContactModal = memo(function ContactModalComponent({
  open,
  close,
  clinic,
}: BasicModalTypes & {
  clinic?: any;
}) {
  const containerRef = useRef(null);
  const mails = clinic?.clinicContactInfos?.filter(
    (e: any) => e.type == "MAIL"
  );
  const insta = clinic?.clinicContactInfos?.filter(
    (e: any) => e.type == "INST"
  )[0];
  const fb = clinic?.clinicContactInfos?.filter((e: any) => e.type == "FB")[0];
  const phones = clinic?.clinicContactInfos?.filter(
    (e: any) => e.type == "PHONE"
  );

  return (
    <>
      <div ref={containerRef}></div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        container={containerRef.current}
        className="h-[100dvh] bottom-0 z-[9999] fixed w-full overflow-y-auto left-0"
      >
        <div className="max-h-[90%] h-auto rounded-t-[16px] absolute bottom-0 z-40 w-full overflow-y-auto bg-[#fff]">
          <div className="flex flex-col gap-6 rounded-[12px] py-[32px] px-[24px]">
            <div className="flex flex-col gap-5 border-b-[1px] border-solid border-b-[#F0F2F5] pb-[20px]">
              <Location
                address={clinic?.address}
                isLoadingClinic={false}
                isErrorClinic={false}
                color="rgba(56, 56, 56, 0.60)"
              />
            </div>
            <div className="flex flex-col gap-5 border-b-[1px] border-solid border-b-[#F0F2F5] pb-[20px]">
              {(phones?.length !== 0 || mails?.length !== 0) && (
                <div className="flex flex-col gap-5">
                  {phones?.map((phone: any) => (
                    <Phone
                      phone={phone?.value}
                      color="rgba(56, 56, 56, 0.60)"
                    />
                  ))}

                  {mails?.map((mail: any) => (
                    <div className="flex items-center font-regular gap-2 text-blackPrimary-60">
                      <ReactSVG src="/images/icons/email.svg" /> {mail?.value}
                    </div>
                  ))}
                </div>
              )}
              {fb !== undefined ||
                (insta !== undefined && (
                  <div className="flex flex-col gap-5 border-b-[1px] border-solid border-b-[#F0F2F5] pb-[20px]">
                    {fb?.value !== undefined && (
                      <SocialComponent
                        img={"/images/icons/social/facebook.svg"}
                        url={fb?.value}
                        color="rgba(56, 56, 56, 0.60)"
                      />
                    )}
                    {insta?.value !== undefined && (
                      <SocialComponent
                        img={"/images/icons/social/instagram.svg"}
                        url={insta?.value}
                        color="rgba(56, 56, 56, 0.60)"
                      />
                    )}
                  </div>
                ))}
            </div>
            <WorkHours workingHours={clinic?.workingHours} />
          </div>
        </div>
      </Modal>
    </>
  );
});
