export const modifyObject = (
  record: any,
  clinicId: number,
  clinic: any,
  isRemove = false
): any => {
  return {
    clinic: {
      id: clinicId,
      title: clinic?.name,
      image: clinic?.mainImgUrl,
    },
    offerAmount: record?.offerAmount,
    amount: parseInt(record?.amount),
    percentage: record?.percentage,
    title: record?.title,
  };
};
