import { useQuery } from "@tanstack/react-query";
import { API_REQUEST } from "Request/API";

export const useCheckOrderStatusQuery = (id: any, enabled = false) => {
  const {
    data: order,
    isError: isErrorOrder,
    isLoading: isLoadingOrder,
    isSuccess: isSuccessOrder,
  } = useQuery({
    queryKey: [`order-status-${id}`],
    queryFn: () => API_REQUEST("GET", `medical/check-order-status/${id}`),
    retry: 0,
    refetchOnWindowFocus: false,
    enabled: enabled && id !== null,
  });

  return {
    order,
    isErrorOrder,
    isLoadingOrder,
    isSuccessOrder,
  };
};
