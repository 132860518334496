import useWindowSize from "Hooks/useWindowSize";
import { useRef } from "react";
import { Helmet } from "react-helmet";
import { ReactSVG } from "react-svg";

const aboutList = [
  "ათასობით სამედიცინო სერვისი, ფიზიკური, მენტალური და ესთეტიკური ჯანმრთელობის მიმართულებით 215+ პროვაიდერი კლინიკისგან",
  "მომენტალური გააქტიურება, ლოდინის პერიოდის გარეშე",
  "ულიმიტო წვდომა ყველა პროფილის კლინიკასთან",
  "VITA-ს გამოყენება არ იწვევს  საყოველთაო ან კერძო დაზღვევის გაუქმებას. პირიქით, ვიტა არის დაზღვევის იდეალური შემავსებელი.",
  "სააფთიაქო პროდუქტები",
  "გამოიყენება ულიმიტო ჯერადობით იგივე ან სხვადასხვა კლინიკებში.",
];

export const AboutUs = () => {
  const { width } = useWindowSize();
  let blockRef = useRef<any>(null);

  const aboutHighlite = () => {
    return (
      <div className="relative m-auto mt-[40px] max-w-[1920px] h-full flex items-center">
        <div className="w-[50%] h-full xl:w-[40%] relative">
          <div className="relative">
            <img className="cover w-full h-full" src="/images/aboutcover.png" />
            <div className="absolute left-0 top-0 bg-gray-48 w-full h-full z-10 rounded-tr-[12px] rounded-br-[12px]"></div>
          </div>
          <div className="absolute left-[50%] translate-x-[-50%] bottom-[-55px] z-20">
            <img
              onClick={() =>
                blockRef?.current &&
                blockRef?.current?.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              }
              className="cursor-pointer"
              src="/images/icons/scrolltobottom.svg"
              alt="scroll to bottom"
            />
          </div>
        </div>
        <div className="pl-[60px] 3xl:max-w-[40%] 2xl:max-w-[calc(50%-50px)] xl:max-w-[calc(60%-40px)] overflow-hidden">
          <h1 className="font-bold mb-[30px] xl:mb-[20px] text-sloganSize xl:text-[30px] text-blackPrimary-100">
            VITA ჯანდაცვის ბარათი
          </h1>
          <h2 className="font-mzeqala mb-[20px] text-green-100 text-sloganSize xl:text-[30px]">
            ,,შენი ჯანმრთელობის თილისმა’’
          </h2>
          <p className="font-regular text-md xl:text-rg text-blackPrimary-100">
            VITA ჯანდაცვის ბარათი არის ვირტუალური ბარათი, რომლის საშუალებითაც
            მომხმარებელი იღებს განსაკუთრებულ, 50%-მდე ფასდაკლებებს 215+ კლინიკის
            მომსახურებებსა და აფთიაქების პროდუქტებზე, ულიმიტოდ და მთელი
            საქართველოს მასშტაბით. ანუ ჩვენ ვეხმარებით
          </p>
          <div
            ref={blockRef}
            className="flex flex-col mt-[40px] gap-8 xl:gap-4"
          >
            <div className="flex items-center gap-3">
              <div className="min-w-[44px] min-h-[44px] w-[44px] h-[44px] flex items-center justify-center bg-blue-100 rounded-[50%] text-[20px] font-demi text-[#fff]">
                01
              </div>
              <h4 className="font-medium text-md xl:text-rg text-blackPrimary-100">
                <span className="font-bold">ადამიანებს -</span> უფრო
                ხელმისაწვდომი ჯანდაცვის სერვისების მიღებაში
              </h4>
            </div>
            <div className="flex items-center gap-3">
              <div className="min-w-[44px] min-h-[44px]  w-[44px] h-[44px] flex items-center justify-center bg-green-100 rounded-[50%] text-[20px] font-demi text-[#fff]">
                02
              </div>
              <h4 className="font-medium text-md xl:text-rg text-blackPrimary-100">
                <span className="font-bold">
                  ჯანმრთელობის სფეროს პროვაიდერებს -
                </span>{" "}
                მომხმარებლების მოპოვებაში
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const aboutHighliteMobile = () => {
    return (
      <div className="relative mt-[40px] container h-full flex items-center">
        <div className="w-full overflow-hidden">
          <h2 className="font-bold text-center mb-[30px] xl:mb-[20px] text-sloganSize xl:text-[30px] sm:text-[20px] text-blackPrimary-100">
            VITA ჯანდაცვის ბარათი
          </h2>
          <h2 className="font-mzeqala text-center mb-[20px] text-green-100 text-sloganSize xl:text-[30px] sm:text-[20px]">
            ,,შენი ჯანმრთელობის თილისმა’’
          </h2>
          <img
            className="w-[100%] max-w-[600px] m-auto rounded-[22px] mb-[20px] h-full cover"
            src="/images/aboutcover.webp"
          />
          <p className="font-regular text-md text-blackPrimary-100">
            VITA ჯანდაცვის ბარათი არის ვირტუალური ბარათი, რომლის საშუალებითაც
            მომხმარებელი იღებს განსაკუთრებულ, 50%-მდე ფასდაკლებებს 215+ კლინიკის
            მომსახურებებსა და აფთიაქების პროდუქტებზე, ულიმიტოდ და მთელი
            საქართველოს მასშტაბით. ანუ ჩვენ ვეხმარებით
          </p>
          <div className="flex flex-col mt-[40px] gap-8 xl:gap-4">
            <div className="flex items-center gap-3">
              <div className="min-w-[44px] min-h-[44px] w-[44px] h-[44px] flex items-center justify-center bg-blue-100 rounded-[50%] text-[20px] font-demi text-[#fff]">
                01
              </div>
              <h4 className="font-medium text-md xl:text-rg text-blackPrimary-100">
                <span className="font-bold">ადამიანებს -</span> უფრო
                ხელმისაწვდომი ჯანდაცვის სერვისების მიღებაში
              </h4>
            </div>
            <div className="flex items-center gap-3">
              <div className="min-w-[44px] min-h-[44px]  w-[44px] h-[44px] flex items-center justify-center bg-green-100 rounded-[50%] text-[20px] font-demi text-[#fff]">
                02
              </div>
              <h4 className="font-medium text-md xl:text-rg text-blackPrimary-100">
                <span className="font-bold">
                  ჯანმრთელობის სფეროს პროვაიდერებს -
                </span>{" "}
                მომხმარებლების მოპოვებაში
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {width > 1080 ? aboutHighlite() : aboutHighliteMobile()}
      <Helmet>
        <title>VITA-ჯანდაცვის ბარათი | about us</title>
        <meta
          name="description"
          content="გაინტერესებთ ინფორმაცია VITA-ს შესახებ? რა არის რას VITA?რას ვაკეთებთ? რატომ ვაკეთებთ, რასაც ვაკეთებთ? მოცემულ კითხვებზე პასუხს იხილავთ ამ გვერძე."
        />
      </Helmet>
      <div className="flex relative container my-[100px] lg:my-[80px] sm:my-[60px] lg:bg-[transparent] bg-gray-30 rounded-[12px] items-center">
        <div className="relative z-10 px-[40px] lg:px-0">
          <h2 className="text-[24px] sm:text-[16px] font-bold text-blackPrimary-100 mb-[20px]">
            რატომ ვაკეთებთ რასაც ვაკეთებთ?
          </h2>
          <p className="text-md sm:text-rg font-regular mb-[20px] text-blackPrimary-100">
            ჩვენ გვჯერა, რომ ჯანმრთელობაზე რეგულარული ზრუნვა - არა
            “პრივილეგია/ფუფუნება”, არამედ ყოევლდღიურობის ნაწილი უნდა იყოს,
            ამიტომ - ადამიანებისთვის შესაბამისი ციფრული ხელსაწყოების,
            ინფორმაციის/ რეკომენდაციების გაზიარებითა და მათი მუდმივი
            სტიმულირებით
          </p>
          <h3 className="font-bold sm:text-[16px] text-[18px]">
            ვქმნით უფრო ჯანსაღ საზოგადოებას და ვაყალიბებთ ახალ ქცევას ფართო
            აუდიტორიაში
          </h3>
        </div>
        <img
          className="absolute top-0 right-[290px]"
          src="/images/aboutline.svg"
          alt="line"
        />
        <img
          className="ml-auto lg:hidden relative z-10 h-full cover"
          src="/images/aboutcover2.webp"
          alt="about cover"
        />
      </div>
      <div className="container sm:max-w-full relative">
        <div className="relative sm:pt-5 bg-blue-100 rounded-[12px] sm:rounded-none py-[60px] z-10 mb-[100px] sm:mb-[50px]">
          <img
            className="absolute left-[145px] sm:left-[45px] sm:w-[43px] sm:top-[-15px] top-[-30px]"
            src="/images/icons/aboutshape.svg"
            alt="shape"
          />
          <img
            className="absolute right-[135px] bottom-[-30px] sm:right-[45px] sm:w-[43px] sm:bottom-[-15px]"
            src="/images/icons/aboutshapebottom.svg"
            alt="shape2"
          />

          <h2 className="text-[24px] sm:text-[16px] text-center font-bold text-[#fff] mb-[20px]">
            ანუ ჩვენი მისიაა
          </h2>
          <span className="text-[24px] max-w-[1009px] m-auto flex justify-center text-center sm:text-rg text-[#fff] font-regular">
            ვაქციოთ ჯანმრთელობასა და კეთილდღეობაზე ზრუნვა ყველასათვის
            ხელმისაწვდომ ყოველდღიურობად და ამით, გავაუმჯობესოთ საზოგადოების
            “ცხოვრების ხარისხიც”.
          </span>
        </div>
        <div className="relative sm:px-[16px] z-10 mb-[150px] lg:mb-[50px] px-[100px] lg:px-0 sm:mb-[50px]">
          <h2 className="text-[24px] sm:text-[20px] font-demi text-blackPrimary-100 mb-[20px] sm:mb-[16px]">
            როგორ?
          </h2>
          <div>
            <div className="grid grid-cols-2 lg:grid-cols-1 gap-row-[16px] md:grid-cols-1 sm:gap-col-[6px] gap-col-[10px]">
              {aboutList?.map((item, key) => (
                <span
                  className="flex py-[14px] lg:py-[10px] items-center leading-[22px] gap-[13px] font-medium text-md lg:text-[14px] text-blackPrimary-100"
                  key={key}
                >
                  <ReactSVG src="/images/icons/verify.svg" /> {item}
                </span>
              ))}
            </div>
          </div>
        </div>
        <div className="relative sm:px-[16px] flex lg:flex-col-reverse gap-[50px] sm:gap-0 z-10 mb-[100px] sm:mb-[50px]">
          <div className="flex justify-center gap-4">
            <div className="w-[304px] h-[290px] sm:hidden">
              <img
                className="cover w-full h-full"
                src="/images/aboutcard.png"
                alt="about card"
              />
            </div>
            <div className="w-[304px] h-[290px] sm:w-full bg-yellow rounded-[12px] mt-[80px] flex justify-center items-center flex-col">
              <ReactSVG src="/images/icons/vitalogowhite.svg" />
              <p className="text-md font-demi text-[#fff] text-center p-[20px]">
                VITA-ს გუნდი მუდმივად დაგეხმარებით და წაგახალისებთ, რომ თქვენ
                იზრუნოთ თქვენს ჯანმრთელობასა და კეთილდღეობაზე!
              </p>
            </div>
          </div>
          <div>
            <h2 className="text-[24px] sm:text-[20px] font-demi text-blackPrimary-100 mb-[20px]">
              ვისთვის?
            </h2>
            <span className="border-l-[3px] pl-4 block border-solid sm:border-0 sm:pl-0 border-[#3A6CE8] text-md text-blackPrimary-100 font-regular sm:text-rg">
              VITA ჯანდაცვის ბარათის შეძენა შეუძლია როგორც ნებისმიერ, მსურველ
              ფიზიკურ პირს, ასევე კორპორაციას, რომელიც დაინტერესებულია
              წაახალისოს ფიზიკურად და მენტალურად ჯანსაღი გარემო თავის
              ორგანიზაციაში. <br /> ორგანიზაციებისთვის VITA არის{" "}
              <span className="font-bold">
                თანამშრომლის კორპორატიული ბენეფიტი
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
