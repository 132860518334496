import { createTheme } from "@mui/material";

export const tableTheme = createTheme({
  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          border: "none!important",
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: ({ theme }) => ({
          boxShadow: "none",
          padding: "0 34px",
          borderRadius: "12px",
          [theme.breakpoints.down("md")]: {
            padding: "0 0",
          },
        }),
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          "& .MuiTableCell-root": {
            color: "#070A1499",
            fontFamily: "avenir-demi",
            fontSize: "14px",
          },
          body: {
            "& .MuiTableCell-root": {
              fontFamily: "avenir-demi",
              fontSize: "14px",
            },
          },
        },
      },
    },
  },
});

export const servicesTabletheme = createTheme({
  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          border: "none!important",
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: ({ theme }) => ({
          boxShadow: "none",
          padding: "0 0",
          borderRadius: "12px",
          [theme.breakpoints.down("md")]: {
            padding: "0 0",
          },
        }),
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "& th:first-child": {
            borderRadius: "1em 0 0 1em",
          },
          "& th:last-child": {
            borderRadius: "0 1em 1em 0",
          },
          "& th, td": {
            borderBottom: "0",
          },
        },
        head: {
          background: "#E8ECF44D",
          borderBottom: "0px",
          "& .MuiTableCell-root": {
            color: "#070A14",
            fontFamily: "avenir-demi",
            fontSize: "14px",
            borderBottom: "0",
          },
          body: {
            "& .MuiTableCell-root": {
              fontFamily: "avenir-demi",
              fontSize: "14px",
            },
          },
        },
      },
    },
  },
});
