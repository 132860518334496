import { memo } from "react";

export const UserSectionHeader = memo(function UserSectionHeaderComponent({
  title,
}: {
  title: string;
}) {
  return (
    <h2 className="text-[24px] font-bold text-blackPrimary-100 leading-[36px] mb-[16px]">
      {title}
    </h2>
  );
});
