import { memo, useRef } from "react";
import { Modal } from "@mui/material";
import { ReactSVG } from "react-svg";
import { VitaPercent } from "Components/Buttons/VitaPercent";
import { weekendDiscountCardsData } from "JSON/PSP";

export const VitaminGroups = memo(function VitaminGroupsComponent({
  open,
  close,
  openList,
}: {
  open: boolean;
  close: () => void;
  openList?: any;
}) {
  const containerRef = useRef(null);
  return (
    <>
      <div ref={containerRef}></div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        container={containerRef.current}
        className="flex items-center justify-center"
      >
        <div className="w-[644px] sm:w-[100dvh] sm:max-w-full sm:max-h-full sm:rounded-[0px] sm:h-[100dvh] overflow-hidden flex flex-col p-[24px] sm:p-[12px] relative max-w-[90%] max-h-[90%] h-[604px] bg-[#fff] rounded-[12px] outline-none">
          <div className="mb-[24px] flex justify-between items-center">
            <h2 className="font-bold text-[20px] text-blackPrimary-100">
              მედიკამენტების ჯგუფები
            </h2>
            <div
              onClick={close}
              className="cursor-pointer w-[44px] h-[44px] bg-gray-30 sm:bg-gray-100 sm:rounded-[8px] rounded-[50%] flex items-center justify-center"
            >
              <ReactSVG src="/images/icons/close.svg" />
            </div>
          </div>
          <div className="customScrolllbar overflow-y-auto h-[100vh-40px]">
            {weekendDiscountCardsData.map((item: any, index: number) => (
              <div
                className="flex items-center gap-4 py-[16px] border-b-[1px] border-solid border-b-gray-30"
                key={index}
              >
                <div className="rounded-[12px] w-[64px] min-w-[64px] h-[64px] bg-[#fff] border-[#070A141A] border-[1px] border-solid flex items-center justify-center">
                  <img className="contain" src={item?.imageUrl} />
                </div>
                <div className="flex gap-1 flex-col flex-wrap">
                  <h2 className="text-blackPrimary-60 font-demi text-md">
                    {item?.altText}
                  </h2>
                  <span
                    onClick={() => openList(item?.cardId)}
                    className="text-raspberry-100 font-demi text-md"
                  >
                    იხილე მეტი
                  </span>
                </div>
                <div className="ml-auto">
                  <VitaPercent
                    className="text-[#fff!important] bg-raspberry-100"
                    icon={true}
                    percent={item?.discountNumber}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
});
