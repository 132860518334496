import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "Store/store";
import { UserLayout } from "Layouts/User/Layout";
import { TextFieldInput } from "Components/Inputs/TextField";
import { DatePickerInput } from "Components/Inputs/DataPickerInput";
import { SelectInputComponent } from "Components/Inputs/SelectInput";
import { PersonAvatar } from "Components/Avatar/PersonAvatar";
import { UserSectionHeader } from "Components/PageSections/User/UserSectionHeader";
import { UserSectionContainer } from "Components/PageSections/User/UserSectionContainer";
import { MobileInput } from "Components/Inputs/MobileInput";
import { Save } from "Components/Buttons/Save";
import { BasicBreadcrumbs } from "Components/Breadcrumbs/Breadcrumbs";
import { useCitiesQuery } from "Hooks/Queries/User/useCitiesQuery";
import { useFormik } from "formik";
import { Button, Skeleton } from "@mui/material";
import { InputMessage } from "Components/Messages/InputValidations/InputMessage";
import { API_REQUEST } from "Request/API";
import { ToastContainer, toast } from "react-toastify";
import { useUserQuery } from "Hooks/Queries/User/useUserQuery";
import { userData } from "Reducers/UserSlice";
import { useGetUserQuery } from "Reducers/ApiSlice";
import { Helmet } from "react-helmet";

const validationSchema = Yup.object({
  firstName: Yup.string()
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\u0400-\u04FF\u0370-\u03FF\u10A0-\u10FF\s]*)$/gi,
      "სახელი უნდა შეიცავდეს მხოლოდ ასოებს"
    )
    .matches(/^(\S+$)/, "* სფეისები არ არის დაშვებული")
    .required("სახელი აუცილებელია"),
  lastName: Yup.string()
    .required("გვარი აუცილებელია")
    .matches(/^(\S+$)/, "* სფეისები არ არის დაშვებული")
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\u0400-\u04FF\u0370-\u03FF\u10A0-\u10FF\s]*)$/gi,
      "გვარი უნდა შეიცავდეს მხოლოდ ასოებს"
    ),
  personalId: Yup.string().required("პირადი ნომერი აუცილებელია"),
  mail: Yup.string().email(),
});

const User = () => {
  const dispatch = useAppDispatch();
  const { data, isAuthorized, error } = useGetUserQuery();
  const { user, isErrorUser, isLoadingUser, isSuccessUser, refetch } =
    useUserQuery(error?.isAuthorized);
  const state = useAppSelector((state) => state.user);
  const { cities, isLoadingCities, isErrorCities, isSuccessCities } =
    useCitiesQuery();
  const [modifyCities, setModifyCities] = useState([]);
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      personalId: "",
      email: "",
      gender: "",
      address: "",
      cityId: "",
      birthDate: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setIsLoadingSave(true);
      API_REQUEST("PUT", "user", values, undefined, "fullresponse")
        .then(() => {
          setIsLoadingSave(false);
          refetch();
          toast("იუზერის ინფორმაცია წარმატებით განახლდა", {
            type: "success",
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            theme: "success",
          });
        })
        .catch(() => {
          setIsLoadingSave(false);
          toast("მოხდა შეცდომა", {
            type: "error",
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            theme: "light",
          });
        });
    },
  });

  useEffect(() => {
    if (state.user) {
      formik.setValues({
        firstName: state.user.firstName,
        lastName: state.user.lastName,
        personalId: state.user.personalId,
        email: state.user.email,
        gender: state.user.gender,
        address: state.user.address,
        cityId: state.user.cityId,
        birthDate: state.user.birthDate,
      });
    }
  }, [state.user, state.isError, state.isLoading, state.isSuccess]);

  useEffect(() => {
    if (cities) {
      const modifyCitiesdto = cities?.map(
        (city: { title: string; id: number }) => ({
          value: city.id,
          title: city.title,
        })
      );
      setModifyCities(modifyCitiesdto);
    }
  }, [isLoadingCities, isSuccessCities, isErrorCities]);

  useEffect(() => {
    dispatch(
      userData({
        data: user || {},
        isError: isErrorUser,
        isLoading: isLoadingUser,
        isSuccess: isSuccessUser,
      })
    );
  }, [user, isErrorUser, isLoadingUser, isSuccessUser]);

  return (
    <UserLayout>
      <ToastContainer />
      <BasicBreadcrumbs
        breadcrumbs={[
          {
            title: "ანგარიშის რედაქტირება",
            link: "user",
          },
        ]}
      />
      <UserSectionHeader title="პირადი ინფორმაცია" />
      <UserSectionContainer>
        <h4 className="relative z-10 font-medium text-blackPrimary-60 leading-[18px] mb-[32px] md:mb-[16px]">
          უკეთესი მომსახურების მიღების მიზნით, გთხოვთ სრულად შეავსეთ თქვენი
          პროფილი
        </h4>
        {/* <div className="flex items-center gap-3 mb-[32px]">
          <PersonAvatar />
          <div className="flex flex-col gap-[8px]">
            <h3 className="text-rg font-bold text-blackPrimary-100">
              პროფილის ფოტო
            </h3>
            <h4 className="text-blue-100 font-medium text-sm leading-[18px]">
              მაქსიმალური ზომა 700 MB
            </h4>
            <h4 className="text-blue-100 font-medium text-sm leading-[18px]">
              ზომა 120 X 120
            </h4>
          </div>
        </div> */}
        {!state.isLoading &&
        !state.isError &&
        isSuccessCities &&
        !isErrorCities &&
        !isLoadingCities ? (
          <form
            onSubmit={formik.handleSubmit}
            className="mt-[20px] flex flex-col gap-[15px]"
          >
            <div className="grid grid-cols-2 md:grid-cols-1 md:gap-4 gap-8 items-start">
              <div>
                <TextFieldInput
                  name="firstName"
                  label="სახელი"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <InputMessage message={formik.errors.firstName} />
                ) : null}
              </div>
              <div>
                <TextFieldInput
                  name="lastName"
                  label="გვარი"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.firstName && formik.errors.lastName ? (
                  <InputMessage message={formik.errors.lastName} />
                ) : null}
              </div>
              <div>
                <TextFieldInput
                  name="personalId"
                  label="პირადი ნომერი"
                  value={formik.values.personalId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={state.user.personalId ? true : false}
                />
                {formik.touched.firstName && formik.errors.personalId ? (
                  <InputMessage message={formik.errors.personalId} />
                ) : null}
              </div>
              <DatePickerInput
                value={formik.values.birthDate}
                onChange={(value: any) =>
                  formik.setFieldValue("birthDate", value, true)
                }
              />
              <SelectInputComponent
                name="city"
                label="ქალაქი"
                value={formik.values.cityId || ""}
                options={modifyCities}
                onChange={(e: any) => {
                  formik.setFieldValue("cityId", e);
                }}
                onBlur={formik.handleBlur}
              />
              <TextFieldInput
                name="address"
                label="მისამართი"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <SelectInputComponent
                name="gender"
                label="სქესი"
                value={formik.values.gender || ""}
                options={[
                  { value: "F", title: "მდედრობითი" },
                  { value: "M", title: "მამრობითი" },
                ]}
                onChange={(e: any) => {
                  formik.setFieldValue("gender", e.toString());
                }}
                onBlur={formik.handleBlur}
              />
              <MobileInput value={state.user.phone} disabled />
              <TextFieldInput
                name="email"
                label="ელ.ფოსტა"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="flex justify-end mt-8">
              {isLoadingSave ? (
                <Button
                  aria-label="default"
                  className={`sm:max-w-full sm:w-full text-nowrap whitespace-nowrap shadow-none px-[43px] py-[16px] font-bold text-rg leading-[21px] rounded-[12px]`}
                  variant="contained"
                  disabled
                >
                  მონაცემები მუშავდება
                </Button>
              ) : (
                <Save
                  className="sm:max-w-full sm:w-full"
                  type={"submit"}
                  variant="green"
                />
              )}
            </div>
          </form>
        ) : (
          <div className="grid grid-cols-2 md:grid-cols-1 md:gap-4 gap-x-4">
            {Array.from({ length: 8 }).map((_, i) => (
              <Skeleton width={"100%"} height={"80px"} key={i} />
            ))}
          </div>
        )}
      </UserSectionContainer>
    </UserLayout>
  );
};

export default User;
