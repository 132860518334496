import { FamilyMembers } from "Components/Sections/Familymembers";
import { memo } from "react";
import { ReactSVG } from "react-svg";
import { SectionHeader } from "./Layouts/SectionHeader";
import { handleModalTypes } from "types/MembersModal";

export const CreateFamilyMember = memo(function CreateFamilyMemberComponent({
  handleModalStates,
}: handleModalTypes) {
  return (
    <div>
      <SectionHeader title="ოჯახის წევრის დამატება" />
      <div className="flex items-center gap-[6px] font-medium text-rg text-blackPrimary-60 mb-[20px]">
        <ReactSVG src="/images/icons/info.svg" />
        ოჯახის წევრის დამატება ფასიანია. ერთი ადამიანის დამატების ღირებულება
        შეადგენს 15 ლარს
      </div>
      <FamilyMembers
        className="xl:grid-cols-2"
        handleRemoveMember={() => handleModalStates("deleteDialog", true)}
        handleCreateMember={() => handleModalStates("createMember", true)}
      />
    </div>
  );
});
