import { memo } from "react";
import { ReactSVG } from "react-svg";

let array = [
  {
    title: "მეტი მომხმარებელი",
    text: "VITA-ს საცალო და კორპორატიულ მომხმარებელთა ვიზიტები პროვაიდერ  კლინიკებში.",
  },
  {
    title: "მიზნობრივი პოპულარიზაცია",
    text: "ცნობადობისა და ხელმისაწვდომობის გაზრდა პროვაიდერის  პროდუქტებსა თუ მომსახურებებზე",
  },
  {
    title: "ადგილზე მომენტალური ანგარიშსწორება",
    text: "მომხმარებლის მხრიდან პროვაიდერ ორგანიზაციაში, ადგილზე ანგარიშსწორება მომსახურების ან პროდუქტის შეძენისთანავე",
  },
  {
    title: "რეკლამა",
    text: "ეფექტური საკომუნიკაციო არხებით სატელევიზო, ციფრული და PR  აქტივობების დაგეგმვა და ორგანიზება",
  },
  {
    title: "მომხმარებლის უკუკავშირი",
    text: "VITA-ს მომხმარებელთა მხარდაჭერის გუნდის ჩართულობა მომხმარებელთა კვლევასა და კმაყოფილების ამაღლებაში",
  },
  {
    title: "სტაბილური და საიმედო პარტნიორი",
    text: "VITA-ს გუნდი ხელმძღვანელობს მრავალწლიანი სტრატეგიული განვითარების გეგმით, რათა მუდმივად გაუმჯობესდეს VITA-ს სარგებელი პარტნიორებისა და მომხმარებლებისთვის",
  },
];

export const VitaForMedSector = memo(function VitaForMedSectorComponent() {
  return (
    <div className="h-[620px] lg:h-auto lg:pb-[30px] bg-gray-30 overflow-hidden">
      <div className="container h-full relative">
        <div className="mt-[100px] lg:mt-[60px] md:mt-[14px] relative z-10">
          <span className="font-mzeqala text-raspberry-100 text-[20px]">
            ვიტას შესახებ
          </span>
          <h2 className="mt-0 mb-[18px] font-bold text-sloganSize lg:text-sloganLgSize md:text-sloganMdSize text-blackPrimary-100 leading-[50px] lg:leading-[34px] md:leading-[24px]">
            ვიტა პროვაიდერი კლინიკებისთვის
          </h2>
        </div>
        <div className="grid grid-cols-3 customMedsector:grid-cols-2 md:grid-cols-1 gap-[30px] md:gap-[24px] relative z-10 mt-[42px] md:mt-[14px]">
          {array.map((_, i) => (
            <div className="flex flex-col gap-3 md:gap-1" key={i}>
              <div className="font-demi text-[18px] md:text-md flex items-center gap-2 text-blackPrimary-100">
                <ReactSVG
                  className="customsvgcheck"
                  width={32}
                  height={32}
                  src="/images/icons/corporatecheck.svg"
                />
                {_.title}
              </div>
              <h2 className="font-demi leading-[25px] text-rg text-blackPrimary-60">
                {_.text}
              </h2>
            </div>
          ))}
        </div>
        <div className="absolute bottom-[-50px] right-0">
          <img src="/images/vitacorplogo.png" alt="vitacorplogo" />
        </div>
      </div>
    </div>
  );
});
