import axios from "axios";

export const instance = axios.create({
  baseURL: "/",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

export const vitaInstance = axios.create({
  baseURL: process.env.REACT_APP_VITA_APP,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

export async function API_REQUEST(
  method = "GET",
  url = "/",
  data: {} | null = null,
  params: {} | null = null,
  responsType = "data"
) {
  try {
    const response = await vitaInstance({
      method,
      url,
      data,
      params,
    });
    
    if (responsType == "data") {
      return response.data;
    }
    if (responsType == "fullresponse") {
      return response;
    }
  } catch (error) {
    throw error;
  }
}
