import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const useNavigationWithQueryParams = () => {
  const navigate = useNavigate();

  const updateQueryParams = useCallback(
    (newParams: any) => {
      const currentParams = new URLSearchParams(window.location.search);
      
      Object.keys(newParams).forEach((key) => {
        // Clear existing key values
        currentParams.delete(key);
        
        const value = newParams[key];
        if (Array.isArray(value)) {
          // Append array values as separate query params
          value.forEach((val) => {
            currentParams.append(key, val.toString());
          });
        } else {
          // Set single value
          currentParams.set(key, value.toString());
        }
      });

      navigate({
        pathname: window.location.pathname,
        search: currentParams.toString(),
      });
    },
    [navigate]
  );

  const moveToPage = useCallback(
    (page: any) => {
      updateQueryParams({ page });
    },
    [updateQueryParams]
  );

  const handleSort = useCallback(
    (sort: any) => {
      updateQueryParams({ sort });
    },
    [updateQueryParams]
  );

  const updateRegions = useCallback(
    (regions: number[]) => {
      updateQueryParams({ regionId: regions });
    },
    [updateQueryParams]
  );

  const updateMunicipalities = useCallback(
    (municipalities: number[]) => {
      updateQueryParams({ municipalityId: municipalities });
    },
    [updateQueryParams]
  );

  return { moveToPage, handleSort, updateRegions, updateMunicipalities };
};

export default useNavigationWithQueryParams;
