import { modifyObject } from "Helpers/modifyServerObject";
import { addServices, removeServices } from "Reducers/ServicesSlice";
import { useAppDispatch } from "Store/store";

export const RemoveServiceBtn = ({
  check,
  row,
  clinic,
}: {
  check: boolean;
  row: any;
  clinic: any;
}) => {
  const dispatch = useAppDispatch();
  return (
    <span
      onClick={() =>
        check
          ? dispatch(
              addServices(
                modifyObject(
                  row,
                  clinic?.clinicDetails.id,
                  clinic?.clinicDetails
                )
              )
            )
          : dispatch(
              removeServices(
                modifyObject(row, clinic?.clinicDetails.id || 0, clinic, true)
              )
            )
      }
      className={`${
        check ? "text-green-100" : "text-raspberry-100"
      } text-demi text-rg`}
    >
      {check ? "დამატება" : "წაშლა"}
    </span>
  );
};
