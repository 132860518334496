import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Menu } from "./Menu";
import { ReactSVG } from "react-svg";
import { Link, useLocation } from "react-router-dom";
import useWindowSize from "Hooks/useWindowSize";
import { BurgerMenu } from "Components/Modals/Main/BurgerMenu";
import { UserDropdown } from "./UserDropdown";
import { useGetUserQuery } from "Reducers/ApiSlice";
import { useUserQuery } from "Hooks/Queries/User/useUserQuery";
import { useAppDispatch, useAppSelector } from "Store/store";
import { userData } from "Reducers/UserSlice";
import { Calculator } from "Components/Modals/Calculator";
import { SearchInput } from "./Search/SearchInput";
import { SearchModal } from "Components/Modals/SearchModal";

export const Header = memo(function HeaderComponent() {
  const dispatch = useAppDispatch();
  const calc = useAppSelector((state) => state.services);
  const { width } = useWindowSize();
  const [isOpenBurger, setIsOpenBurger] = useState(false);
  const [isOpenCalculator, setIsOpenCalculator] = useState(false);
  const { error } = useGetUserQuery();
  const { user, isErrorUser, isLoadingUser, isSuccessUser } = useUserQuery(
    error?.isAuthorized
  );
  const location = useLocation();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const bodyRef = useRef<any>(null);
  const [isFocued, setIsFocused] = useState(false);
  const [isOpenMobileSearch, setIsOpenMobileSearch] = useState(false);

  const handleCalculator = useCallback(
    (state: boolean) => setIsOpenCalculator(state),
    []
  );

  useEffect(() => {
    dispatch(
      userData({
        data: user || {},
        isError: isErrorUser,
        isLoading: isLoadingUser,
        isSuccess: isSuccessUser,
      })
    );
  }, [isErrorUser, isLoadingUser, isSuccessUser]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node) && // Click is outside the input
        bodyRef.current &&
        !bodyRef.current.contains(event.target as Node) // Click is outside the bodyRef (modal)
      ) {
        setIsFocused(false);
        inputRef.current.blur(); // Remove focus from the input
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Calculator
        open={isOpenCalculator}
        close={() => handleCalculator(false)}
      />
      <SearchModal
        open={isOpenMobileSearch}
        close={() => setIsOpenMobileSearch(false)}
      />
      <div className="w-full fixed top-0 bg-[#fff] z-50">
        <BurgerMenu open={isOpenBurger} close={() => setIsOpenBurger(false)} />
        <div className="container flex items-center gap-[44px] md:gap-3 py-[25px]">
          <button
            aria-label="burger-menu"
            onClick={() => setIsOpenBurger(true)}
            className="hidden md:block"
          >
            <ReactSVG src="/images/icons/burgermenu.svg" />
          </button>
          <Link to="/">
            <img
              onClick={() =>
                location.pathname == "/" &&
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })
              }
              className="sm:object-contain sm:w-[98px] sm:h-[35px]"
              src="/images/logo.svg"
              alt="logo"
            />
          </Link>
          {width > 1420 && <Menu />}
          <div className="flex items-center ml-auto gap-5 md:gap-2">
            <SearchInput
              inputRef={inputRef}
              isFocued={isFocued}
              setIsFocused={setIsFocused}
              bodyRef={bodyRef}
            />
            <div className="bg-gray-100 h-[32px] w-[1px] md:hidden"></div>
            <div
              onClick={() => handleCalculator(true)}
              className="relative md:hidden mr-1 cursor-pointer"
            >
              <ReactSVG src="/images/icons/calculator.svg" />
              <span className="absolute top-[-10px] right-[-8px] border-[#fff] border-[2px] bg-lightRed w-5 h-5 flex items-center justify-center text-[10px] font-demi rounded-[50%] text-[#fff]">
                {calc?.services?.length}
              </span>
            </div>
            <button
              onClick={() => setIsOpenMobileSearch(true)}
              className="hidden md:flex bg-gray-100 w-[36px] h-[36px] justify-center items-center cursor-pointer border-[1px] border-solid border-[#E8ECF466] rounded-[8px]"
            >
              <ReactSVG src="/images/icons/burgerSearch.svg" />
            </button>
            <button
              onClick={() => handleCalculator(true)}
              className="hidden md:flex bg-gray-100 w-[36px] h-[36px] justify-center items-center cursor-pointer border-[1px] border-solid border-[#E8ECF466] rounded-[8px]"
            >
              <ReactSVG src="/images/icons/mobilecalculator.svg" />
            </button>
            <UserDropdown />
            {/* {width > 832 && <Language />} */}
          </div>
        </div>
        {width <= 1420 && (
          <div className="container mb-5 md:hidden">
            <Menu />
          </div>
        )}
      </div>
      <div className="h-[102px] menuSize:h-[146px] md:h-[90px]"></div>
    </>
  );
});
