import { Skeleton } from "@mui/material";

export const ClinicSkeleton = () => {
    return (
      <div>
        <Skeleton variant="rounded" height={188} />
        <div className="flex gap-2">
          <Skeleton width={"40%"} variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton width={"30%"} variant="text" sx={{ fontSize: "1rem" }} />
        </div>
        <Skeleton width={"100%"} variant="text" sx={{ fontSize: "1.4rem" }} />
        <Skeleton width={"80%"} variant="text" sx={{ fontSize: "1rem" }} />
        <Skeleton width={"20%"} variant="text" sx={{ fontSize: "1rem" }} />
      </div>
    );
  };