import { Button } from "@mui/material";
import { memo } from "react";

export const CorporateButton = memo(function CorporateButtonComponent({
  icon,
  label = "შენახვა",
  onClick,
  className = "",
  type = "submit",
}: {
  icon?: React.ReactNode;
  label?: string;
  onClick?: () => void;
  className?: string;
  type?: string;
}) {
  return (
    <Button
      aria-label="corporate"
      onClick={onClick}
      className={`group vtbtn hover:bg-blue-100 hover:text-blue-100 overflow-hidden relative bg-blue-100 ${className} text-nowrap whitespace-nowrap shadow-none px-[38px] py-[18px] font-demi text-md leading-[21px] rounded-[12px] gap-2`}
      variant="contained"
      type={'submit'}
    >
      <span className="flex gap-2 relative z-10">
        {label}
        {icon}
      </span>
      <div className="group-hover:translate-x-[102.3%] absolute left-[-100%] bg-[#fff] w-[98.5%] h-[92%] top-[50%] translate-y-[-50%] rounded-[12px] transition-[0.6s]" />
    </Button>
  );
});
