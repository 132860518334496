import { useQuery } from "@tanstack/react-query";
import { API_REQUEST } from "Request/API";

export const useOffersSlugListQuery = (id: any) => {
  const {
    data: offersSlugList,
    isError: isErrorOffersSlugList,
    isLoading: isLoadingOffersSlugList,
    isSuccess: isSuccessOffersSlugList,
  } = useQuery({
    queryKey: [`offers-section-detailed-${id}`],
    queryFn: () =>
      API_REQUEST("GET", `clinic/offer-item-by-offerId`, null, {
        offerId: id,
      }),
    retry: 0,
    enabled: id !== undefined,
    refetchOnWindowFocus: false,
  });

  return {
    offersSlugList,
    isErrorOffersSlugList,
    isLoadingOffersSlugList,
    isSuccessOffersSlugList,
  };
};
