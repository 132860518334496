import { modifyObject } from "Helpers/modifyServerObject";
import { addServices, removeServices } from "Reducers/ServicesSlice";
import { useAppDispatch } from "Store/store";
import { ReactSVG } from "react-svg";

export const RemoveServiceMobileBtn = ({
  check,
  service,
  clinic,
}: {
  check: boolean;
  service: any;
  clinic: any;
}) => {
  const dispatch = useAppDispatch();

  return (
    <button
      onClick={() =>
        check
          ? dispatch(
              addServices(
                modifyObject(
                  service,
                  clinic?.clinicDetails.id,
                  clinic?.clinicDetails
                )
              )
            )
          : dispatch(
              removeServices(
                modifyObject(
                  service,
                  clinic.clinicDetails.id || 0,
                  clinic,
                  true
                )
              )
            )
      }
      className={`${
        check
          ? "text-green-100 bg-green-10"
          : "bg-raspberry-10 text-raspberry-100"
      } flex ml-auto items-center justify-center  w-full rounded-[8px] text-center p-[14px]  font-demi text-rg gap-1`}
    >
      {check ? (
        <>
          <ReactSVG
            beforeInjection={(svg) => {
              svg.setAttribute("style", "width: 20px; height: 20px;");
            }}
            src="/images/icons/plusgreen.svg"
          />
          დამატება
        </>
      ) : (
        "წაშლა"
      )}
    </button>
  );
};
