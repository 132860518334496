import { useQuery } from "@tanstack/react-query";
import { API_REQUEST } from "Request/API";

export const useClinicdetailsQuery = (id: string) => {
  const {
    data: clinicDetails,
    isError: isErrorClinicDetails,
    isLoading: isLoadingClinicDetails,
    isSuccess: isSuccessClinicDetails,
  } = useQuery({
    queryKey: [`clinic-details-${id}`],
    queryFn: () => API_REQUEST("GET", `clinic/details/${id}`),
    retry: 0,
    enabled: id !== undefined,
    refetchOnWindowFocus: false,
  });

  return {
    clinicDetails,
    isErrorClinicDetails,
    isLoadingClinicDetails,
    isSuccessClinicDetails,
  };
};
