import { createTheme } from "@mui/material";

export const theme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          // background: "#fff",
          //   borderRadius: "12px",
          padding: "20px 20px 12px!important",
          fontSize: "14px",
          borderRadius: "12px!important",
        },
        root: {
          width: "100%",
          maxHeight: "52px",
          //   minWidth: 322,
          background: "transparent!important",
          borderRadius: "12px!important",
          overflow: "hidden",
          "&:after": {
            borderBottom: "0px!important",
          },
          "&:before": {
            borderBottom: "0px!important",
          },
          "& .MuiInput-underline:after": {
            borderBottom: "none!important",
          },
          "& .MuiInput-underline:before": {
            borderBottom: "none!important",
          },
          "&:hover": {
            "& .MuiInput-input": {
              color: "#070A1499",
              marginTop: "10px",
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        filled: {
          fontSize: "1rem",
        },
        root: {
          lineHeight: "1.375em",
          padding: "0 10px",
          fontFamily: "avenir-medium",
          "&.Mui-focused": {
            fontSize: "12px",
            color: "#070A1499",
          },
          "&.MuiInputLabel-shrink": {
            fontSize: "12px",
          },
          "&.Mui-error": {
            color: "rgba(0, 0, 0, 0.6)!important",
          },
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          width: "100%",
          borderRadius: "12px!important",
        },
      },
    },
    MuiStack: {
      styleOverrides: {
        root: {
          width: "100%!important",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          marginRight: "0!important",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          marginTop: "8px",
          borderRadius: "12px",
          boxShadow: " 0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          maxHeight: "232px",
          padding: "16px 6px 16px 6px",
          overflowY: "auto",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          fontFamily: "avenir-medium",
          color: "#070a1499",
          "&:hover": {
            background: "none",
            color: "#000!important",
          },
          "&.Mui-selected": {
            background: "none!important",
            color: "#000!important",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            background: "red!important",
          },
        },
      },
    },
  },
});
