import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useCallback, useState } from "react";
import { Gallery } from "Components/Modals/Clinics/Gallery/Gallery";
import useWindowSize from "Hooks/useWindowSize";
import { GallerySection } from "Components/PageSections/Clinics/tabSections/GallerySection";
import { BranchesSection } from "Components/PageSections/Clinics/tabSections/BranchesSection";
import { ServicesSection } from "Components/PageSections/Clinics/tabSections/ServicesSection";

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    variant="scrollable"
  />
))(({ theme }) => ({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "start",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: "60%",
    width: "100%",
    backgroundColor: "#23AD61",
  },
  [theme.breakpoints.down("sm")]: {
    "& .MuiTabs-indicatorSpan": {
      maxWidth: "40%",
    },
    "& .MuiTabs-flexContainer": {
      // overflowX: "auto",
      paddingLeft: "16px",
      paddingRight: "16px",
      // scrollbarWidth: "none",
    },
    "& .MuiTabs-indicator": {
      border: "none",
    },
    // background: "#E8ECF44D",
    // borderTop: "1px solid #070A141A",
    borderBottom: "0px",
    overflowX: "auto",
  },
}));

interface StyledTabProps {
  label: string;
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(18),
  marginRight: theme.spacing(1),
  color: "#070A14",
  justifyContent: "start",
  paddingLeft: 0,
  minHeight: "auto",
  paddingBottom: "16px",
  height: "auto",
  alignItems: "start",
  fontFamily: "avenir-bold",
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
  "&.Mui-selected": {
    color: "#23AD61",
    paddingLeft: 0,
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
    paddingLeft: 0,
  },
}));

export function ClinicDetailTabs({ data }: any) {
  const [value, setValue] = useState(0);
  const { width } = useWindowSize();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
        >
          <StyledTab label="მომსახურებები" />
          <StyledTab label="კლინიკის შესახებ" />
          <StyledTab label="ფილიალები" />
        </StyledTabs>
        {width > 640 && (
          <Box sx={{ p: "0px", mt: "-2px", border: "1px solid #070A141A" }} />
        )}
      </Box>
      <div>
        {/* services */}
        {value == 0 && (
          <ServicesSection
            clinic={data}
            clinicSlug={data?.clinicDetails?.clinicSlug}
          />
        )}
        {/* description & gallery */}
        {value == 1 && <GallerySection data={data} />}
        {/* clinic branches */}
        {value == 2 && <BranchesSection />}
      </div>
    </Box>
  );
}
