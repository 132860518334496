import { API_REQUEST } from "Request/API";

export const handleActiveSub = async (toast: any, cardid: any, refetch: any) =>
  await API_REQUEST("POST", `user/active-subscription/${cardid}`, {
    sourceId: 1,
  })
    .then((res) => {
      refetch();
    })
    .catch(() =>
      toast("მოხდა შეცდომა, თავიდან სცადეთ", {
        type: "error",
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        theme: "light",
      })
    );
