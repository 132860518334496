import { memo } from "react";
import { useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { UserList } from "./UserList";
import { useAppDispatch, useAppSelector } from "Store/store";
import { removeUserData } from "Reducers/UserSlice";
import { useHasUserCard } from "Hooks/Queries/User/useHasUserCard";

export const UserBlock = memo(function UserBlockComponent() {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.user);
  const location = useLocation();
  let pathnames = location?.pathname?.split("/");
  let current = pathnames[pathnames.length - 1];
  const { userHasCard, isLoadingUserHasCard } = useHasUserCard(
    true
  );
  const spanStyle =
    "group-hover:text-green-100 h-[24px] usersvg flex items-center w-full cursor-pointer flex px-[20px] items-center gap-2 font-demi text-blackPrimary-100 leading-[21px] transition-colors";
  const liStyle =
    "group relative after:w-0 transition-all hover:after:transition-all after:content-[''] after:absolute hover:after:w-1 hover:after:h-full hover:after:bg-green-100 hover:after:top-0 hover:after:rounded-r-[4px]";
  const activeListItem =
    "after:w-1 useractiveSvg transition-all after:transition-all after:content-[''] after:absolute after:h-full after:bg-green-100 after:top-0 after:rounded-r-[4px]";

  return (
    <div className="xl:flex sm:h-full xl:gap-5 md:rounded-none md:gap-0 xl:bg-[transparent] xl:flex-row xl:w-full md:bg-gray-30 md:flex-col overflow-hidden relative w-[360px] min-w-[360px] md:w-full bg-gray-30 rounded-[12px] xl:rounded-[0px] after:content-[''] after:bg-no-repeat after:bg-userBg after:w-[100%] md:after:bg-cover md:after:bg-center md:after:h-[100px] after:absolute after:h-[67px] after:top-0 after:left-0">
      <div className="flex relative z-10 mt-[40px] md:rounded-none xl:justify-start items-center flex-col xl:min-w-[360px] md:w-full xl:w-[360px] xl:pb-4 xl:bg-gray-30 xl:rounded-[12px]">
        <div className="bg-lightGreen w-[90px] h-[90px] p-2 xl:mb-[20px] rounded-[12px] border-[4px] border-solid border-[#fff] flex items-center justify-center">
          <img src="/images/icons/avatar.svg" alt="user avatar" />
        </div>
        <h3 className="font-bold leading-[21px] text-rg text-blackPrimary-100 mb-[2px]">
          {state.user.firstName} {state.user.lastName}
        </h3>
        <span className="font-demi leading-[21px] text-rg text-blue-100">
          ID: {state.user.personalId || "პირადი ნომერი არ მოიძებნა"}
        </span>
      </div>
      <div className="xl:w-full xl:relative xl:z-10 md:rounded-none xl:rounded-[12px] xl:m-0 xl:bg-gray-30 my-[32px] mx-[24px] rounded-[12px] bg-[#fff]">
        <div>
          <ul className="flex flex-col gap-[20px] py-[20px]">
            <UserList
              liStyle={liStyle}
              current={current}
              activeListItem={activeListItem}
              spanStyle={spanStyle}
              hasUserCard={userHasCard}
            />
          </ul>
        </div>
        <form action={`${process.env.REACT_APP_VITA}logout`} method="POST">
          <button
            onClick={() => dispatch(removeUserData())}
            type="submit"
            className="border-t-[1px] cursor-pointer flex items-center gap-2 font-demi text-raspberry-100 text-rg border-solid border-gray-100 mx-[24px] pt-[24px] pb-[20px]"
          >
            <ReactSVG src="/images/icons/usertool/exit.svg" />
            გასვლა
          </button>
        </form>
      </div>
    </div>
  );
});
