import { memo } from "react";
import { ReactSVG } from "react-svg";

export const LeftArrow = memo(function LeftArrowComponent({
  handlePrev,
}: {
  handlePrev?: () => void;
}) {
  return (
    <div
      className="bg-[#fff] cursor-pointer rounded-[50%] w-[40px] h-[40px] swiperButtonShadow flex items-center justify-center"
      onClick={handlePrev}
    >
      <ReactSVG src="/images/icons/arrowleft.svg" />
    </div>
  );
});
