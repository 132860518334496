import { Button } from "@mui/material";
import { memo } from "react";
import { ReactSVG } from "react-svg";

export const ContactButton = memo(function ContactButtonComponent({
  onClick,
}: {
  onClick: () => void;
}) {
  return (
    <Button
      onClick={onClick}
      className="w-full gap-2 justify-start flex items-center p-4 bg-green-100 rounded-[12px] text-rg font-bold text-[#fff]"
    >
      <ReactSVG src="/images/icons/contactphonemobile.svg" />
      კონტაქტი
      <ReactSVG className="ml-auto" src="/images/icons/arrowwhite.svg" />
    </Button>
  );
});
