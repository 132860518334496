import { memo } from "react";
import { ReactSVG } from "react-svg";

export const InputMessage = memo(function InputMessage({
  message,
}: {
  message: string;
}) {
  return (
    <div className="text-raspberry-100 font-medium text-[12px] flex items-center gap-[6px] mt-2">
      <ReactSVG src="/images/icons/inputerror.svg" />
      {message}
    </div>
  );
});
