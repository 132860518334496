import { memo } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

export const Footer = memo(function FooterComponent() {
  return (
    <div className="bg-gray-30">
      <div className="container">
        <div className="grid gap-row-2 grid-cols-footerGrid lg:flex lg:flex-col pt-[32px] pb-[44px]">
          <div className="lg:pb-[20px] lg:border-b-[1px] lg:border-solid lg:border-blackPrimary-10">
            <img
              className="mb-[20px] sm:object-contain sm:w-[104px] sm:h-[36px]"
              src="/images/footerlogo.svg"
              alt="footerlogo"
            />
            <span className="block mb-[16px] text-blackPrimary-60 font-regular text-rg max-w-[90%]">
              მიიღე 50%-მდე ფასდაკლება 200+ კლინიკასა და აფთიაქების ქსელში
              ულიმიტოდ და მთელი საქართველოს მასშტაბით
            </span>
            <div className="flex items-center gap-[24px]">
              <Link
                to="https://www.facebook.com/Vitahealthcard"
                target="_blank"
                rel="noopener noreferrer"
                className="activeSocialIcon"
              >
                <ReactSVG src="/images/icons/social/facebook.svg" />
              </Link>
              <Link
                className="activeSocialIcon"
                to="https://www.instagram.com/vita_health_card/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ReactSVG src="/images/icons/social/instagram.svg" />
              </Link>
              <Link
                className="activeSocialIcon"
                to="https://www.youtube.com/@VITA-healthCard"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ReactSVG src="/images/icons/social/youtube.svg" />
              </Link>
              <Link
                className="activeSocialIcon"
                to="https://www.linkedin.com/company/vita-health-card/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ReactSVG src="/images/icons/social/linkedin.svg" />
              </Link>
            </div>
          </div>
          <div className="lg:pt-[20px] lg:pb-[20px] lg:border-b-[1px] lg:border-solid lg:border-blackPrimary-10">
            <h4 className="font-demi text-md leading-[23px] text-blackPrimary-100 mb-[14px]">
              VITA
            </h4>
            <div>
              <ul className="flex flex-col gap-[8px]">
                <li className="text-blackPrimary-60 font-medium">
                  <Link to="/about">ჩვენ შესახებ</Link>
                </li>
                <li className="text-blackPrimary-60 font-medium">
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to={`${process.env.REACT_APP_ISO}`}
                  >
                    ISO სერტიფიცირებული
                  </Link>
                </li>
                <li className="text-blackPrimary-60 font-medium">
                  <Link to="/">ბლოგი</Link>
                </li>
                <li className="text-blackPrimary-60 font-medium">
                  <Link to="/">კონტაქტი</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="lg:pt-[20px] lg:pb-[20px] lg:border-b-[1px] lg:border-solid lg:border-blackPrimary-10">
            <h4 className="font-demi text-md leading-[23px] text-blackPrimary-100 mb-[14px]">
              სწრაფი ლინკები
            </h4>
            <div className="flex flex-col gap-[8px]">
              <li className="text-blackPrimary-60 font-medium">
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to={`${process.env.REACT_APP_RULES}`}
                >
                  წესები და პირობები
                </Link>
              </li>
              <li className="text-blackPrimary-60 font-medium">
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to={`${process.env.REACT_APP_TERMS}`}
                >
                  კონფიდენციალურობის პოლიტიკა
                </Link>
              </li>
              <li className="text-blackPrimary-60 font-medium">
                <Link to="/faq">ხშირად დასმული კითხვები</Link>
              </li>
            </div>
          </div>
          <div className="lg:pt-[20px] lg:pb-[20px]">
            <h4 className="font-demi text-md leading-[23px] text-blackPrimary-100 mb-[14px]">
              კონტაქტი
            </h4>
            <div>
              <ul className="flex flex-col gap-[8px]">
                <li className="text-blackPrimary-60 font-medium">
                  <Link
                    className="flex items-center gap-[10px]"
                    to="tel:0322185577"
                  >
                    <ReactSVG src="/images/icons/footer/phone.svg" /> + 032 2 18
                    55 77
                  </Link>
                </li>
                <li className="text-blackPrimary-60 font-medium">
                  <Link
                    className="flex items-center gap-[10px]"
                    to="mailto:Info@vitaapp.ge"
                  >
                    <ReactSVG src="/images/icons/footer/email.svg" />
                    Info@vitaapp.ge
                  </Link>
                </li>
                <li className="text-blackPrimary-60 font-medium">
                  <Link className="flex items-center gap-[10px]" to="/">
                    <ReactSVG src="/images/icons/footer/location.svg" />
                    ზოვრეთის და მგალობლიშვილის ქუჩების კუთხე
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="border-t-[1px] border-solid border-blackPrimary-10 font-demi text-sm pt-[30px] pb-[20px]">
          2024 © ყველა უფლება დაცულია
        </div>
      </div>
    </div>
  );
});
