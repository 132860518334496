import { memo, useState } from "react";
import { ReactSVG } from "react-svg";

export let days = [
  { title: "ორშაბათი", dayId: 1 },
  { title: "სამშაბათი", dayId: 2 },
  { title: "ოთხშაბათი", dayId: 3 },
  { title: "ხუთშაბათი", dayId: 4 },
  { title: "პარასკევი", dayId: 5 },
  { title: "შაბათი", dayId: 6 },
  { title: "კვირა", dayId: 7 },
];

export const WorkHours = memo(function WorkHoursComponent({
  workingHours,
}: {
  workingHours?: any;
}) {
  const [state, setState] = useState(true);
  return (
    <div>
      <div
        onClick={() => setState(!state)}
        className="cursor-pointer flex gap-1 items-center"
      >
        <ReactSVG src="/images/icons/time.svg" />
        <h2>სამუშაო საათები</h2>
        <ReactSVG
          className={`${state ? "rotate-[180deg]" : "rotate-[0]"} ml-auto`}
          src="/images/icons/arrow.svg"
        />
      </div>
      {state && (
        <div className="mt-4">
          <ul className="flex flex-col gap-4">
            {days?.map(
              (day, i) =>
                workingHours?.filter((e: any) => e?.dayId === day.dayId)?.[0]
                  ?.startHour !== undefined && (
                  <li
                    key={i}
                    className="flex text-rg font-regular text-blackPrimary-60 justify-between items-center"
                  >
                    {day.title}
                    <span className="font-demi text-blackPrimary-100">
                      {workingHours?.filter(
                        (e: any) => e?.dayId === day.dayId
                      )?.[0]?.startHour || "N/A"}{" "}
                      -{" "}
                      {workingHours?.filter(
                        (e: any) => e?.dayId === day.dayId
                      )?.[0]?.endHour || "N/A"}
                    </span>
                  </li>
                )
            )}
          </ul>
        </div>
      )}
    </div>
  );
});
