import { Modal } from "@mui/material";
import { Save } from "Components/Buttons/Save";
import { memo, useRef } from "react";
import { ReactSVG } from "react-svg";

export const ActiveSubscription = memo(function ActiveSubscriptionModal({
  open,
  close,
  handleClick,
}: {
  open: boolean;
  close?: () => void;
  handleClick?: any;
}) {
  const containerRef = useRef(null);

  return (
    <>
      <div className="absolute" ref={containerRef}></div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        container={containerRef.current}
        className="flex items-center justify-center outline-0"
      >
        <div className="w-[398px] py-[20px] px-[30px] outline-0 flex flex-col items-center gap-6 rounded-[12px] bg-[#fff]">
          <h2 className="text-center text-[14px] font-demi text-blackPrimary-60">
            ბარათის გამოწერის ჩართვის სურვილის შემთხვევაში გამოიყენეთ ღილაკი
            “გამოწერის ჩართვა“
          </h2>
          <div className="flex gap-4">
            <Save
              onClick={() => {
                close && close();
                handleClick();
              }}
              variant="green"
              className="w-full max-w-full"
              label="გამოწერის ჩართვა"
            />
          </div>
        </div>
      </Modal>
    </>
  );
});
