import { useQuery } from "@tanstack/react-query";
import { API_REQUEST } from "Request/API";

export const useServicesSearch = (slug: string | number, title: string) => {
  const {
    data: searchedServices,
    isError: isErrorSearchedServices,
    isLoading: isLoadingSearchedServices,
    isSuccess: isSuccessSearchedServices,
  } = useQuery({
    queryKey: [`clinic-services-${slug}-${title}`, [title]],
    queryFn: () =>
      API_REQUEST("GET", `clinic/services-by-clinic-title`, null, {
        slug: slug,
        title: title,
        page: 0,
        size: 10,
      }),
    retry: 0,
    enabled: slug !== undefined && title !== "",
    refetchOnWindowFocus: false,
  });

  return {
    searchedServices,
    isErrorSearchedServices,
    isLoadingSearchedServices,
    isSuccessSearchedServices,
  };
};
