type ModifiedServiesType = {
  amount: number;
  clinic: { id: number };
  offerAmount: number;
  percentage: number;
  title: string;
};

export const serviceChecker = (
  services: ModifiedServiesType[],
  record: any
) => {
  return services?.findIndex((item: any) => item.title == record?.title) == -1;
};
