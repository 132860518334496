import { memo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ThemeProvider,
} from "@mui/material";
import { servicesTabletheme } from "./TableTheme";
import { ReactSVG } from "react-svg";
import { useAppSelector } from "Store/store";
import { serviceChecker } from "Helpers/serviceChecker";
import { RemoveServiceBtn } from "Components/Buttons/RemoveServiceBtn";

type row = {
  title: string;
  percentage: string;
  offerAmount: number;
  amount: number;
};

export const ServicesTable = memo(function ServicesTableComponent({
  services,
  clinic,
}: any) {
  const state = useAppSelector((state) => state?.services);
  return (
    <ThemeProvider theme={servicesTabletheme}>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: { sm: 350, md: 650 } }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>მომსახურების დასახელება</TableCell>
              <TableCell align="left">ფასდაკლება</TableCell>
              <TableCell className="text-nowrap" align="left">
                არსებული ფასი
              </TableCell>
              <TableCell className="text-nowrap" align="left">
                შეთავაზებული ფასი
              </TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {services?.map((row: row, i: number) => {
              const check = serviceChecker(state?.services, row);
              return (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell className="font-medium" component="th" scope="row">
                    <h3>{row.title}</h3>
                  </TableCell>
                  <TableCell className="font-medium" align="left">
                    <h3>{row.percentage}%</h3>
                  </TableCell>

                  <TableCell className="font-medium" align="left">
                    <h3>{row.amount}</h3>
                  </TableCell>
                  <TableCell className="font-medium" align="left">
                    <h3>{row.offerAmount}</h3>
                  </TableCell>
                  <TableCell
                    className="flex items-center gap-1 cursor-pointer"
                    align="left"
                  >
                    {check && (
                      <ReactSVG
                        width={16}
                        height={16}
                        src="/images/icons/plusgreen.svg"
                      />
                    )}
                    <RemoveServiceBtn clinic={clinic} row={row} check={check} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
});
