import { Type1 } from "Components/PageSections/Offers/Types/Type1";
import { Type2 } from "Components/PageSections/Offers/Types/Type2";
import { useOffersSlugListQuery } from "Hooks/Queries/useOffersSlugListQuery";
import { useSlugs } from "Hooks/useQuery";

export const Offers = () => {
  let loading: boolean = false;
  const slugs = useSlugs();
  const slug = slugs[slugs.length - 1];
  const { offersSlugList } = useOffersSlugListQuery(slug);

  switch (offersSlugList?.offerType) {
    case "SPECIAL":
      return <Type1 offersSlugList={offersSlugList} />;
    case "WEEKEND":
      return <Type2 offersSlugList={offersSlugList} />;
    default:
      return <div></div>;
  }
};
