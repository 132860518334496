import { memo, useRef, useState } from "react";
import { DropdownMenu, theme } from "./DropdownMenu";
import { Menu, Skeleton, ThemeProvider } from "@mui/material";
import { UserBlock } from "Components/PageSections/User/UserBlock/UserBlock";
import { UserList } from "Components/PageSections/User/UserBlock/UserList";
import { ReactSVG } from "react-svg";
import useWindowSize from "Hooks/useWindowSize";
import { Link, useNavigate } from "react-router-dom";
import { anchor } from "types/types";
import { MouseEvents } from "types/ReactTypes";
import { Save } from "Components/Buttons/Save";
import { useGetUserQuery } from "Reducers/ApiSlice";
import { useAppDispatch, useAppSelector } from "Store/store";
import { removeUserData } from "Reducers/UserSlice";
import { useHasUserCard } from "Hooks/Queries/User/useHasUserCard";

export const UserDropdown = memo(function UserDropdownComponent() {
  const dispatch = useAppDispatch();
  const { width } = useWindowSize();
  const [anchorEl, setAnchorEl] = useState<anchor>(null);
  const open = Boolean(anchorEl);
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const { isError, isLoading, data, error } = useGetUserQuery();
  const state = useAppSelector((state) => state.user);
  const { userHasCard, isLoadingUserHasCard } = useHasUserCard(
    error?.isAuthorized
  );
  const handleClick = (event: MouseEvents) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const spanStyle =
    "group-hover:text-green-100 h-[24px] usersvg flex items-center w-full cursor-pointer flex px-[20px] items-center gap-2 font-medium text-blackPrimary-100 leading-[21px] transition-colors";
  const liStyle =
    "group relative after:w-0 transition-all hover:after:transition-all after:content-[''] after:absolute hover:after:w-1 hover:after:h-full hover:after:bg-green-100 hover:after:top-0 hover:after:rounded-r-[4px]";
  const activeListItem =
    "after:w-1 useractiveSvg transition-all after:transition-all after:content-[''] after:absolute after:h-full after:bg-green-100 after:top-0 after:rounded-r-[4px]";

  return (
    <>
      {isLoading || error?.skeleton ? (
        <Skeleton width={100} height={70} />
      ) : error?.isAuthorized ? (
        <div ref={containerRef}>
          <div
            onClick={(event: any) =>
              width > 832 ? handleClick(event) : navigate("/user/menu")
            }
            className="cursor-pointer w-[52px] p-2 h-[52px] md:w-[36px] md:h-[36px] rounded-[12px] bg-lightGreen"
          >
            <img src="/images/icons/avatar.svg" />
          </div>
          {width > 832 && (
            <ThemeProvider theme={theme}>
              <Menu
                container={containerRef.current}
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <div className="flex relative mx-5 mt-3 mb-5 items-center gap-3">
                  <div className="bg-lightGreen p-2 w-[52px] h-[52px] rounded-[12px] border-[4px] border-solid border-[#fff] flex items-center justify-center">
                    <img src="/images/icons/avatar.svg" alt="user avatar" />
                  </div>
                  {state.isLoading || state.isError ? (
                    <div className="flex flex-col">
                      <div className="flex items-center gap-2">
                        <Skeleton width={60} height={20} />
                        <Skeleton width={80} height={20} />
                      </div>
                      <Skeleton width={120} height={20} />
                    </div>
                  ) : (
                    <div>
                      <h3 className="font-bold leading-[21px] text-rg text-blackPrimary-100 mb-[1px]">
                        {state.user.firstName} {state.user.lastName}
                      </h3>
                      <span className="font-demi leading-[21px] text-rg text-blue-100">
                        ID:
                        {state.user.personalId || "პირადი ნომერი არ მოიძებნა"}
                      </span>
                    </div>
                  )}
                </div>
                <ul className="flex flex-col gap-5">
                  <div className="border-b-gray-100 border-b-solid border-b-[1px] mx-[20px]"></div>
                  <UserList
                    liStyle={liStyle}
                    activeListItem={activeListItem}
                    spanStyle={spanStyle}
                    close={handleClose}
                    hasUserCard={userHasCard}
                  />
                  <div className="border-b-gray-100 border-b-solid border-b-[1px] mx-[20px]"></div>
                </ul>
                <form
                  action={`${process.env.REACT_APP_VITA}logout`}
                  method="POST"
                >
                  <button
                    onClick={() => dispatch(removeUserData())}
                    type="submit"
                    className="border-t-[1px] cursor-pointer flex items-center gap-2 font-demi text-raspberry-100 text-rg border-solid border-gray-100 mx-[24px] pt-[20px] pb-[20px]"
                  >
                    <ReactSVG src="/images/icons/usertool/exit.svg" />
                    გასვლა
                  </button>
                </form>
              </Menu>
            </ThemeProvider>
          )}
        </div>
      ) : (
        <a href="/secured">
          <Save
            // onClick={() => window.open("/secured")}
            className="sm:text-[12px] sm:px-[16px!important] sm:py-[12px!important]"
            variant="green"
            label="შესვლა"
          />
        </a>
      )}
    </>
  );
});
