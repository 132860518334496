import { useQuery } from "@tanstack/react-query";
import { API_REQUEST } from "Request/API";

export const useSubCategoriesQuery = (slugs: any, slug: any) => {
  const {
    data: childrenCategories,
    isError: childrenIsError,
    isLoading: childrenIsLoading,
  } = useQuery({
    queryKey: [
      `clinicSubCategories-${
        slugs.length == 2 ? slugs[slugs.length - 1] : slugs[slugs.length - 2]
        // თუ საბკატეგორიაა, მაგ შემთხვევაში ისევ მშობელი კატეგორიის ქუერი უნდა იყოს.
        // ამიტომ ვიყენებ slugs[slugs.length - 2]
      }`,
    ],
    queryFn: () =>
      API_REQUEST("GET", `clinic/clinic-sub-categories`, null, {
        slug:
          slugs.length == 2 ? slugs[slugs.length - 1] : slugs[slugs.length - 2],
      }),
    retry: 0,
    enabled: slug !== undefined && slug !== "clinics",
    refetchOnWindowFocus: false,
  });

  return { childrenCategories, childrenIsError, childrenIsLoading };
};
