export const collectRegions = (clinicResults: any) => {
  let regions: any = [];

  if (clinicResults) {
    for (let clinic of clinicResults) {
      if (regions.some((e: any) => e?.region == clinic?.address?.region)) {
        continue;
      }
      regions.push(clinic?.address);
    }
  }

  return regions;
};
