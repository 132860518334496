import { memo } from "react";

export const MobileModal = memo(function MobileModalComponent({
  title,
  children,
}: {
  title: string,
  children: any
}) {
  return (
    <div className="max-h-[90dvh] p-4 bg-[#FFFFFF] absolute bottom-0 w-full rounded-t-[16px]">
      <h2 className="text-md text-blackPrimary-100 font-bold mb-4">{title}</h2>
      {children}
    </div>
  );
});
