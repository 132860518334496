import { Skeleton } from "@mui/material";
import { memo } from "react";
import { ReactSVG } from "react-svg";

export const SocialComponent = memo(function SocialComp({
  clinic,
  color = "",
  img,
  url,
}: any) {
  return (
    <div className="flex items-center gap-2">
      <ReactSVG src={img} />
      <span
        style={{ color: color }}
        className={`${
          color && "line-clamp-1"
        } text-[14px] font-regular text-dark-default leading-[120%]`}
      >
        {url}
      </span>
    </div>
  );
});

const Social = memo(
  ({ clinic, isLoadingClinic, isErrorClinic, color = "" }: any) => {
    return (
      <div className="flex items-center gap-2">
        {isLoadingClinic || isErrorClinic ? (
          <Skeleton width="100%" height="20px" />
        ) : (
          <>
            <SocialComponent color={color} clinic={clinic} />
          </>
        )}
      </div>
    );
  }
);

Social.displayName = "ClinicSocialWithSkeleton";

export { Social };
