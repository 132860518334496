import { AddPaymentMethod } from "Components/Buttons/AddPaymentButton";
import { BankCard } from "Components/Cards/BankCard";
import { UserLayout } from "Layouts/User/Layout";
import { UserSectionContainer } from "Components/PageSections/User/UserSectionContainer";
import { UserSectionHeader } from "Components/PageSections/User/UserSectionHeader";
import { ReactSVG } from "react-svg";
import { BasicBreadcrumbs } from "Components/Breadcrumbs/Breadcrumbs";
import { useUserBankCardsQuery } from "Hooks/Queries/User/useUserBankCardsQuery";
import { API_REQUEST } from "Request/API";
import { ToastContainer, toast } from "react-toastify";
import { handleAddCardRequest } from "Functions/Checkout/handleAddCardRequest";
import { useBankCardCheckQuery } from "Hooks/Queries/User/useBankCardCheckQuery";
import { useCustomQuery } from "Hooks/useQuery";
import { useEffect, useState } from "react";
import { SavedCardPayment } from "Components/Modals/Payments/SaveCard";
import { ErrorPayment } from "Components/Modals/Payments/ErrorPayment";
import { Helmet } from "react-helmet";

export const BankCards = () => {
  const { bankCard, isLoadingBankCard, isErrorBankCard, isSuccessBankCard } =
    useUserBankCardsQuery();
  const query = useCustomQuery();

  const { bankCardCheck, isLoadingBankCardCheck, isSuccessBankCardCheck } =
    useBankCardCheckQuery(query.get("orderId"));
  const [isOpenSuccess, setIsOpenSuccess] = useState(false);
  const [isOpenErrorMessage, setIsOpenErrorMessage] = useState(false);

  useEffect(() => {
    if (query.get("orderId")) {
      if (isLoadingBankCardCheck == false && isSuccessBankCardCheck) {
        if (
          JSON.parse(
            JSON.parse(JSON.stringify(localStorage.getItem("bankCardOrderId")))
          ) != query.get("orderId")
        ) {
          if (bankCardCheck?.status == "SUCCESS") {
            setIsOpenSuccess(true);
            localStorage.setItem(
              "bankCardOrderId",
              JSON.stringify(query.get("orderId"))
            );
          } else if (bankCardCheck?.status == "PAYMENT_FAILED") {
            setIsOpenErrorMessage(true);
            localStorage.setItem(
              "orderId",
              JSON.stringify(query.get("bankCardOrderId"))
            );
          }
        }
      }
    }
  }, [isSuccessBankCardCheck, isLoadingBankCardCheck]);

  return (
    <>
      <ToastContainer />
      <UserLayout>
        <SavedCardPayment
          open={isOpenSuccess}
          close={() => setIsOpenSuccess(false)}
        />
        <ErrorPayment
          open={isOpenErrorMessage}
          close={() => setIsOpenErrorMessage(true)}
        />
        <BasicBreadcrumbs
          breadcrumbs={[
            {
              title: "საბანკო ბარათები",
              link: "user/bankcards",
            },
          ]}
        />
        <UserSectionHeader title="ჩემი ბართები" />
        <UserSectionContainer>
          <div className="grid grid-cols-2 md:grid-cols-1 gap-[30px]">
            {bankCard?.savedCards?.map((card: any, index: any) => (
              <BankCard card={card} key={index} />
            ))}
            <AddPaymentMethod
              onClick={() => handleAddCardRequest(toast, false)}
              title="დაამატე გადახდის მეთოდი"
              icon={<ReactSVG src="/images/icons/bankcard.svg" />}
            />
          </div>
          {/* <div className="flex flex-col mt-[34px]">
            <div className="grid grid-cols-2 md:grid-cols-1 gap-[30px] mb-[12px]">
              <div>
                <h2 className="font-demi text-md text-blackPrimary-100 leading-[24px] mb-[6px]">
                  სარეზერვო
                </h2>
                <span className="leading-[21px] font-regular text-sm text-blackPrimary-60">
                  როდესაც გადახდის ძირითადი მეთოდი ვერ ხერხდება, სარეზერვო
                  გადახდის მეთოდი ავტომატურად იხდის ბალანსს
                </span>
              </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-1 gap-[30px]">
              <AddPaymentMethod
                title="სარეზერვო გადახდის მეთოდი"
                icon={<ReactSVG src="/images/icons/plus.svg" />}
              />
            </div>
          </div> */}
        </UserSectionContainer>
      </UserLayout>
    </>
  );
};
