import { memo, useCallback, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { SatisfiedCostumer } from "Components/Cards/SatisfiedCostumer";
import { LeftArrow } from "Components/SwiperButtons/LeftArrow";
import { RightArrow } from "Components/SwiperButtons/RightArrow";

let users = [
  {
    name: "ნინო ცერცვაძე",
    city: "თბილისი",
    img: "/images/user/mariam.svg",
    color: "#3A6CE84D",
    text: "კმაყოფილი ვარ ლაბორატორიულ კვლევებზე დიდი ფასდაკლებებით. პერიოდულად ერთიანად მესაჭიროება გამოკვლევების ჩატარება და VITA ბარათით კვლევებში გადახდილი თანხა საგრძნობლად ნაკლებია მიმდინარე ფასებთან შედარებით.",
  },
  {
    name: "ლია ბაწელაშვილი",
    city: "თბილისი",
    img: "/images/user/nin.svg",
    color: "#3A6CE84D",
    text: "კმაყოფილი და მადლიერი ვარ, რადგან პენსიონრებისთვის და იმ ადამიანებისთვის, ვისაც დაზღვევა არ აქვს ძალიან დიდი შეღავათია ვიტა ბარათის ქონა! სამედიცინო მომსახურებებზე საშუალოდ 50% ფასდაკლება მნიშვნელოვანი ფინანსური დანაზოგია.",
  },
  {
    name: "ქეთევან მიქელაძე",
    city: "თბილისი",
    img: "/images/user/mariam.svg",
    color: "#EF357D4D",
    text: "ზოგადად კმაყოფილი ვარ, რომ მაღალი 40%-50% ფასდაკლებით შემიძლია ვისარგებლო ბარათის გამოყენებისას როგორც ლაბორატორიულ ანალიზებზე, ასევე სხვა ტიპის კვლევებზე და კონსულტაციებზე.",
  },
  {
    name: "ია გაბრიჭიძე",
    city: "თბილისი",
    img: "/images/user/nin.svg",
    color: "#23AD614D",
    text: "აქტიურად ვიყენებ ვიტა ბარათს სხვადასხვა ტიპის კვლევებსა და ანალიზებზე. კმაყოფილი ვარ როგორც პროვაიდერში მიღებული მომსახურებით ასევე იმ ფასდაკლებებით რომელიც ბარათმა მომცა.",
  },
];

export const VitaUsers = memo(function VitaUsersComponent() {
  const sliderRef = useRef<any>(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <div className="container mt-[90px] lg:mt-[60px] lg:mb-[60px] mb-[160px]">
      <div className="flex justify-between mb-[44px] lg:mb-[34px] md:mb-[24px] items-center">
        <h2 className="font-bold text-[40px] lg:text-sloganLgSize md:text-sloganMdSize leading-[56px] lg:leading-[34px] md:leading-[24px] text-blackPrimary-100">
          კმაყოფილი მომხმარებლები
        </h2>
        <div className="flex items-center gap-5 md:hidden">
          <LeftArrow handlePrev={handlePrev} />
          <RightArrow handleNext={handleNext} />
        </div>
      </div>
      <Swiper
        ref={sliderRef}
        spaceBetween={12}
        breakpoints={{
          600: {
            slidesPerView: 3,
          },
          1280: {
            slidesPerView: 4,
          },
        }}
        className="md:hidden"
      >
        {users.map((_, i) => (
          <SwiperSlide key={i}>
            <SatisfiedCostumer user={_} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="hidden md:flex md:flex-col md:gap-5">
        {users.map((_, i) => (
          <SwiperSlide key={i}>
            <SatisfiedCostumer user={_} />
          </SwiperSlide>
        ))}
      </div>
    </div>
  );
});
