import { useQuery } from "@tanstack/react-query";
import { Footer } from "Components/Footer/Footer";
import { Header } from "Components/Header/Header";
import ScrollToTop from "Helpers/ScrolltoTop";
import { setCategories } from "Reducers/CategoriesSlice";
import { API_REQUEST } from "Request/API";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

export const Layout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const blacklist = [
    "/user",
    "/user/",
    "/user/card",
    "/user/card/",
    "/user/bankcards",
    "/user/bankcards/",
    "/user/notifications",
    "/user/notifications/",
    "/user/menu",
    "/user/menu/",
  ];
  const showFooter = !blacklist.includes(location.pathname);

  const { data, isError, isLoading, isSuccess } = useQuery({
    queryKey: ["categories"],
    queryFn: () => API_REQUEST("GET", "clinic/clinic-categories"),
    retry: 0,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    dispatch(
      setCategories({
        data: data || [],
        isError,
        isLoading,
        isSuccess,
      })
    );
  }, [isError, isLoading, isSuccess]);

  return (
    <>
      <div>
        <ScrollToTop />
      </div>
      {<Header />}
      <div className="flex-1">
        <Outlet />
      </div>
      {showFooter && <Footer />}
    </>
  );
};
