import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface CategoriesSlice {
  list: any;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
}

const initialState: CategoriesSlice = {
  list: [],
  isLoading: true,
  isError: false,
  isSuccess: false,
};

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setCategories: (state, action: PayloadAction<any>) => {
      state.list = action.payload.data;
      state.isLoading = action.payload.isLoading;
      state.isError = action.payload.isError;
      state.isSuccess = action.payload.isSuccess;
    },
  },
});

export const { setCategories } = categoriesSlice.actions;
export default categoriesSlice.reducer;
