import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchMembers = createAsyncThunk(
  "familyMembers/fetchMembers",
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_VITA_APP}/user/group`
    );
    return response.data;
  }
);
