import { memo, useRef } from "react";
import { Modal } from "@mui/material";
import { AddMemberButton } from "Components/Buttons/AddMemberButton";
import { MemberCard } from "Components/Cards/MemberCard";
import { MobileModal } from "Layouts/User/MobileModal";
import { MembersModalTypes } from "types/MembersModal";
import { useAppSelector } from "Store/store";
import { Link } from "react-router-dom";
import { useHasUserCard } from "Hooks/Queries/User/useHasUserCard";

export const MembersModal = memo(function MembersModalComponent({
  open,
  close,
  handleRemoveMember,
  handleCreateMember,
  type = "default",
}: MembersModalTypes & { type?: string }) {
  const containerRef = useRef(null);
  const state = useAppSelector((state) => state.familymembers);
  const { userHasCard, isLoadingUserHasCard } = useHasUserCard(true); // always authorized if user reachs this page

  return (
    <>
      <div ref={containerRef}></div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        container={containerRef.current}
        className="flex items-center"
      >
        <MobileModal title="ოჯახის წევრები">
          <div className="flex flex-col gap-3">
            {type == "default" ? (
              <AddMemberButton handleClick={handleCreateMember} />
            ) : (
              <Link
                className="w-full"
                to={userHasCard ? "/createmember" : "/checkout"}
              >
                <AddMemberButton className="w-full" />
              </Link>
            )}
            {state?.members?.map((member: any, i: number) => {
              if (type === "profile" || !member.isAproved) {
                return (
                  <MemberCard
                    member={member}
                    handleRemoveMember={handleRemoveMember}
                    key={i}
                  />
                );
              }
              return null;
            })}
          </div>
        </MobileModal>
      </Modal>
    </>
  );
});
