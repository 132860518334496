import { ContactUs } from "Components/PageSections/Corporate/ContactUs/ContactUs";
import { CorporateBenefit } from "Components/PageSections/Corporate/CorporateBenefit/CorporateBenefit";
import { CorporateFeature } from "Components/PageSections/Corporate/CorporateFeature/CorporateFeature";
import { Partners } from "Components/PageSections/Corporate/Partners/Partners";
import { SatisfiedUsers } from "Components/PageSections/Corporate/SatisfiedUsers/SatisfiedUsers";
import { VitaForCorporate } from "Components/PageSections/Corporate/VitaForCorporate/VitaForCorporate";
import useWindowSize from "Hooks/useWindowSize";
import { useRef } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";

export const Corporate = () => {
  const contactUsRef = useRef(null);
  const { width } = useWindowSize();
  return (
    <div>
      <Helmet>
        <title>
          VITA-ს კორპორატიული ბენეფიტი | შეთავაზება კომპანიებსა და კორპორაციებს
        </title>
        <meta
          name="description"
          content="კომპანიებისა და ორგანიზაციებისთვის VITA, თანამშრომლის კორპორატიული ბენეფიტია, რომლის საშუალებითაც ისინი ზრუნავენ თანამშრომლების ფიზიკურ, მენტალურ და ესთეტიკურ ჯანმრთელობაზე, რაც შემდგომში თანამშრომელთა მოტივაციისა და შრომის ნაყოფიერების მომატებაზე აისახება და ხელს უწყობს ორგანიზაციაში ჯანსაღი სამუშაო კულტურის შექმნას."
        />
        {/* <link rel="canonical" href="http://vitaapp.ge/corporate" /> */}
      </Helmet>
      <ToastContainer />
      <CorporateFeature contactUsRef={contactUsRef} />
      <VitaForCorporate />
      <CorporateBenefit />
      <div className="container sm:mb-[60px] mb-[100px]">
        <Link
          target="_blank"
          rel="noopener noreferrer"
          to={`${process.env.REACT_APP_ISO}`}
        >
          <div className="w-full rounded-[12px] overflow-hidden">
            {width > 640 ? (
              <img
                className="object-contain"
                src="/images/VITA-ISO-new-blue.png"
                alt=""
              />
            ) : (
              <img
                className="object-contain w-full"
                src="/images/VITA-ISO-mob-10.png"
                alt=""
              />
            )}
          </div>
        </Link>
      </div>
      <Partners />
      {/* <SatisfiedUsers /> */}
      <ContactUs contactUsRef={contactUsRef} />
    </div>
  );
};
