import { Button } from "@mui/material";
import { memo } from "react";
import { ReactSVG } from "react-svg";
import { HandleClick } from "types/ButtonTypes";

export const AddMemberButton = memo(function AddMemberButtonComponent({
  handleClick,
  className = "",
}: HandleClick & { className?: string }) {
  return (
    <Button
      aria-label="add-family-member"
      onClick={handleClick}
      className={`${className} md:flex-row md:p-4 custom-dashed-border text-center bg-gray-30 pt-[28px] pb-[32px] px-[40px] flex flex-col gap-3 items-center justify-center rounded-[12px]`}
    >
      <ReactSVG src="/images/icons/plus.svg" />
      დაამატე ოჯახის წევრი
    </Button>
  );
});
