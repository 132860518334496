import styled from "@emotion/styled";
import { Pagination, PaginationItem } from "@mui/material";
import useWindowSize from "Hooks/useWindowSize";
import { memo } from "react";

export const CustomPagination = memo(function CustomPaginationComponent({
  totalPages,
  moveToPage,
  page,
}: any) {
  const { width } = useWindowSize();
  const StyledPagination = styled(Pagination)(({ theme }) => {
    return {
      "& .MuiPaginationItem-root": {
        fontSize: width > 640 ? "14px" : "12px",
        color: "#070A144D",
        fontFamily: "avenir-demi",
        maxWidth: "36px",
        minWidth: "36px",
        height: "36px",
        display: "flex",
        alignItems: "center",
        margin: width <= 640 ? "0" : "3px",
        "&.Mui-selected": {
          color: "#fff",
          background: "#23AD61",
        },
        "&.Mui_selected:hover": {
          background: "#23AD61",
        },
      },
    };
  });
  return (
    <StyledPagination
      count={totalPages}
      page={page}
      shape="rounded"
      className="pagination-web"
      onChange={(_, pg) => {
        moveToPage(pg);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }}
      //   siblingCount={0}
      boundaryCount={1}
      renderItem={(item) => (
        <PaginationItem
          {...item}
          // shape="rounded"
          size="large"
          color="secondary"
        />
      )}
    />
  );
});
