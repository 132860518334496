import { useQuery } from "@tanstack/react-query";
import { API_REQUEST } from "Request/API";

export const useCitiesQuery = () => {
  const {
    data: cities,
    isError: isErrorCities,
    isLoading: isLoadingCities,
    isSuccess: isSuccessCities,
  } = useQuery({
    queryKey: [`all-city`],
    queryFn: () => API_REQUEST("GET", `clinic/all-city`),
    retry: 0,
    refetchOnWindowFocus: false,
  });

  return {
    cities,
    isErrorCities,
    isLoadingCities,
    isSuccessCities,
  };
};
