import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import { FormControlLabel } from "@mui/material";
import { memo } from "react";
import { CheckboxTypes } from "types/ButtonTypes";

const BpIcon = styled("span")(({ theme }) => ({
  width: "24px!important",
  height: "24px!important",
  borderRadius: "6px",
  backgroundColor: "#E8ECF4B2",
  border: "1px solid #070A1433",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  //   "input:hover ~ &": {
  //     backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  //   },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#23AD61",
  border: "transparent!important",
  borderRadius: "6px",
  //   backgroundImage:
  //     "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 24,
    height: 24,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#23AD61",
  },
});

// Inspired by blueprintjs
export function BpCheckbox(props: CheckboxProps) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
        padding: "5px",
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: "14px!important",
          fontFamily: "avenir-medium",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          padding: "4px!important",
        },
      },
    },
  },
});

export const CustomCheckbox = memo(function CustomCheckboxComponent({
  label,
  checked = false,
  onChange,
  name,
}: CheckboxTypes) {
  return (
    <ThemeProvider theme={theme}>
      <FormControlLabel
        control={
          <BpCheckbox
            name={name}
            onChange={onChange}
            value={checked}
            checked={checked}
          />
        }
        label={label}
      />
    </ThemeProvider>
  );
});
