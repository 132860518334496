import { BpCheckbox } from "Components/Checkbox/Checkbox";
import { UserLayout } from "Layouts/User/Layout";
import { UserSectionContainer } from "Components/PageSections/User/UserSectionContainer";
import { UserSectionHeader } from "Components/PageSections/User/UserSectionHeader";
import { ReactSVG } from "react-svg";
import useWindowSize from "Hooks/useWindowSize";
import { BasicBreadcrumbs } from "Components/Breadcrumbs/Breadcrumbs";
import { useNotificationAccess } from "Hooks/Queries/User/useNotificationAccess";
import { API_REQUEST } from "Request/API";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

export const Notifications = () => {
  const { width } = useWindowSize();
  const {
    notifications,
    isLoadingNotifications,
    isSuccessNotifications,
    refetch,
  } = useNotificationAccess(true);

  const updateNofiticationType = (data: any) => {
    API_REQUEST(
      "POST",
      "user/create-update-notification-access-type",
      data,
      undefined,
      "fullresponse"
    )
      .then(() => refetch())
      .catch(() =>
        toast("მოხდა შეცდომა", {
          type: "error",
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          theme: "light",
        })
      );
  };

  return (
    <UserLayout>
      <BasicBreadcrumbs
        breadcrumbs={[
          {
            title: "შეტყობინებები",
            link: "user/notifications",
          },
        ]}
      />
      {width > 832 && <UserSectionHeader title="შეტყობინებები" />}
      <UserSectionContainer>
        <h2 className="font-medium text-rg text-blackPrimary-60 md:mb-[22px]">
          მართე შენს ინტერესებზე მორგებული სიახლეებისა და შეთავაზებების მიღება.
        </h2>
        <div>
          <div className="flex justify-end relative z-10 gap-6 mb-5 mr-2">
            <div className="flex flex-col items-center justify-center">
              <ReactSVG src="/images/icons/email.svg" />
              <span className="md:hidden font-bold text-rg text-blackPrimary-100">
                Email
              </span>
            </div>
            <div className="flex flex-col items-center justify-center">
              <ReactSVG src="/images/icons/phone.svg" />
              <span className="md:hidden font-bold text-rg text-blackPrimary-100">
                SMS
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-[22px]">
            {isSuccessNotifications &&
              !isLoadingNotifications &&
              notifications?.map((notify: any) => (
                <div className="flex justify-between items-center">
                  <span className="font-demi text-[12px] text-blackPrimary-100">
                    {notify.notificationAccessTypeName}
                  </span>
                  <div className="flex justify-end gap-7 md:gap-4 mr-1">
                    <BpCheckbox
                      onChange={(e) =>
                        updateNofiticationType({
                          notificationAccessTypeId:
                            notify?.notificationAccessTypeId,
                          type: "MAIL",
                        })
                      }
                      defaultChecked={notify?.type?.mail}
                    />
                    <BpCheckbox
                      onChange={(e) =>
                        updateNofiticationType({
                          notificationAccessTypeId:
                            notify?.notificationAccessTypeId,
                          type: "SMS",
                        })
                      }
                      defaultChecked={notify?.type?.sms}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </UserSectionContainer>
    </UserLayout>
  );
};
