import { useCallback, useEffect, useRef, useState } from "react";
import { ReactSVG } from "react-svg";
import { Box, IconButton, Skeleton } from "@mui/material";
import { LeftArrow } from "Components/SwiperButtons/LeftArrow";
import { RightArrow } from "Components/SwiperButtons/RightArrow";
import { useSlugs } from "Hooks/useQuery";
import { Link } from "react-router-dom";
import { RootState, useAppSelector } from "Store/store";

export const Menu = () => {
  const slugs = useSlugs();
  const slug = slugs[slugs.length - 1];
  const buttonRefs = useRef<any>([]);
  const menuRef = useRef<HTMLDivElement>(null);
  const categories = useAppSelector((state: RootState) => state.categories);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);

  const checkScrollPosition = () => {
    if (menuRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = menuRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const scroll = (scrollOffset: number) => {
    if (menuRef.current) {
      menuRef.current.scrollLeft += scrollOffset;
      setTimeout(checkScrollPosition, 100); // Check scroll position after scrolling
    }
  };

  const scrollTo = useCallback(
    (index: number) => {
      if (menuRef.current && buttonRefs.current[index]) {
        const container = menuRef.current;
        const tabElement = buttonRefs.current[index];
        const tabLeft = tabElement.offsetLeft;
        const tabWidth = tabElement.offsetWidth;
        const containerWidth = container.offsetWidth;
        const scrollPosition =
          categories?.list?.length - 1 == index
            ? tabLeft
            : tabLeft - (containerWidth / 2 - tabWidth / 2);
        container.scrollTo({
          left: scrollPosition,
          behavior: "smooth",
        });
      }
    },
    [categories?.list]
  );

  useEffect(() => {
    scrollTo(categories?.list?.findIndex((e: any) => e.slug == slug));
  }, [categories?.list]);

  useEffect(() => {
    checkScrollPosition();
    if (menuRef.current) {
      menuRef.current.addEventListener("scroll", checkScrollPosition);
    }
    return () => {
      if (menuRef.current) {
        menuRef.current.removeEventListener("scroll", checkScrollPosition);
      }
    };
  }, [categories?.list]);

  return (
    <Box
      display="flex"
      alignItems="center"
      paddingTop={"16px"}
      marginBottom={"30px"}
      borderTop={"1px solid #070A141A"}
      sx={{
        transition: ".3s",
      }}
      className="sm:hidden"
    >
      {categories?.list && canScrollLeft && (
        <IconButton onClick={() => scroll(-300)} disabled={!canScrollLeft}>
          <LeftArrow />
        </IconButton>
      )}
      {!categories?.isLoading ? (
        categories?.list && (
          <Box
            ref={menuRef}
            sx={{
              display: "flex",
              overflowX: "auto",
              whiteSpace: "nowrap",
              gap: "32px",
              width: "100%",
              scrollBehavior: "smooth",
              overflowY: "hidden",
              "::-webkit-scrollbar": { display: "none" }, // Hide the scrollbar
            }}
          >
            {categories?.list?.map((item: any, index: number) => (
              <Link key={index} to={`${item.slug}`}>
                <div
                  ref={(el: any) => (buttonRefs.current[index] = el)}
                  onClick={() => scrollTo(index)}
                  className={`${
                    (slug == item.slug ||
                      item.slug == slugs[slugs.length - 2]) &&
                    "text-blue-100"
                  } group svgfilterhover h-[44px] flex cursor-pointer items-start gap-2 whitespace-nowrap text-nowrap transition-all hover:text-blue-100`}
                >
                  <div
                    className={`${
                      (slug == item.slug ||
                        item.slug == slugs[slugs.length - 2]) &&
                      "bg-blue-100"
                    } group-hover:bg-blue-100 transition-all w-[36px] h-[36px] bg-blue-10 rounded-[50%] flex items-center justify-center`}
                  >
                    <img
                      className={`${
                        (slug == item.slug ||
                          item.slug == slugs[slugs.length - 2]) &&
                        "defaultHoverForSvg"
                      } hoverforsvg`}
                      src={item.iconUrl}
                      alt="category icon"
                    />
                  </div>
                  <h2
                    className={`h-full flex flex-col mt-[6px] text-rg font-demi`}
                  >
                    {item.title}
                    <div
                      className={`${
                        (slug == item.slug ||
                          item.slug == slugs[slugs.length - 2]) &&
                        "bg-blue-100"
                      }  w-[40%] h-[1px] mt-[10px]`}
                    ></div>
                  </h2>
                </div>
              </Link>
            ))}
          </Box>
        )
      ) : (
        <Box
          ref={menuRef}
          sx={{
            display: "flex",
            overflowX: "auto",
            whiteSpace: "nowrap",
            gap: "32px",
            width: "100%",
            scrollBehavior: "smooth",
            "::-webkit-scrollbar": { display: "none" }, // Hide the scrollbar
          }}
        >
          {Array.from({ length: 3 }).map((_, index) => (
            <div key={index} className="flex gap-6 overflow-x-auto">
              {Array.from({ length: 3 }).map((_, i) => (
                <div key={i} className="flex gap-2 items-center mb-6">
                  <Skeleton variant="circular" width={40} height={40} />
                  <Skeleton
                    variant="text"
                    width={160}
                    sx={{ fontSize: "1rem" }}
                  />
                </div>
              ))}
            </div>
          ))}
        </Box>
      )}
      {categories?.list && canScrollRight && (
        <IconButton onClick={() => scroll(300)} disabled={!canScrollRight}>
          <RightArrow />
        </IconButton>
      )}
    </Box>
  );
};
