import { Modal } from "@mui/material";
import { Save } from "Components/Buttons/Save";
import { removeMember } from "Reducers/FamilyMembers";
import { API_REQUEST } from "Request/API";
import { useAppDispatch, useAppSelector } from "Store/store";
import { memo, useRef } from "react";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";

export const DeleteModal = memo(function DeleteModal({
  open,
  close,
  text,
}: {
  open: boolean;
  close?: () => void;
  text: string;
}) {
  const containerRef = useRef(null);
  const dispatch = useAppDispatch();
  const member = useAppSelector((state) => state.familymembers);
  return (
    <>
      <div className="absolute" ref={containerRef}></div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        container={containerRef.current}
        className="flex items-center justify-center outline-0"
      >
        <div className="w-[398px] py-[20px] px-[30px] outline-0 flex flex-col items-center gap-6 rounded-[12px] bg-[#fff]">
          <div className="flex items-center justify-center rounded-[50%] w-[60px] h-[60px] bg-raspberry-10">
            <ReactSVG src="/images/icons/trash.svg" />
          </div>
          <h2 className="text-center text-[14px] font-demi text-blackPrimary-60">
            {text}
          </h2>
          <div className="flex gap-4">
            <Save
              onClick={() => {
                close && close();
              }}
              variant="gray"
              label="არა,არ მსურს"
            />
            <Save
              onClick={() => {
                if (member?.memberId?.isAproved === true) {
                  API_REQUEST(
                    "DELETE",
                    `user/group/remove/${member?.memberId?.id}`,
                    undefined,
                    undefined,
                    "fullresponse"
                  )
                    .then((e) => {
                      close && close();
                      dispatch(removeMember(member?.memberId?.id));
                      toast("იუზერი წაიშალა", {
                        type: "success",
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        draggable: true,
                        theme: "success",
                      });
                    })
                    .catch((e) =>
                      toast("მოხდა შეცდომა", {
                        type: "error",
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        draggable: true,
                        theme: "light",
                      })
                    );
                } else {
                  close && close();
                  dispatch(removeMember(member?.memberId?.id));
                }
              }}
              variant="green"
              label="დიახ, მსურს"
            />
          </div>
        </div>
      </Modal>
    </>
  );
});
