import { Modal } from "@mui/material";
import { Filter } from "Components/PageSections/Clinics/Filter/Filter";
import { useCustomQuery } from "Hooks/useQuery";
import { memo, useRef } from "react";
import { ReactSVG } from "react-svg";
import { BasicModalTypes } from "types/MembersModal";

export const FilterModal = memo(function FilterModal({
  open,
  close,
  filter,
  handleSortCallback,
}: BasicModalTypes & {
  filter: any;
  handleSortCallback?: any;
}) {
  const containerRef = useRef(null);
  const query = useCustomQuery();

  return (
    <>
      <div ref={containerRef}></div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        container={containerRef.current}
        className="h-[100dvh] z-100 fixed w-full overflow-y-auto bg-[#fff] top-0 left-0"
      >
        <div className="h-[100dvh] z-40 w-full overflow-y-auto bg-[#fff]">
          <div className="flex justify-between items-center p-4 bg-[#fff]">
            <h2 className="font-bold text-md text-blackPrimary-100">ფილტრი</h2>
            <div
              onClick={close}
              className="cursor-pointer bg-gray-100 rounded-[8px] w-[36px] h-[36px] flex items-center justify-center"
            >
              <ReactSVG src="/images/icons/close.svg" />
            </div>
          </div>
          <div className="px-5 flex flex-col gap-3">
            <div
              onClick={() => handleSortCallback("discount:desc")}
              className={"cursor-pointer flex justify-between items-center"}
            >
              <div className="flex items-center gap-3">
                <span className="text-[14px] font-medium text-blackPrimary-100">
                  მაღალი პროცენტი
                </span>
              </div>
              {query.get("sort") == "discount:desc" ? (
                <button className="w-[20px] h-[20px] bg-[#fff] border-[6px] border-solid border-green-100 rounded-[50%]" />
              ) : (
                <button className="w-[20px] h-[20px] bg-gray-70 rounded-[50%]" />
              )}
            </div>
            <div
              onClick={() => handleSortCallback("discount:asc")}
              className={"cursor-pointer flex justify-between items-center"}
            >
              <div className="flex items-center gap-3">
                <span className="text-[14px] font-medium text-blackPrimary-100">
                  დაბალი პროცენტი
                </span>
              </div>
              {query.get("sort") == "discount:asc" ? (
                <button className="w-[20px] h-[20px] bg-[#fff] border-[6px] border-solid border-green-100 rounded-[50%]" />
              ) : (
                <button className="w-[20px] h-[20px] bg-gray-70 rounded-[50%]" />
              )}
            </div>
          </div>
          <div>{filter}</div>
        </div>
      </Modal>
    </>
  );
});
