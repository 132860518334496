import { memo } from "react";
import { ReactSVG } from "react-svg";

export const DownButton = memo(function DownbuttonComponent() {
  return (
    <div className="cursor-pointer p-[8.32px] border-[5px] border-[#FFFFFF] flex items-center justify-center rounded-[50%] w-[133px] h-[133px] bg-yellow">
      <img src="/images/icons/vectortext.svg" alt="vector" loading="lazy" />
      <ReactSVG className="absolute" src="/images/icons/down.svg" />
    </div>
  );
});
