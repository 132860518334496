import { memo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ThemeProvider,
} from "@mui/material";
import { tableTheme } from "./TableTheme";
import useWindowSize from "Hooks/useWindowSize";

function createData(date: string, price: number, status: string, type: string) {
  return { date, price, status, type };
}

const rows = [
  createData("22.05.2024", 30, "წარმატებული", "ბარათით"),
  createData("22.04.2024", 30, "წარმატებული", "ბარათით"),
  createData("22.04.2024", 30, "წარმატებული", "ბალანსით"),
  createData("22.04.2024", 30, "წარმატებული", "ბარათით"),
  createData("22.04.2024", 30, "წარმატებული", "ბალანსით"),
];

export const TransactionsTable = memo(function TransactionsTableComponent() {
  const { width } = useWindowSize();
  return (
    <ThemeProvider theme={tableTheme}>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: { sm: 350, md: 650 } }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                {width > 832 ? "გადახდის თარიღი" : "თარიღი"}
              </TableCell>
              {width <= 832 && <TableCell align="left"></TableCell>}
              <TableCell align="left">
                {width > 832 ? "გადახდილი თანხა" : "თანხა"}
              </TableCell>
              {width > 832 && (
                <>
                  <TableCell align="left">სტატუსი</TableCell>
                  <TableCell align="left">გადახდის ტიპი</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {rows.map((row, i) => (
              <TableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.date}
                </TableCell>
                {width > 832 ? (
                  <>
                    <TableCell align="left">{row.price}</TableCell>
                    <TableCell align="left">
                      <span className="bg-green-10 px-2 font-demi py-2 text-green-100 rounded-[5px]">
                        {row.status}
                      </span>
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell align="left">
                      <span className="bg-green-10 px-2 font-demi py-2 text-green-100 rounded-[5px]">
                        {row.status}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      {row.type} <br />
                      {row.price} 
                    </TableCell>
                  </>
                )}

                {width > 832 && <TableCell align="left">{row.type}</TableCell>}
              </TableRow>
            ))} */}
          </TableBody>
        </Table>
        <div className="w-full h-full p-[50px] flex items-center justify-center text-blackPrimary-30 font-demi text-[24px]">
          ინფორმაცია არ მოიძებნა
        </div>
      </TableContainer>
    </ThemeProvider>
  );
});
