import { memo } from "react";
import { TextField, ThemeProvider } from "@mui/material";
import { theme } from "./TextFieldTheme";

export const TextFieldInput = memo(function TextFieldComponent({
  value,
  label,
  className = "bg-[#fff!important]",
  disabled = false,
  error,
  helperText,
  onChange,
  onBlur,
  name,
  ref,
  ...props
}: {
  value?: string | number;
  label?: string;
  className?: string;
  disabled?: boolean;
  error?: boolean;
  helperText?: any;
  props?: any;
  name?: string;
  ref?: any;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <ThemeProvider theme={theme}>
      <TextField
        name={name}
        label={label}
        variant="filled"
        value={value}
        className={`${className}`}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        ref={ref}
        {...props}
      />
    </ThemeProvider>
  );
});
