import { Box, Menu, MenuItem, ThemeProvider, createTheme } from "@mui/material";
import { RootState, useAppSelector } from "Store/store";
import { memo } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { anchor } from "types/types";

const menuList = [
  {
    id: 0,
    title: "სამედიცინო დაწესებულებები",
    icon: "/images/icons/menu/aftiakebi.svg",
  },
  {
    id: 1,
    title: "მენტალური ჯანმრთელობა",
    icon: "/images/icons/menu/mentaluri.svg",
  },
  {
    id: 2,
    title: "სასწრაფო დახმარება",
    icon: "/images/icons/menu/samedicino.svg",
  },
  {
    id: 3,
    title: "სტომატოლოგია",
    icon: "/images/icons/menu/sascrafo.svg",
  },
  {
    id: 4,
    title: "სილამაზე და ესთეტიკა",
    icon: "/images/icons/menu/silamaze.svg",
  },
  {
    id: 5,
    title: "აფთიაქები",
    icon: "/images/icons/menu/stomatologia.svg",
  },
];

export const theme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: 12,
          boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08) #FF0000",
          className: "bg-[red]",
          marginTop: "12px",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: "10px 18px",
          display: "flex",
          gap: "8px",
          fontSize: "14px",
          fontFamily: "avenir-medium",
          color: "#070A14",
        },
      },
    },
  },
});

export const DropdownMenu = memo(function DropdownMenu({
  anchorEl,
  handleClose,
  open,
}: {
  anchorEl: anchor;
  handleClose: () => void;
  open: boolean;
}) {
  const categories = useAppSelector((state: RootState) => state.categories);

  return (
    <ThemeProvider theme={theme}>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {categories?.list?.map((category: any, index: number) => (
          <Link key={index} to={`clinics/${category.slug}`}>
            <MenuItem onClick={handleClose}>
              <Box
                sx={{
                  display: "flex",
                  width: "30px",
                  height: "30px",
                  background: "#3A6CE81A",
                  borderRadius: "50%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ReactSVG src={category.iconUrl} />
              </Box>
              {category.title}
            </MenuItem>
          </Link>
        ))}
        <Link to="/clinics">
          <Box
            onClick={handleClose}
            component="span"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              margin: "5px 27px 0",
              padding: "18px 0",
              cursor: "pointer",
              borderTop: "1px solid #E8ECF4",
              color: "#3A6CE8",
              fontFamily: "avenir-demi",
              fontSize: "14px",
            }}
          >
            იხილე ყველა <ReactSVG src="/images/icons/rotatedarrowdark.svg" />
          </Box>
        </Link>
      </Menu>
    </ThemeProvider>
  );
});
