import { useQuery } from "@tanstack/react-query";
import { API_REQUEST } from "Request/API";

export const useCardPriceQuery = (enabled = false as boolean, countOfNewMember: number) => {
  const {
    data: cardPrice,
    isError: isErrorPrice,
    isLoading: isLoadingPrice,
    isSuccess: isSuccessPrice,
  } = useQuery({
    queryKey: [`cardprice-${countOfNewMember}`, [countOfNewMember]],
    queryFn: () =>
      API_REQUEST("GET", `medical/calculate-price`, null, {
        countOfNewMember: countOfNewMember,
      }),
    enabled: countOfNewMember !== undefined,
    refetchOnWindowFocus: false,
    retry: 0,
  });

  return { cardPrice, isErrorPrice, isLoadingPrice, isSuccessPrice };
};
