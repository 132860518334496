import { Modal } from "@mui/material";
import { CancelSubscribe } from "Components/Buttons/CancelSubscribe";
import { BlockListItem } from "Components/VitaCardBlock/VitaCardBlock";
import { useUserBankCardsQuery } from "Hooks/Queries/User/useUserBankCardsQuery";
import { MobileModal } from "Layouts/User/MobileModal";
import dayjs from "dayjs";
import { memo, useRef } from "react";
import { ReactSVG } from "react-svg";
import { BasicModalTypes } from "types/MembersModal";

export const DetailsModal = memo(function DetailsModalComponent({
  open,
  cardInfo,
  close,
  onCardChange,
  onSubCancel,
}: BasicModalTypes & {
  onCardChange?: any;
  cardInfo?: any;
  onSubCancel?: any;
}) {
  const { bankCard } = useUserBankCardsQuery();
  const containerRef = useRef(null);
  const formattedDate = dayjs(cardInfo?.cardEndDate).format("DD.MM.YYYY");
  const formattedDateStart = dayjs(cardInfo?.cardStartDate).format(
    "DD.MM.YYYY"
  );
  // Calculate the remaining days from today
  const remainingDays = dayjs(cardInfo?.cardEndDate).diff(dayjs(), "day");

  return (
    <>
      <div ref={containerRef}></div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        container={containerRef.current}
        className="flex items-center"
      >
        <MobileModal title="ბარათის დეტალები">
          <div className="pb-[30px] flex flex-col gap-[24px]">
            {cardInfo?.cardType === "INDIVIDUAL" && (
              <>
                <div className="flex justify-between items-center">
                  <BlockListItem title={"შემდეგი გადახდა"} />
                  <span className="text-rg text-blackPrimary-100 font-demi flex flex-col items-end">
                    {`${formattedDate}`}
                    <span className="text-blackPrimary-60">
                      (დარჩენილია {remainingDays} დღე)
                    </span>
                  </span>
                </div>
                <div className="flex justify-between items-center">
                  <BlockListItem title={"გადასახდელი თანხა"} />
                  <span className="cursor-pointer flex items-center gap-2 text-rg text-blackPrimary-100 font-demi">
                    {cardInfo?.nextPayAmount?.toFixed(2)} ლარი
                    <ReactSVG wrapper="span" src="/images/icons/info.svg" />
                  </span>
                </div>
                <div className="flex justify-between items-center">
                  <div className="flex items-center gap-3">
                    <BlockListItem title={"ბარათი"} />
                    {bankCard?.subscriptionCard ? (
                      <>
                        <span className="border-[1px] rounded-[6px] border-gray-100 border-solid flex items-center justify-center px-[5px] py-[4px] bg-[#fff]">
                          <img
                            className="max-w-[24px]"
                            src={
                              bankCard?.subscriptionCard.pan.substring(0, 1) ==
                              "5"
                                ? "/images/icons/mastercard.svg"
                                : "/images/icons/visacard.svg"
                            }
                          />
                        </span>
                        <span className="text-sm font-demi">
                          **
                          {bankCard?.subscriptionCard?.pan?.slice(
                            bankCard?.subscriptionCard?.pan?.length - 4,
                            bankCard?.subscriptionCard?.pan?.length
                          )}
                        </span>
                      </>
                    ) : (
                      <span className="text-sm font-demi">
                        ბარათი არ მოიძებნა
                      </span>
                    )}
                  </div>
                  <span
                    onClick={() => {
                      close();
                      onCardChange();
                    }}
                    className="cursor-pointer text-rg leading-[21px] text-raspberry-100 underline font-demi"
                  >
                    {cardInfo?.cardType === "INDIVIDUAL" &&
                    cardInfo?.hasSubscription === true
                      ? "შეცვლა"
                      : "გამოწერის ჩართვა"}
                  </span>
                </div>
              </>
            )}
            {cardInfo?.cardType === "CORPORATE" && (
              <>
                <div className="flex justify-between">
                  <BlockListItem title={"ბარათი გააქტიურა"} />
                  <span className="text-rg text-blackPrimary-100 font-demi">
                    {cardInfo?.medicalCorporateClientName}
                  </span>
                </div>
                <div className="flex justify-between">
                  <BlockListItem title={"ბარათი გააქტიურდა"} />
                  <span className="text-rg text-blackPrimary-100 font-demi">
                    {`${formattedDateStart}`}
                  </span>
                </div>
                <div className="flex justify-between">
                  <BlockListItem title={"აქტიურობის ვადა იწურება"} />
                  <span className="text-rg text-blackPrimary-100 font-demi">
                    {`${formattedDate}`}
                  </span>
                </div>
              </>
            )}
          </div>
          {cardInfo?.cardType === "INDIVIDUAL" &&
            cardInfo?.hasSubscription === true && (
              <div className="flex justify-end mb-[14px]">
                <CancelSubscribe onClick={onSubCancel} />
              </div>
            )}
        </MobileModal>
      </Modal>
    </>
  );
});
