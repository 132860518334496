import { AddMemberButton } from "Components/Buttons/AddMemberButton";
import { MemberCard } from "Components/Cards/MemberCard";
import { useHasUserCard } from "Hooks/Queries/User/useHasUserCard";
import { useAppSelector } from "Store/store";
import { memo } from "react";
import { Link } from "react-router-dom";

export const FamilyMembers = memo(function ({
  handleCreateMember,
  handleRemoveMember,
  className = "",
  type = "default",
}: {
  handleRemoveMember?: () => void;
  handleCreateMember: (state: boolean) => void;
  className?: string;
  type?: string;
}) {
  const state = useAppSelector((state) => state.familymembers);
  const { userHasCard, isLoadingUserHasCard } = useHasUserCard(true); // always authorized if user reachs this page
  const isActive = userHasCard;

  return (
    <div
      className={`${className} bg-[#fff] rounded-[12px] p-[44px] grid grid-cols-4 md:grid-cols-2 customxlsize:grid-cols-3 gap-4`}
    >
      {type == "default" ? (
        <AddMemberButton handleClick={handleCreateMember} />
      ) : (
        <Link to={isActive ? "/createmember" : "/checkout"}>
          <AddMemberButton />
        </Link>
      )}
      {state?.members?.map((member: any, i: number) => (
        <MemberCard
          member={member}
          handleRemoveMember={handleRemoveMember}
          key={member.id}
        />
      ))}
      {!isActive && type !== "default" && (
        <div className="col-span-3 mt-auto mb-auto text-blackPrimary-60 font-medium text-md">
          ოჯახის წევრის დამატება ფასიანია. ერთი ადამიანის დამატების ღირებულება
          შეადგენს <span className="text-raspberry-100 font-bold">15 ლარს</span>
        </div>
      )}
    </div>
  );
});
