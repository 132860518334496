import { memo } from "react";
import { ReactSVG } from "react-svg";

export const VitaPercent = memo(function VitaPercentComponent({
  percent,
  icon = true,
  className = "",
}: {
  percent: string | number;
  className?: string;
  icon?: boolean;
}) {
  return (
    <div
      className={`${className} bg-raspberry-10 flex items-center py-2 px-3 gap-2 rounded-[8px] font-bold text-raspberry-100 text-rg`}
    >
      {icon && <ReactSVG src="/images/icons/vitaicon.svg" />}{" "}
      <span className="text-nowrap">-{percent} %</span>
    </div>
  );
});
