import { useQuery } from "@tanstack/react-query";
import { API_REQUEST } from "Request/API";

export const useClinicsSearchQuery = (clinicProps: any) => {
  const {
    data: clinicResults,
    isError: isErrorClinicResults,
    isLoading: isLoadingClinicResults,
    isSuccess: isSuccessClinicResults,
    isFetched,
    isStale,
    isFetchedAfterMount,
  } = useQuery({
    queryKey: [
      `clinics-search-${clinicProps.key}`,
      [clinicProps.page, clinicProps.size, clinicProps.key],
    ],
    queryFn: () => {
      return API_REQUEST("GET", `clinic/clinics`, null, {
        page: 0,
        size: 100,
        searchKay: clinicProps.key,
      });
    },
    retry: 0,
    enabled: clinicProps?.key?.length !== 0,
    refetchOnWindowFocus: false,
    staleTime: 0,
  });

  return {
    clinicResults,
    isErrorClinicResults,
    isLoadingClinicResults,
    isSuccessClinicResults,
    isFetched,
    isStale,
    isFetchedAfterMount,
  };
};
