import { memo, useRef } from "react";
import { Button, Modal } from "@mui/material";
import { Save } from "Components/Buttons/Save";
import { ClinicMessage } from "Components/Messages/Clinic/ClinicMessage";
import { ReactSVG } from "react-svg";
import useWindowSize from "Hooks/useWindowSize";
import { useAppDispatch, useAppSelector } from "Store/store";
import { clearServices, removeServices } from "Reducers/ServicesSlice";
import { modifyObject } from "Helpers/modifyServerObject";
import { RootState } from "@reduxjs/toolkit/query";
import { Link } from "react-router-dom";

export const Calculator = memo(function CalculatorComponent({
  open,
  close,
}: {
  open: boolean;
  close: () => void;
}) {
  const { width } = useWindowSize();
  const containerRef = useRef(null);
  const state = useAppSelector((state) => state.services);
  const dispatch = useAppDispatch();

  const removeBtn = (item: any) => (
    <div
      onClick={() =>
        dispatch(
          removeServices(
            modifyObject(item, item.clinic.id || 0, item.clinic, true)
          )
        )
      }
      className="w-[40px] ml-auto flex justify-end items-center"
    >
      <ReactSVG className="cursor-pointer" src="/images/icons/blacktarsh.svg" />
    </div>
  );

  return (
    <>
      <div ref={containerRef}></div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        container={containerRef.current}
        className="flex items-center justify-center"
      >
        <div className="w-[890px] sm:w-[100dvh] sm:max-w-full sm:max-h-full sm:rounded-[0px] sm:h-[100dvh] overflow-hidden flex flex-col p-[32px] sm:p-[12px] relative max-w-[90%] max-h-[90%] h-[690px] bg-[#fff] rounded-[12px] outline-none">
          <div className="mb-[24px] flex justify-between items-center">
            <h2 className="font-bold text-[20px] text-blackPrimary-100">
              დათვალე
            </h2>
            <div
              onClick={close}
              className="cursor-pointer w-[44px] h-[44px] bg-gray-30 sm:bg-gray-100 sm:rounded-[8px] rounded-[50%] flex items-center justify-center"
            >
              <ReactSVG src="/images/icons/close.svg" />
            </div>
          </div>
          {state?.services?.length == 0 && (
            <div className="font-demi mt-auto flex flex-col max-w-[600px] w-[80%] m-auto justify-center text-md relative overflow-hidden h-[233px]">
              <img
                className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
                src="/images/VITA.png"
              />
              <div className="relative z-10 flex flex-col items-center gap-6 text-center font-demi text-md">
                გადადი კონკრეტული კლინიკის გვერდზე, მონიშნე შენთვის სასურველი
                სამედიცინო თუ ესთეტიკური მომსახურებები და წინასწარ ნახე, რა
                თანხას დაზოგავ ჯანდაცვის ბარათით.
                <Link to="/clinics">
                  <Save
                    onClick={close}
                    label="დაამატე სერვისი"
                    variant="green"
                    className="max-w-full m-auto"
                  />
                </Link>
              </div>
            </div>
          )}
          {state?.services?.length > 0 && (
            <div className="h-[calc(100%-54px-80px)] overflow-y-auto">
              <div className="font-demi text-md text-blackPrimary-100 mb-[30px]">
                ჩამონათვალის ბოლოს ნახე რა თანხის გადახდა გიწევს კლინიკაში და რა
                თანხას დაზოგავ შერჩეულ სერვისებზე ჯამურად VITA ბარათით
              </div>
              <ClinicMessage />
              <div className="mt-[30px]">
                <div className="flex justify-between items-center">
                  <h2 className="font-demi text-md text-blackPrimary-100">
                    მომსახურებები
                  </h2>
                  <Save
                    onClick={() => dispatch(clearServices())}
                    variant="gray"
                    label="გასუფთავება"
                  />
                </div>
                <div>
                  {state?.services?.map((item: any, index) => (
                    <div
                      className="flex gap-4 py-[16px] items-center border-b-[1px] border-solid border-b-[#E8ECF466]"
                      key={index}
                    >
                      <div className="w-[80px] min-w-[80px] bg-gray-30 rounded-[8px] h-[80px]">
                        <img src={item.clinic?.image} />
                      </div>
                      <div className="sm:w-full">
                        <h2 className="font-demi w-full text-rg text-blackPrimary-100 flex items-center justify-between">
                          {item?.clinic?.title}
                          {width <= 640 && removeBtn(item)}
                        </h2>
                        <span className="font-regular text-rg text-blackPrimary-60 sm:line-clamp-2">
                          {item?.title}
                        </span>
                        <div className="w-full flex">
                          <span className="text-raspberry-100 font-demi text-md mr-[12px] flex gap-1 items-center">
                            {item?.offerAmount}{" "}
                            <ReactSVG src="/images/icons/larired.svg" />
                          </span>
                          <span className="line-through text-blackPrimary-60 font-demi text-md">
                            {item?.amount}
                          </span>
                        </div>
                      </div>
                      {width > 640 && removeBtn(item)}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {state?.services?.length > 0 && (
            <div className="absolute bottom-0 left-0 w-full h-[80px] bg-gray-100 flex justify-end items-center pr-[40px] gap-8">
              <div>
                <h2 className="text-blackPrimary-60 font-demi text-md">
                  დაზოგილი თანხა
                </h2>
                <span className="text-blackPrimary-30 font-demi text-md">
                  {state?.savings}
                </span>
              </div>
              <div>
                <h2 className="text-blackPrimary-60 font-demi text-md">
                  გადასახდელი
                </h2>
                <div className="flex gap-3">
                  <span className="text-raspberry-100 font-demi text-md flex items-center gap-1">
                    {state.amount} <ReactSVG src="/images/icons/larired.svg" />
                  </span>
                  <span className="line-through text-blackPrimary-60 font-demi text-md">
                    {state?.amountWithoutOffers}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
});
