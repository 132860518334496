import { BasicBreadcrumbs } from "Components/Breadcrumbs/Breadcrumbs";
import { Medicaments } from "Components/Modals/Medicaments";
import { OfferBackButton } from "Components/Offers/Layouts/OfferBackButton";
import { OfferHeader } from "Components/Offers/Layouts/OfferHeader";
import { OfferType2 } from "Components/Offers/OfferType2";
import { CustomPagination } from "Components/Pagination/CustomPagination";
import { chunkArray } from "Helpers/Chunks";
import useWindowSize from "Hooks/useWindowSize";
import { useState } from "react";

export const Type2 = ({ offersSlugList }: any) => {
  const { width } = useWindowSize();
  const chunkedOffers = chunkArray(offersSlugList?.responseOfferItemDto, 12);
  const [page, setPage] = useState(0);
  const [isOpenMedicaments, setIsOpenMedicaments] = useState(false);
  const [medicamentsId, setMedicamentsId] = useState(null);
  const [offerItemName, setItemOffer] = useState("");

  return (
    <div className="relative">
      {width > 640 && <OfferBackButton />}
      {width <= 640 && (
        <div className="px-[16px] pt-4">
          <BasicBreadcrumbs
            breadcrumbs={[
              {
                title: offersSlugList?.offerName,
                link: "/",
              },
            ]}
          />
        </div>
      )}
      <Medicaments
        open={isOpenMedicaments}
        close={() => {
          setIsOpenMedicaments(false);
          setMedicamentsId(null);
          setItemOffer("");
        }}
        medicamentsId={medicamentsId}
        offerItemName={offerItemName}
      />
      <div className="container pt-[16px] pb-[48px] md:py-6 relative z-10">
        <OfferHeader
          title={offersSlugList?.offerName}
          description={offersSlugList?.offerDescription}
        />
        <div className="grid grid-cols-4 xl:gap-[24px] md:gap-[16px] lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-[32px] my-[44px] md:my-[24px]">
          {chunkedOffers[page]?.map((item: any, index: number) => (
            <OfferType2
              key={index}
              imgUrl={item.imgUrl}
              title={item.title}
              discount={item.discount}
              onClick={() => {
                setIsOpenMedicaments(true);
                setMedicamentsId(item.id);
                setItemOffer(item?.title);
              }}
            />
          ))}
        </div>
        <div className="flex justify-center">
          <CustomPagination
            moveToPage={(e: any) => setPage(e - 1)}
            totalPages={chunkedOffers?.length}
            page={page + 1}
          />
        </div>
      </div>
    </div>
  );
};
