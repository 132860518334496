import { useEffect } from "react";
import { useNavigate } from "react-router-dom"; // for react-router-dom v6
import useWindowSize from "Hooks/useWindowSize";
import { UserBlock } from "Components/PageSections/User/UserBlock/UserBlock";

export const MobileMenu = () => {
  const { width } = useWindowSize();
  const navigate = useNavigate();

  useEffect(() => {
    if (width > 832) {
      navigate("/");
    }
  }, [width, navigate]);

  return (
    <div className="h-[calc(100dvh-157px)]">
      <UserBlock />
    </div>
  );
};
