import { Skeleton } from "@mui/material";
import { Location } from "Components/Location/Location";
import { Link } from "react-router-dom";

export const SaerchContent = ({
  isLoading,
  isLoadingClinicResults,
  isSuccessClinicResults,
  clinicResults,
  close,
  keyWord,
}: any) => {
  const resultsBody = () => {
    return (
      <div className="py-[30px] px-[20px]">
        <div className="flex justify-between items-center">
          <h2 className="font-bold text-rg ">კლინიკები</h2>
          {isLoading ? (
            <Skeleton width={80} />
          ) : (
            <Link onClick={close} to={`searchresults?key=${keyWord}`}>
              <button className="text-rg font-demi text-raspberry-100">
                მაჩვენე ყველა
              </button>
            </Link>
          )}
        </div>
        <div className="py-[32px] pt-[20px] pb-[2px]">
          {isLoading
            ? Array.from({ length: 5 }).map((_, i) => (
                <div className="flex gap-2 py-[12px] border-b-[1px] border-solid border-b-[#E8ECF466]">
                  <Skeleton
                    width={52}
                    height={52}
                    className="min-h-[52px] scale-100 h-[52px] p-0 m-0"
                  />
                  <div className="flex flex-col gap-1">
                    <h2 className="font-demi text-rg text-blackPrimary-100">
                      <Skeleton width={100} />
                    </h2>
                    <Skeleton width={150} />
                  </div>
                </div>
              ))
            : clinicResults?.content?.map((item: any, index: number) => (
                <Link key={index} to={`detailed/${item.id}`}>
                  <div
                    onClick={close}
                    className="flex gap-2 items-center py-[12px] border-b-[1px] border-solid border-b-[#E8ECF466]"
                  >
                    <div className="w-[52px] min-w-[52px] min-h-[52px] h-[52px] flex items-center justify-center bg-gray-30 rounded-[12px]">
                      <img src={item.imgUrl} alt="clinic image" />
                    </div>
                    <div className="flex flex-col gap-1">
                      <h2 className="font-demi text-rg text-blackPrimary-100">
                        {item.name}
                      </h2>
                      <Location address={item.address} />
                    </div>
                  </div>
                </Link>
              ))}
        </div>
      </div>
    );
  };

  if (isLoading || isLoadingClinicResults) {
    return resultsBody();
  }

  if (isSuccessClinicResults && clinicResults?.content?.length !== 0) {
    return resultsBody();
  }

  return (
    <div className="realtive p-[20px] h-[200px] flex items-center justify-center">
      <img
        className="absolute p-8 left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
        src="/images/nonbranches.png"
      />
      <h2 className="font-bold text-md text-blackPrimary-100">
        კლინიკა არ მოიძებნა
      </h2>
    </div>
  );
};

export const SearchBox = ({
  clinicResults,
  bodyRef,
  close,
  isLoading,
  isSuccessClinicResults,
  isLoadingClinicResults,
  keyWord,
}: any) => {
  return (
    <div
      ref={bodyRef}
      className="languagesShadow rounded-[16px] absolute left-0 overflow-hidden bg-[#fff] w-full search-modal top-[60px] max-h-[374px] overflow-y-auto z-40"
    >
      <SaerchContent
        isLoading={isLoading}
        isLoadingClinicResults={isLoadingClinicResults}
        isSuccessClinicResults={isSuccessClinicResults}
        clinicResults={clinicResults}
        close={close}
        keyWord={keyWord}
      />
    </div>
  );
};
