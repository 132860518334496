import { memo, useRef } from "react";
import { Modal } from "@mui/material";
import { MobileModal } from "Layouts/User/MobileModal";
import { TransactionsTable } from "Components/Tables/TransactionsTable";
import { BasicModalTypes } from "types/MembersModal";

export const TransactionsModal = memo(function TransactionsModalComponent({
  open,
  close,
}: BasicModalTypes) {
  const containerRef = useRef(null);
  return (
    <>
      <div ref={containerRef}></div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        container={containerRef.current}
        className="flex items-center"
      >
        <MobileModal title="ტრანზაქციები">
          <TransactionsTable />
        </MobileModal>
      </Modal>
    </>
  );
});
