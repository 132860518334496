import { BasicBreadcrumbs } from "Components/Breadcrumbs/Breadcrumbs";
import { OfferBackButton } from "Components/Offers/Layouts/OfferBackButton";
import { OfferHeader } from "Components/Offers/Layouts/OfferHeader";
import { OfferType1 } from "Components/Offers/OfferType1";
import { CustomPagination } from "Components/Pagination/CustomPagination";
import { chunkArray } from "Helpers/Chunks";
import useWindowSize from "Hooks/useWindowSize";
import { useState } from "react";
import { Link } from "react-router-dom";

export const Type1 = ({ offersSlugList }: any) => {
  const { width } = useWindowSize();
  const [page, setPage] = useState(0);
  const chunkedOffers = chunkArray(offersSlugList?.responseOfferItemDto, 12);

  return (
    <div className="bg-gray-30 min-h-[calc(100dvh-120px)] relative">
      <img
        className="absolute md:hidden z-[1] top-[90px] left-0"
        src="/images/offerscomponent.png"
      />
      {width > 640 && <OfferBackButton />}
      {width <= 640 && (
        <div className="px-[16px] pt-4">
          <BasicBreadcrumbs
            breadcrumbs={[
              {
                title: offersSlugList?.offerName,
                link: "/",
              },
            ]}
          />
        </div>
      )}
      <div className="container py-[48px] md:py-6 relative z-10">
        <OfferHeader
          title={offersSlugList?.offerName}
          description={offersSlugList?.offerDescription}
        />
        <div className="grid grid-cols-4 xl:gap-[24px] md:gap-[16px] lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-[32px] my-[44px] md:my-[24px]">
          {chunkedOffers[page]?.map((item: any, index: number) => (
            <Link
              key={index}
              to={item?.clinicId ? `/detailed/${item?.clinicId}` : "#"}
            >
              <OfferType1
                imgUrl={item.imgUrl}
                description={item.description}
                title={item.title}
                discount={item.discount}
              />
            </Link>
          ))}
        </div>
        <div className="flex justify-center">
          <CustomPagination
            moveToPage={(e: any) => setPage(e - 1)}
            totalPages={chunkedOffers?.length}
            page={page + 1}
          />
        </div>
      </div>
    </div>
  );
};
