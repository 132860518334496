import { memo } from "react";

export const AboutVita = memo(function AboutVitaComponent() {
  return (
    <div className=" bg-[#fff] md:mb-[20px] p-[32px] sm:p-[16px] rounded-[12px] relative before:content-[''] before:bg-aboutVitaLine sm:before:bg-aboutvitaarrow sm:before:left-0 sm:before:right-[inherit] sm:before:top-0 before:absolute before:top-0 before:bg-right before:right-0 before:w-full before:h-full before:bg-no-repeat">
      <div className="hidden relative z-10 sm:flex items-center justify-between sm:mb-[12px]">
        <h2 className="text-md text-blackPrimary-100 font-bold">ვიტა ბარათი</h2>
        <span className="text-raspberry-100 text-sm font-bold">არააქტიური</span>
      </div>
      <div className="flex sm:flex-col sm:mb-[20px] sm:gap-[10px] items-center gap-[54px]">
        <div className="min-w-[236px] sm:w-full xl:min-w-[136px] relative">
          <img
            src="/images/vitacard.png"
            className="sm:w-full sm:object-cover"
            alt="vitacardimage"
          />
        </div>
        <div className="w-full relative">
          <div className="flex justify-between items-center mb-4 xl:mb-2">
            <h2 className="sm:hidden text-[20px] xl:text-md md:text-md font-bold leading-[29px] text-blackPrimary-100">
              ვიტა ბარათი
            </h2>
          </div>
          <div className="text-md xl:text-rg font-medium text-blackPrimary-100 sm:text-blackPrimary-30">
            მულტიფუნქციური ბარათი შენთვის და შენი ოჯახისთვის
          </div>
        </div>
      </div>
    </div>
  );
});
