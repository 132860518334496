import { Modal } from "@mui/material";
import { Save } from "Components/Buttons/Save";
import { Adult } from "Components/Sections/Adult";
import { Children } from "Components/Sections/Children";
import { SwitchMemberType } from "Components/Switch/SwitchMemberType";
import { memo, useCallback, useRef, useState } from "react";
import { ReactSVG } from "react-svg";

export const CreateMember = memo(function CreateMemberComponent({
  open,
  close,
}: {
  open: boolean;
  close: () => void;
}) {
  const containerRef = useRef(null);
  const [isAdult, setIsAdult] = useState(true);

  const handleIsAdult = useCallback((state: boolean) => {
    setIsAdult(state);
  }, []);

  return (
    <>
      <div ref={containerRef}></div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        container={containerRef.current}
        className="flex items-center"
      >
        <div className="flex flex-col w-[492px] sm:w-full sm:h-[100%] sm:max-h-[100%] sm:rounded-none max-h-[610px] h-[95%] overflow-y-auto bg-[#fff] m-auto rounded-[12px] p-[32px] sm:p-4">
          <div className="flex justify-between items-center py-[6px] mb-[15px]">
            <h2 className="font-bold text-[20px] text-blackPrimary-100">
              ოჯახის წევრის დამატება
            </h2>
            <div
              onClick={close}
              className="cursor-pointer w-[44px] h-[44px] bg-gray-30 sm:bg-gray-100 sm:rounded-[8px] rounded-[50%] flex items-center justify-center"
            >
              <ReactSVG src="/images/icons/close.svg" />
            </div>
          </div>
          <div>
            <SwitchMemberType
              state={isAdult}
              setState={(state: boolean) => handleIsAdult(state)}
            />
          </div>

          <div className="mb-[20px]">
            {isAdult ? <Adult close={close} /> : <Children close={close} />}
          </div>
        </div>
      </Modal>
    </>
  );
});
