import { memo } from "react";
import { RemoveServiceMobileBtn } from "Components/Buttons/RemoveServiceMobile";
import { serviceChecker } from "Helpers/serviceChecker";
import { useAppSelector } from "Store/store";

export const ServiceMobileListItem = memo(
  function ServiceMobileListItemComponent({
    service,
    clinic,
  }: {
    service: any;
    clinic: any;
  }) {
    const state = useAppSelector((state) => state?.services);
    const check = serviceChecker(state?.services, service);

    return (
      <div className="bg-[#fff] rounded-[12px] p-[16px]">
        <ul className="flex flex-col gap-5 mb-[20px]">
          <li className="flex flex-col">
            <span className="text-rg text-blackPrimary-60 font-regular">
              მომსახურება
            </span>
            <span className="text-rg font-demi text-blackPrimary-100">
              {service.title}
            </span>
          </li>
          <div className="flex gap-2 justify-between">
            <li className="flex flex-col">
              <span className="text-rg sm:text-sm text-blackPrimary-60 font-regular">
                ფასდაკლება
              </span>
              <span className="text-left text-rg font-demi text-raspberry-100">
                {service?.percentage}%
              </span>
            </li>
            <li className="flex flex-col">
              <span className="text-rg sm:text-sm text-blackPrimary-60 font-regular">
                არსებული
              </span>
              <span className="text-left text-rg font-demi text-blackPrimary-100">
                {service?.amount}₾
              </span>
            </li>
            <li className="flex flex-col">
              <span className="text-rg sm:text-sm text-blackPrimary-60 font-regular">
                შეთავაზებული
              </span>
              <span className="text-left text-rg font-demi text-blackPrimary-100">
                {service?.offerAmount}₾
              </span>
            </li>
          </div>
          <RemoveServiceMobileBtn
            check={check}
            clinic={clinic}
            service={service}
          />
        </ul>
      </div>
    );
  }
);
