import { memo, useCallback, useState } from "react";
import { ReactSVG } from "react-svg";
import { Save } from "Components/Buttons/Save";
import { Stars } from "Components/Stars/Stars";
import useWindowSize from "Hooks/useWindowSize";
import { Gallery } from "Components/Modals/Clinics/Gallery/Gallery";

const GalleryCard = ({
  index,
  setIsOpenGallery,
  sizeindex,
  img,
  rest,
}: any) => {
  return (
    <div
      onClick={() => setIsOpenGallery(true)}
      className="relative cursor-pointer w-full max-h-[258px] sm:max-h-[120px] sm:h-[120px] rounded-[12px] overflow-hidden"
    >
      {index == sizeindex && rest > 0 && (
        <div className="cursor-pointer absolute bg-blackPrimary-30 w-full h-full font-bold text-[24px] text-[#fff] flex items-center justify-center">
          +{rest}
        </div>
      )}
      <img className="object-cover h-full w-full" src={img} alt="clinicgalleryimg" />
    </div>
  );
};

export const GallerySection = memo(function GallerySectionComponent({
  data,
}: any) {
  const { width } = useWindowSize();
  const [isOpenGallery, setIsOpenGallery] = useState({
    state: false,
    id: null as number | null,
  });

  const handleOpenGallery = (index: number) =>
    setIsOpenGallery({
      state: true,
      id: index,
    });

  const handleClose = useCallback(
    () =>
      setIsOpenGallery({
        state: false,
        id: null,
      }),
    []
  );

  return (
    <div className="mt-[30px] sm:mt-0 sm:gap-[32px] sm:px-[16px] sm:bg-[transparent] mb-[60px] px-[44px] py-[24px] md:px-[24px] rounded-[12px] bg-gray-30 flex flex-col gap-11">
      {isOpenGallery?.state && (
        <Gallery
          data={data?.clinicDetails?.clinicDescriptionDTO?.imgUrls}
          onClose={handleClose}
          init={isOpenGallery?.id}
        />
      )}

      {width > 640 ? (
        <div className="grid grid-cols-4 lg:grid-cols-2 lg:gap-[22px] gap-[30px]">
          {data?.clinicDetails?.clinicDescriptionDTO?.imgUrls?.map(
            (img: string, index: number) =>
              index <= 3 && (
                <GalleryCard
                  index={index}
                  setIsOpenGallery={() => handleOpenGallery(index)}
                  key={index}
                  rest={
                    data?.clinicDetails?.clinicDescriptionDTO?.imgUrls?.length -
                    4
                  }
                  sizeindex={3}
                  img={img}
                />
              )
          )}
        </div>
      ) : (
        <div className="h-[248px] gap-2 flex">
          <div className="min-w-[210px] md:w-full w-[210px] h-full">
            {data?.clinicDetails?.clinicDescriptionDTO?.imgUrls?.map(
              (img: string, index: number) =>
                index == 0 && (
                  <div
                    onClickCapture={() => handleOpenGallery(index)}
                    key={index}
                    onClick={() => handleOpenGallery(index)}
                    className="relative w-full h-full rounded-[12px] overflow-hidden"
                  >
                    <img
                      className="object-cover h-full w-full"
                      src={img}
                      alt="clinicgalleryimg"
                    />
                  </div>
                )
            )}
          </div>
          <div className="flex flex-col gap-2">
            {data?.clinicDetails?.clinicDescriptionDTO?.imgUrls
              ?.slice(1, 5)
              .map(
                (img: string, index: number) =>
                  index <= 1 && (
                    <GalleryCard
                      setIsOpenGallery={() => handleOpenGallery(index)}
                      index={index}
                      key={index}
                      sizeindex={1}
                      onClickCapture={() => handleOpenGallery(index)}
                      rest={
                        data?.clinicDetails?.clinicDescriptionDTO?.imgUrls
                          ?.length - 3
                      }
                      img={img}
                    />
                  )
              )}
          </div>
        </div>
      )}
      <div className="mb-[16px] sm:mb-0">
        <h2 className="font-bold text-[18px] sm:text-rg text-blackPrimary-100 mb-[14px] sm:mb-0 leading-[36px]">
          {data?.clinicDetails?.name}
        </h2>
        <p className="font-regular text-rg text-blackPrimary-100">
          {data?.clinicDetails?.clinicDescriptionDTO?.description}
        </p>
      </div>
      {/* <div className="bg-[#FFFFFF] sm:p-0 p-[32px] rounded-[12px]">
        <h2 className="font-bold text-[18px] sm:text-rg text-blackPrimary-100 leading-[36px] sm:mb-3 mb-[24px]">
          მომხმარებლების შეფასებები
        </h2>
        <div className="mb-[24px]">
          <div className="flex flex-col gap-3">
            <div className="flex gap-2 items-center">
              <div className="bg-green-100 w-[48px] h-[48px] rounded-[50%] flex items-center justify-center overflow-hidden p-3">
                <ReactSVG src="/images/icons/avatar.svg" />
              </div>
              <h2 className="font-demi text-rg text-blackPrimary-100">
                ნიკოლოზ ჯიშკარიანი
              </h2>
            </div>
            <p className="font-medium text-rg text-blackPrimary-60">
              ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ
              როგორიხშირადაა შემთხვევა, როდესაც{" "}
            </p>
            <Stars rating={5} />
          </div>
        </div>
        <Save label="იხილე მეტი" className="bg-gray-100" />
      </div> */}
    </div>
  );
});
