import { memo, useCallback, useRef, useState } from "react";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { LeftArrow } from "Components/SwiperButtons/LeftArrow";
import { RightArrow } from "Components/SwiperButtons/RightArrow";
import useWindowSize from "Hooks/useWindowSize";

export const Gallery = memo(function GalleryComponent({
  data,
  onClose,
  init,
}: {
  data: any;
  onClose: () => void;
  init: number | null;
}) {
  const {width} = useWindowSize();
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass>();
  const sliderRef = useRef<any>(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <div className="fixed md:px-[10px] top-0 left-0 w-full h-[100dvh] bg-blackPrimary-30 md:bg-blackPrimary-60 z-[9999] flex flex-col justify-center">
      <div
        onClick={onClose}
        className="absolute top-0 left-0 w-full h-[100dvh] z-20"
      />
      <>
        <div className="flex z-40 left-[50%] mt-[-150px] translate-x-[-50%] w-[630px] justify-between absolute items-center gap-5 md:hidden">
          <LeftArrow handlePrev={handlePrev} />
          <RightArrow handleNext={handleNext} />
        </div>
        <Swiper
          ref={sliderRef}
          spaceBetween={10}
          thumbs={{
            swiper:
              thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
          }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper2 max-h-[508px] w-[506px] md:w-full rounded-[12px] relative z-40"
          initialSlide={data?.findIndex(
            (_: string, index: number) => index == init
          )}
        >
          {Array.isArray(data) &&
            data?.map((e: any, i: number) => (
              <SwiperSlide key={i}>
                <img className="selector object-cover w-full h-full" src={e} />
              </SwiperSlide>
            ))}
        </Swiper>
        <Swiper
          onSwiper={setThumbsSwiper}
          breakpoints={{
            300: {
              spaceBetween: 20,
              slidesPerView: 3,
            },
            832: {
              spaceBetween: 30,
              slidesPerView: 3,
            },
            1080: {
              spaceBetween: 30,
              slidesPerView: 3,
            },
          }}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper w-[420px] md:w-full h-[120px] mt-[23px] relative z-40"
        >
          {data?.map((e: any, i: number) => (
            <SwiperSlide
              key={i}
              className={`${width > 832 && 'w-[120px!important]'} h-[120px] rounded-[8px] overflow-hidden cursor-pointer`}
            >
              <img className="selector object-cover w-full h-full" src={e} />
            </SwiperSlide>
          ))}
        </Swiper>
      </>
    </div>
  );
});
