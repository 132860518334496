import { Skeleton } from "@mui/material";
import { memo } from "react";
import { ReactSVG } from "react-svg";

export const PhoneComponent = memo(function PhoneComp({
  phone,
  color = "",
}: any) {
  return (
    <>
      <ReactSVG src="/images/icons/phoneicon.svg" />
      <span
        style={{ color: color }}
        className={`${
          color && "line-clamp-1"
        } text-[14px] font-regular text-dark-default leading-[120%]`}
      >
        {phone}
      </span>
    </>
  );
});

const Phone = memo(({ phone, color }: any) => {
  return (
    <div className="flex items-center gap-2">
      <PhoneComponent color={color} phone={phone} />
    </div>
  );
});

Phone.displayName = "ClinicPhoneWithSkeleton";

export { Phone };
