import { Button } from "@mui/material";
import { memo } from "react";
import { ReactSVG } from "react-svg";

export const AddPaymentMethod = memo(function AddPaymentMethodComponent({
  title,
  icon,
  onClick,
}: {
  icon?: React.ReactNode;
  title?: string;
  onClick?: any;
}) {
  return (
    <Button
      onClick={onClick}
      aria-label="add-payment-method"
      className="custom-dashed-border-gray text-center bg-[#FFFFFF] py-[46px] px-[20px] flex gap-3 items-center justify-center rounded-[12px] font-demi text-blue-100 text-rg"
    >
      {icon}
      {title}
    </Button>
  );
});
