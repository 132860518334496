import { API_REQUEST } from "Request/API";

export const handleAddCardRequest = async (
  toast: any,
  isPrimary: boolean = false
) =>
  await API_REQUEST("POST", "medical/bank/saveCard", {
    sourceId: 1,
    isPrimary: isPrimary,
  })
    .then((res) => (document.location = res?.redirectUrl))
    .catch(() =>
      toast("მოხდა შეცდომა, თავიდან სცადეთ", {
        type: "error",
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        theme: "light",
      })
    );
