export const debounce = <T extends (...args: any[]) => void>(
    func: T,
    deb = 500
  ) => {
    let timer: NodeJS.Timeout | null;
  
    return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
      const context = this as ThisParameterType<T>;
  
      if (timer) clearTimeout(timer);
  
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, deb);
    };
  };