import { createSlice, current } from "@reduxjs/toolkit";

export const servicesSlice = createSlice({
  name: "services",
  initialState: {
    services: [],
    amount: 0,
    amountWithoutOffers: 0,
    savings: 0,
    message: false,
    messageBody: "",
    isModalOpen: false,
  },
  reducers: {
    addServices(state: any, action: any) {
      let serviceCheck = state?.services
        ?.filter((item: any) => {
          if (
            item.clinic.id == action.payload.clinic.id &&
            item.title == action.payload.title
          ) {
            return item;
          } else {
            return undefined;
          }
        })
        .filter((e: any) => e !== undefined);

      if (serviceCheck.length > 0) {
        return {
          ...state,
          services: [...state.services],
          message: false,
        };
      }

      return {
        ...state,
        services: [...state.services, action.payload],
        savings: (
          state.amountWithoutOffers +
          action.payload.amount -
          (state?.amount + action.payload.offerAmount)
        ).toFixed(1),
        amount: state?.amount + action.payload.offerAmount,
        amountWithoutOffers: state.amountWithoutOffers + action.payload.amount,
        message: false,
      };
    },
    removeServices(state: any, action: any) {
      const stateWithoutCurrentItem = state.services
        .map((service: any) => {
          if (
            service.title == action.payload.title &&
            service.clinic.id == action.payload.clinic.id
          ) {
            return undefined;
          } else {
            return service;
          }
        })
        .filter((e: any) => e !== undefined);

      return {
        ...state,
        services: stateWithoutCurrentItem,
        savings:
          parseFloat(
            (
              state?.savings -
              (action.payload.amount - action.payload.offerAmount)
            ).toFixed(1)
          ) || 0,
        amount:
          parseFloat((state?.amount - action.payload.offerAmount).toFixed(1)) ||
          0,
        amountWithoutOffers:
          parseFloat(
            (state.amountWithoutOffers - action.payload.amount).toFixed(1)
          ) || 0,
        message: false,
      };
    },
    clearServices() {
      return {
        services: [],
        amount: 0,
        amountWithoutOffers: 0,
        savings: 0,
        message: false,
        messageBody: "",
        isModalOpen: false,
      };
    },
  },
});

export const { addServices, removeServices, clearServices } =
  servicesSlice.actions;

export default servicesSlice.reducer;
