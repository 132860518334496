export const MedGroups = [
  {
    id: 0,
    title: "ანთების საწინააღმდეგო პრეპარატები",
    discount: "20",
  },
  {
    id: 1,
    title: "ანალგეზიური საშუალებები",
    discount: "20",
  },
  {
    id: 2,
    title: "ანტიბაქტერიული",
    discount: "20",
  },
  {
    id: 3,
    title: "ანტიჰისტამინური საშუალებები",
    discount: "20",
  },

  {
    id: 4,
    title: "ანტისეპტიკური საშუალებები",
    discount: "20",
  },
  {
    id: 5,
    title: "გასტროენტეროლოგია, ჰეპატოლოგია",
    discount: "20",
  },
  {
    id: 6,
    title: "გლუკოკორტიკოიდები",
    discount: "20",
  },
  {
    id: 7,
    title: "დერმატოლოგია",
    discount: "20",
  },
  {
    id: 8,
    title: "ენდოკრინოლოგია",
    discount: "20",
  },
  {
    id: 9,
    title: "ვიტამინები, პოლივიტამინები და მიკროელემენტები",
    discount: "20",
  },
  {
    id: 10,
    title: "გინეკოლოგიური პრეპარატები",
    discount: "20",
  },
  {
    id: 11,
    title: "ნივთიერებათა ცვლაზე მოქმედი პრეპარატები",
    discount: "20",
  },
  {
    id: 12,
    title: "ოტორინოლარინგოლოგიური პრეპარატები",
    discount: "20",
  },
  {
    id: 13,
    title: "ოფთალმოლოგიური პრეპარატები",
    discount: "20",
  },
  {
    id: 14,
    title: "ჰომეოპათიური საშუალებები",
    discount: "20",
  },
  {
    id: 15,
    title: "ცენტრალურ ნერვულ სისტემაზე მოქმედი პრეპარატები",
    discount: "20",
  },
  {
    id: 16,
    title: "ფოროპრეპარატები",
    discount: "20",
  },
  {
    id: 17,
    title: "უროლოგიური და ნეფროლოგიური პრეპარატები",
    discount: "20",
  },
  {
    id: 18,
    title: "სტომატოლოგიაში გამოსაყენებელი პრეპარატები",
    discount: "20",
  },
  {
    id: 19,
    title: "სოკოს საწინააღმდეგო საშუალებები (ანტიფუნგალური)",
    discount: "20",
  },
  {
    id: 20,
    title: "პულმონოლოგიაში გამოსაყენებელი პრეპარატები",
    discount: "20",
  },
  {
    id: 21,
    title: "პროქტოლოგიაში გამოსაყენებელი პრეპარატები",
    discount: "20",
  },
  {
    id: 22,
    title: "ანტიპროტოზოული საშუალებები",
    discount: "20",
  },
];

export const medObjects = <any>{
  bamba: [
    {
      id: 0,
      name: "ბინტი სტერილური",
      src: "/images/pharmacy/bestpharma/ბინტი სტერილური_1.jpg",
      title: "ბამბები და ბინტები",
      discount: "20",
    },
    {
      id: 1,
      name: "ბინტი არასრეტილური",
      src: "/images/pharmacy/bestpharma/ბინტი არასტერილური.jpg",
      title: "ბამბები და ბინტები",
      discount: "20",
    },
    {
      id: 2,
      name: "ბინტი ელასტიური",
      src: "/images/pharmacy/bestpharma/ბინტი ელასტიური.jpg",
      title: "ბამბები და ბინტები",
      discount: "20",
    },
    {
      id: 3,
      name: "ბამბა სტერილური",
      src: "/images/pharmacy/bestpharma/ბამბა სტერილური_2.jpg",
      title: "ბამბები და ბინტები",
      discount: "20",
    },
  ],
  baiani: [
    {
      id: 4,
      name: "კათეტერი",
      src: "/images/pharmacy/bestpharma/კათეტერი.jpg",
      title: "შპრიცები და კათეტერები",
      discount: "20",
    },
    {
      id: 5,
      name: "ერთჯერადი შპრიცი",
      src: "/images/pharmacy/bestpharma/needleOne.jpeg",
      title: "შპრიცები და კათეტერები",
      discount: "20",
    },
    {
      id: 6,
      name: "გადასხმის სისტემა",
      src: "/images/pharmacy/bestpharma/სისტემა გადასხმის.jpg",
      title: "შპრიცები და კათეტერები",
      discount: "20",
    },
    {
      id: 7,
      name: "ინსულინის შპრიცი",
      src: "/images/pharmacy/bestpharma/შპრიცი ინსულინის_1.jpg",
      title: "შპრიცები და კათეტერები",
      discount: "20",
    },
  ],
  cneva: [
    {
      id: 8,
      name: "წნევის აპარატი მაჯის",
      src: "/images/pharmacy/bestpharma/წნევის.jpeg",
      title: "გლუკომეტრები და წნევის აპარატები",
      discount: "20",
    },
    {
      id: 9,
      name: "მექანიკური წნევის აპარატი",
      src: "/images/pharmacy/bestpharma/წნევის აპარატი მექანიკური.jpg",
      title: "გლუკომეტრები და წნევის აპარატები",
      discount: "20",
    },
    {
      id: 10,
      name: "გლუკომეტრი",
      src: "/images/pharmacy/bestpharma/გლუკომეტრი.png",
      title: "გლუკომეტრები და წნევის აპარატები",
      discount: "20",
    },
    {
      id: 11,
      name: "გლუკომეტრის ჩხირი",
      src: "/images/pharmacy/bestpharma/გლუკომეტრის ჩხირები.jpg",
      title: "გლუკომეტრები და წნევის აპარატები",
      discount: "20",
    },
  ],
};
