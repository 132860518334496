import { Skeleton } from "@mui/material";
import { memo } from "react";
import { ReactSVG } from "react-svg";

export const LocationComponent = memo(function LocationComp({
  address,
  color = "",
}: any) {
  return (
    <>
      <ReactSVG src="/images/icons/location.svg" />
      <span
        style={{ color: color }}
        className={`${
          color && "line-clamp-1"
        } text-[14px] font-regular text-dark-default leading-[120%]`}
      >
        {address?.region}, {address?.address}
      </span>
    </>
  );
});

const Location = memo(
  ({
    address,
    isLoadingClinic = false,
    isErrorClinic = false,
    color = "",
  }: any) => {
    return (
      <div className="flex items-center gap-2">
        {isLoadingClinic || isErrorClinic ? (
          <div className="flex gap-3">
            <Skeleton width={20} height={30} />
            <Skeleton width={100} height={30} />
          </div>
        ) : (
          <LocationComponent color={color} address={address} />
        )}
      </div>
    );
  }
);

Location.displayName = "ClinicLocationWithSkeleton";

export { Location };
