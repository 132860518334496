import { memo } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

export const OfferBackButton = memo(function OfferBackButtonComponent() {
  return (
    <div className="bg-[#fff] py-[30px] relative z-10">
      <div className="container">
        <Link to="/">
          <button className="flex items-center gap-1 text-md font-medium text-blackPrimary-100">
            <ReactSVG wrapper="span" src="/images/icons/back.svg" />
            უკან
          </button>
        </Link>
      </div>
    </div>
  );
});
