import * as Yup from "yup";

const personalInfoSchemeForMember = Yup.object({
  firstName: Yup.string()
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\u0400-\u04FF\u0370-\u03FF\u10A0-\u10FF\s]*)$/gi,
      "სახელი უნდა შეიცავდეს მხოლოდ ასოებს"
    )
    .matches(/^(\S+$)/, "* სფეისები არ არის დაშვებული")
    .required("სახელი აუცილებელია"),
  lastName: Yup.string()
    .required("გვარი აუცილებელია")
    .matches(/^(\S+$)/, "* სფეისები არ არის დაშვებული")
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\u0400-\u04FF\u0370-\u03FF\u10A0-\u10FF\s]*)$/gi,
      "გვარი უნდა შეიცავდეს მხოლოდ ასოებს"
    ),
  foreignCitizen: Yup.boolean(),
  personalId: Yup.string().when(["foreignCitizen"], {
    is: true,
    otherwise: (schema) =>
      schema
        .matches(/^[0-9]+$/, "პირადი ნომერი უნდა შეიცავდეს მხოლოდ ციფრებს")
        .length(11, "პირადი ნომერი უნდა იყოს 11 სიმბოლო")
        .required("პირადი ნომერი აუცილებელია"),
    then: (schema) => schema.required("პირადი ნომერი აუცილებელია"),
  }),
});

export default personalInfoSchemeForMember;
