import { useQuery } from "@tanstack/react-query";
import { API_REQUEST } from "Request/API";

export const useMunicipalitiesQuery = (regions: any, categorySlug: any) => {
  const {
    data: municipalities,
    isError: isErrorMunicipalities,
    isLoading: isLoadingMunicipalities,
    isSuccess: isSuccessMunicipalities,
  } = useQuery({
    queryKey: [`municipality-${regions?.toString()}`],
    queryFn: () => {
      const params = new URLSearchParams();
      regions.forEach((regionId: any) =>
        params.append("regionId", regionId.toString())
      );
      return API_REQUEST(
        "GET",
        `clinic/municipality?${params.toString()}&categorySlug=${categorySlug}`,
        null
      );
    },
    retry: 0,
    enabled: Array.isArray(regions) && regions?.length > 0,
    refetchOnWindowFocus: false,
  });

  return {
    municipalities,
    isErrorMunicipalities,
    isLoadingMunicipalities,
    isSuccessMunicipalities,
  };
};
