import { memo } from "react";
import { ReactSVG } from "react-svg";

export const CancelSubscribe = memo(function CancelSubscribe({ onClick }: any) {
  return (
    <span onClick={onClick} className="cursor-pointer text-rg text-raspberry-100 font-demi leading-[21px] flex items-center gap-[6px]">
      გამოწერის გაუქმება <ReactSVG src="/images/icons/info.svg" />
    </span>
  );
});
