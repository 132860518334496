import { useQuery } from "@tanstack/react-query";
import { API_REQUEST } from "Request/API";

export const useUserBankCardsQuery = () => {
  const {
    data: bankCard,
    isError: isErrorBankCard,
    isLoading: isLoadingBankCard,
    isSuccess: isSuccessBankCard,
    refetch: isRefetchBankCard,
  } = useQuery({
    queryKey: [`USER-BANK-CARDS`],
    queryFn: () => API_REQUEST("GET", `medical/bank/cards`),
    retry: 0,
    refetchOnWindowFocus: false,
  });

  return {
    bankCard,
    isErrorBankCard,
    isLoadingBankCard,
    isSuccessBankCard,
    isRefetchBankCard,
  };
};
