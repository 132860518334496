import { memo, useState } from "react";

export const SwitchMemberType = memo(function SwitchMemberTypeComponent({
  state = true,
  setState,
  variant = "default",
}: {
  state: boolean;
  setState: (state: boolean) => void;
  variant?: string;
}) {
  const switcherVariant = variant == "default" ? "bg-green-100" : "bg-gray-100";
  // const
  return (
    <div className="relative flex h-[52px] items-center bg-gray-30 rounded-[12px] overflow-hidden">
      <div
        className={`${
          !state && "translate-x-[100%]"
        } absolute w-[50%] h-full transition-all ${switcherVariant} rounded-[12px]`}
      ></div>
      <div
        onClick={() => setState(true)}
        className={`${
          state
            ? variant == "default"
              ? "text-[#fff]"
              : "text-blackPrimary-100"
            : variant == "default"
            ? "text-blackPrimary-100"
            : "text-blackPrimary-60"
        } transition-all cursor-pointer relative w-[50%] text-center px-[51px] pointer font-medium text-rg flex justify-center`}
      >
        სრულწლოვანი
      </div>
      <div
        onClick={() => setState(false)}
        className={`${
          !state
            ? variant == "default"
              ? "text-[#fff]"
              : "text-blackPrimary-100"
            : variant == "default"
            ? "text-blackPrimary-100"
            : "text-blackPrimary-60"
        } transition-all cursor-pointer relative w-[50%] text-center px-[51px] pointer font-medium text-rg flex justify-center`}
      >
        არასრულწლოვანი
      </div>
    </div>
  );
});
