import { FormControlLabel, Skeleton } from "@mui/material";
import { RootState } from "@reduxjs/toolkit/query";
import { Save } from "Components/Buttons/Save";
import { BpCheckbox } from "Components/Checkbox/Checkbox";
import { stateForPharmacy } from "Helpers/stateForPharmacy";
import useNavigationWithQueryParams from "Hooks/useNavigationWithQueryParams";
import { useSlugs } from "Hooks/useQuery";
import { useAppSelector } from "Store/store";
import { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

export const Filter = memo(function FilterComponent({
  regions,
  municipalities,
  categories,
  handleRegions,
  handleMunicipalities,
  selectedRegions,
  selectedMunicipalities,
  category,
  close,
}: any) {
  const allcategories = useAppSelector((state) => state.categories);
  const { updateRegions, updateMunicipalities, moveToPage } =
    useNavigationWithQueryParams();
  // initialize slugs
  const slugs = useSlugs();
  const { stateForParmacyCards } = stateForPharmacy(slugs);
  const slug = slugs[slugs.length - 1];
  const [state, setState] = useState({
    regions: true,
    municipality: true,
  });
  let catTitle = allcategories?.list?.filter(
    (e: any) => e.slug == category
  )?.[0]?.title;

  const handleButtonClick = () => {
    moveToPage(1);
    close();
    updateRegions(selectedRegions);
    updateMunicipalities(selectedMunicipalities);
  };

  return (
    <div className="w-[282px] min-w-[282px] md:w-full md:bg-[transparent] bg-gray-30 rounded-[16px] py-[32px] px-[20px] md:pb-[80px]">
      <div
        className={`${
          !stateForParmacyCards && "pb-[20px] mb-[20px]"
        } border-solid border-b-[1px] border-b-gray-30 md:border-b-gray-100`}
      >
        <h3 className="font-bold text-rg text-blackPrimary-100 mb-2">
          {category ? catTitle : "მიმართულება"}
        </h3>
        <div className="w-full">
          <ul className="w-full flex flex-col items-start gap-3 mb-[12px]">
            {categories?.map(
              (item: any, index: number) =>
                item?.clinicCount !== 0 && (
                  <li
                    key={index}
                    className={`${
                      item.slug == slug && "text-blue-100"
                    } font-medium cursor-pointer hover:text-blue-100 transition-colors w-full text-rg text-blackPrimary-100`}
                  >
                    <Link
                      className="flex justify-between items-center"
                      key={index}
                      to={`${category !== undefined ? `${category}/` : ""}${
                        item.slug
                      }`}
                    >
                      {item.slug == slug ? (
                        <h1 className="font-medium text-rg">{item.title}</h1>
                      ) : (
                        item.title
                      )}
                      <span className="text-blackPrimary-30">
                        {item?.clinicCount}
                      </span>
                    </Link>
                  </li>
                )
            )}
          </ul>
          <Link
            to={`${
              category
                ? slugs?.length == 2
                  ? `/clinics`
                  : `/clinics/${slugs[slugs.length - 2]}`
                : "."
            }`}
          >
            <button
              aria-label="allclinic-btn"
              className="font-demi text-raspberry-100 text-rg"
            >
              ყველა კლინიკა
            </button>
          </Link>
        </div>
      </div>
      {!stateForParmacyCards && (
        <>
          <div className="border-solid border-b-[1px] border-b-gray-30 md:border-b-gray-100 pb-[20px] mb-[20px]">
            <h3
              onClick={() =>
                setState((prev) => ({ ...prev, regions: !state.regions }))
              }
              className="flex cursor-pointer justify-between items-center font-bold text-rg text-blackPrimary-100 mb-2"
            >
              რეგიონები
              <ReactSVG
                style={{
                  transform: state?.regions ? "rotate(0deg)" : "rotate(180deg)",
                }}
                src="/images/icons/arrow.svg"
              />
            </h3>
            {state.regions && (
              <div className="flex flex-col items-start gap-1 w-full max-h-[267px] overflow-y-auto">
                {!regions?.isLoadingRegions || !regions?.isErrorRegions
                  ? regions?.regions?.map((region: any, index: number) => (
                      <FormControlLabel
                        key={index}
                        checked={selectedRegions.includes(region.id.toString())}
                        onChange={() => handleRegions(region.id.toString())}
                        className="flex flex-row-reverse mr-0 ml-0 w-full justify-between font-[14px!important] text-rg text-blackPrimary-100"
                        control={<BpCheckbox />}
                        label={region.title}
                      />
                    ))
                  : Array.from({ length: 5 }).map((_, i) => (
                      <div className="w-full flex justify-between" key={i}>
                        <Skeleton width={"60%"} height={24} />
                        <Skeleton
                          variant="rectangular"
                          className="min-w-[20px]"
                          width="20"
                          height={20}
                        />
                      </div>
                    ))}
              </div>
            )}
          </div>
          {selectedRegions?.length > 0 && (
            <div className="pb-[10px] mb-[10px]">
              <h3
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    municipality: !state.municipality,
                  }))
                }
                className="flex cursor-pointer justify-between items-center font-bold text-rg text-blackPrimary-100 mb-2"
              >
                მუნიციპალიტეტები
                <ReactSVG
                  style={{
                    transform: state?.municipality
                      ? "rotate(0deg)"
                      : "rotate(180deg)",
                  }}
                  src="/images/icons/arrow.svg"
                />
              </h3>
              {state.municipality && (
                <div className="flex flex-col items-start gap-1 w-full max-h-[267px] overflow-y-auto">
                  {municipalities?.municipalities?.map(
                    (municipality: any, index: number) => (
                      <FormControlLabel
                        key={index}
                        checked={selectedMunicipalities.includes(
                          municipality.id.toString()
                        )}
                        onChange={() =>
                          handleMunicipalities(municipality.id.toString())
                        }
                        className="flex flex-row-reverse mr-0 ml-0 w-full justify-between font-[14px!important] text-rg text-blackPrimary-100"
                        control={<BpCheckbox />}
                        label={municipality.title}
                      />
                    )
                  )}
                </div>
              )}
            </div>
          )}
          <Save
            onClick={handleButtonClick}
            label="გაფილტრე"
            variant="green"
            className="max-w-full w-full md:fixed md:bottom-[16px] md:left-[50%] md:translate-x-[-50%] md:w-[calc(100%-32px)]"
          />
        </>
      )}
    </div>
  );
});
