import { memo, useState } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { DropdownMenu } from "./DropdownMenu";
import { anchor } from "types/types";
import { MouseEvents } from "types/ReactTypes";
import { useSlugs } from "Hooks/useQuery";

type MenuItem = {
  id: number;
  title: string;
  href: string;
};

export const MenuItem = memo(function MenuItemComponent({
  item,
}: {
  item: MenuItem;
}) {
  const slugs = useSlugs();
  const [anchorEl, setAnchorEl] = useState<anchor>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvents) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  console.log(slugs[0] == item.href.slice(1, item.href.length));

  return (
    <li className="cursor-pointer">
      {item?.id == 0 ? (
        <span
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          className="flex font-demi items-center gap-[4px]"
        >
          {item.title}
          <ReactSVG src="/images/icons/arrow.svg" />
        </span>
      ) : (
        <Link
          preventScrollReset={false}
          className={`${
            slugs[0] == item.href.slice(1, item.href.length)
              ? "text-blue-100"
              : "text-blackPrimary-100"
          } relative group hover:text-blue-100 transition-all flex font-demi items-center gap-[4px]`}
          to={item?.href}
        >
          {item?.id == 2 && (
            <span className="absolute top-[-8px] right-0">
              <ReactSVG src="/images/icons/vita.svg" />
            </span>
          )}
          {item.title}
          <div
            className={`${
              slugs[0] == item.href.slice(1, item.href.length)
                ? "w-[30%]"
                : "w-[0]"
            } absolute bottom-[-10px] group-hover:w-[30%] h-[1px] transition-all bg-blue-100`}
          ></div>
        </Link>
      )}
      <DropdownMenu anchorEl={anchorEl} handleClose={handleClose} open={open} />
    </li>
  );
});
