import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { ReactSVG } from "react-svg";
import { CircularProgress } from "@mui/material";
import { SeeAll } from "Components/Buttons/SeeAll";
import { ServiceModal } from "Components/Modals/Clinics/ServiceModal";
import { ServicesTable } from "Components/Tables/ServicesTable";
import { debounce } from "Helpers/Debounce";
import { useParentServicesQuery } from "Hooks/Queries/useParentServicesQuery";
import { useServicesSearch } from "Hooks/Queries/useServicesSearchQuery";
import { useSubServicesQuery } from "Hooks/Queries/useSubServicesQuery";
import { useSlugs } from "Hooks/useQuery";
import useWindowSize from "Hooks/useWindowSize";
import { GradientCircularProgress } from "Components/Progress/GradientCircularProgress";
import { ServiceMobileListItem } from "Components/Cards/ServiceMobileListItem";
import JsonLd from "Components/JSONLD/JsonLd";
import { Helmet } from "react-helmet";

export const Services = memo(function Services({ clinic }: { clinic: any }) {
  const { width } = useWindowSize();
  const slugs = useSlugs();
  const slug = slugs[slugs.length - 1];
  const [state, setState] = useState<number | null>(null);
  const [search, setSearch] = useState("");
  const [value, setValue] = useState("");
  const [isLoadingServiceSearch, setIsLoadingServicesSearch] = useState(false);
  const [isOpenServiceModal, setIsOpenServiceModal] = useState(false);
  const [page, setPage] = useState(1);
  const [allSubServices, setAllSubServices] = useState<any[]>([]);
  const { parentServices } = useParentServicesQuery(slug);
  const [serviceName, setServiceName] = useState("");
  const [ldjson, setLdJson] = useState([]);
  const { subServices, isSuccessSubServices } = useSubServicesQuery(
    state,
    slug,
    10,
    page - 1
  );
  const { searchedServices, isLoadingSearchedServices } = useServicesSearch(
    clinic?.clinicDetails?.slug,
    value
  );

  const clearData = () => {
    setPage(1);
    setAllSubServices([]);
  };

  useEffect(() => {
    setLdJson(
      parentServices?.parentServices?.map((item: any) => ({
        "@context": "https://schema.org",
        "@type": "Service",
        serviceType: item?.title,
      }))
    );
  }, [parentServices, isSuccessSubServices]);

  useEffect(() => {
    if (subServices && isSuccessSubServices) {
      if (page === 1) {
        setAllSubServices(subServices?.content);
      } else {
        setAllSubServices((prev) => [...prev, ...subServices?.content]);
      }
    }
  }, [subServices, isSuccessSubServices, page]);

  const fetchNextPage = () => setPage((prev) => prev + 1);

  const handleModal = useCallback(
    (state: boolean) => setIsOpenServiceModal(state),
    []
  );

  const handleChange = (e: any) => {
    setValue(e);
    setIsLoadingServicesSearch(false);
  };

  const debouncedHandleChange = useMemo(() => debounce(handleChange, 1000), []);

  return (
    <div className="sm:px-[16px]">
      {width <= 640 && (
        <ServiceModal
          serviceName={serviceName}
          open={isOpenServiceModal}
          close={() => {
            setState(null);
            handleModal(false);
          }}
          state={state}
          slug={slug}
          clinic={clinic}
        />
      )}
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldjson)}</script>
      </Helmet>
      <div className="mb-[16px] flex justify-between items-center sm:flex-col sm:items-start sm:gap-3">
        <h3 className="text-[18px] sm:text-rg font-bold text-blackPrimary-100">
          მომსახურებები
        </h3>
        <div className="h-[48px] w-[316px] sm:w-full bg-[#fff] flex items-center rounded-[12px] px-[20px] sm:px-[12px] sm:bg-gray-100 sm:bg-[#E8ECF466] gap-2">
          {isLoadingSearchedServices || isLoadingServiceSearch ? (
            <div className="w-[24px] h-[24px] overflow-hidden">
              <GradientCircularProgress />
            </div>
          ) : (
            <div className="w-[24px] h-[24px] overflow-hidden">
              <ReactSVG src="/images/icons/searchloop.svg" />
            </div>
          )}
          <input
            value={search}
            onChange={(e) => {
              debouncedHandleChange(e.target.value);
              setSearch(e.target.value);
              setIsLoadingServicesSearch(true);
            }}
            className="outline-none border-none bg-[transparent] font-medium sm:font-regular w-full"
            name="search"
            placeholder="მოძებნე მომსახურება"
            autoComplete="off"
          />
        </div>
      </div>
      <div className="bg-[#fff] px-[24px] sm:px-0 rounded-[12px]">
        {search == "" ? (
          parentServices?.parentServices?.map((item: any, i: number) => {
            return (
              <div
                key={i}
                className="border-b-[1px] border-solid border-b-gray-100"
              >
                <h2
                  onClick={() => {
                    if (width > 640) {
                      setState(state !== item.id ? item.id : null);
                    } else {
                      setState(state !== item.id ? item.id : null);
                      handleModal(true);
                      setServiceName(item.title);
                    }
                    clearData();
                  }}
                  className="flex font-medium items-center gap-1 py-[23px] cursor-pointer sm:justify-between"
                >
                  {item.title}
                  <ReactSVG
                    className={`mt-1 ${
                      state == item.id ? "rotate-[180deg]" : "rotate-[0]"
                    } ${width <= 640 && "rotate-[270deg]"}`}
                    src="/images/icons/arrow.svg"
                  />
                  <span className="sm:hidden ml-auto bg-blue-10 text-blue-100 text-[12px] font-demi rounded-[8px] p-2">
                    {item?.serviceCount}
                  </span>
                </h2>
                {state == item.id && width > 640 && (
                  <div className="pb-5">
                    <ServicesTable clinic={clinic} services={allSubServices} />
                    {subServices?.totalPages > page && (
                      <div className="flex justify-center">
                        <SeeAll
                          onClick={() => fetchNextPage()}
                          className="text-md"
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })
        ) : !isLoadingSearchedServices && !isLoadingServiceSearch ? (
          <div className="py-5">
            {searchedServices?.content?.length !== 0 ? (
              width > 640 ? (
                <ServicesTable
                  clinic={clinic}
                  services={searchedServices?.content}
                />
              ) : (
                <div className="flex flex-col gap-3">
                  {searchedServices?.content?.map((service: any, i: number) => (
                    <ServiceMobileListItem
                      clinic={clinic}
                      service={service}
                      key={i}
                    />
                  ))}
                </div>
              )
            ) : (
              <div className="p-20 flex justify-center text-center items-cente text-[24px] font-demi">
                სახელით "{search}" სერვისები არ მოიძებნა
              </div>
            )}
          </div>
        ) : (
          <div className="p-20 flex justify-center text-center items-center text-[24px] font-demi">
            მიმდინარეობს სერვისების ძებნა...
          </div>
        )}
      </div>
    </div>
  );
});
