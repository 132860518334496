import { VitaPercent } from "Components/Buttons/VitaPercent";

export const OfferType2 = ({
  imgUrl,
  discount,
  title,
  onClick,
}: {
  imgUrl: string;
  discount: string;
  title: string;
  onClick?: any;
}) => {
  return (
    <div className="bg-gray-30 pt-[20px] pb-[24px] rounded-[12px] flex flex-row items-center justify-between px-[24px] gap-[10px]">
      <div className="w-[48px] h-[48px] flex items-center justify-center overflow-hidden bg-[#fff] rounded-[50%]">
        <img className="object-contain" src={imgUrl} alt="offer image" />
      </div>
      <div className="flex flex-col gap-[2px] mr-auto">
        <h2 className="text-rg font-bold leading-[21px] mb-0">{title}</h2>
        <span
          onClick={onClick}
          className="cursor-pointer text-sm font-demi text-raspberry-100"
        >
          იხილე მეტი
        </span>
      </div>
      <VitaPercent percent={discount} icon={false} />
    </div>
  );
};
