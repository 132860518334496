import { CircularProgress } from "@mui/material";

export const GradientCircularProgress = () => {
  return (
    <>
      <svg width={0} height={0}>
        <defs>
          <linearGradient
            width={14}
            height={14}
            id="my_gradient"
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop offset="0%" stopColor="#e01cd5" />
            <stop offset="100%" stopColor="#1CB5E0" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress
        className="w-[20px!important] h-[20px!important] ml-[0px]"
        sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
      />
    </>
  );
};
