import { memo } from "react";
import { ReactSVG } from "react-svg";

export const WrongInfo = memo(function WrongInfoComponent({
  text,
}: {
  text: string;
}) {
  return (
    <div className="flex items-start gap-[6px]">
      <ReactSVG className="mt-1" src="/images/icons/error.svg" />
      <span
        dangerouslySetInnerHTML={{
          __html: text,
        }}
        className="text-rg sm:text-sm font-medium text-raspberry-100"
      />
    </div>
  );
});
