import { memo, useCallback, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { VitaPercent } from "Components/Buttons/VitaPercent";
import { LeftArrow } from "Components/SwiperButtons/LeftArrow";
import { RightArrow } from "Components/SwiperButtons/RightArrow";

export const OffersBlock = memo(function OffersBlockComponent({ data }: any) {
  const sliderRef = useRef<any>(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <>
      <div className="flex justify-between mb-[44px] sm:px-[16px] sm:mb-[20px] items-center">
        <h2 className="font-bold text-[24px] sm:text-rg lg:text-sloganLgSize md:text-sloganMdSize leading-[56px] lg:leading-[34px] md:leading-[24px] text-blackPrimary-100">
          ფასდაკლება პროტეინებსა და სპორტულ კვებაზე
        </h2>
        <div className="flex items-center gap-5 md:hidden">
          <LeftArrow handlePrev={handlePrev} />
          <RightArrow handleNext={handleNext} />
        </div>
      </div>
      <div className="flex sm:flex-col h-[383px] lg:h-auto items-center gap-8">
        <div className="sm:px-[16px] w-[336px] sm:w-full min-w-[336px] lg:hidden sm:block">
          <img
            className="preventImgSelect"
            src="/images/pspofferbg.png"
            alt="vita banner"
          />
        </div>
        <Swiper
          ref={sliderRef}
          spaceBetween={12}
          breakpoints={{
            300: {
              slidesPerView: 1.4,
            },
            600: {
              slidesPerView: 2,
            },
            700: {
              slidesPerView: 3,
            },
            1080: {
              slidesPerView: 2,
            },
            1180:{
              slidesPerView: 3,
            },
            1380: {
              slidesPerView: 4,
            },
          }}
          className="w-full sm:px-[16px]"
        >
          {data?.map((item: any, i: number) => {
            return (
              <SwiperSlide
                key={i}
                className="min-h-[383px] rounded-[12px] p-[24px] bg-[#fff] customxlsize:min-h-[300px] xl:min-h-[260px] lg:min-h-[300px] flex flex-col gap-1"
              >
                <div className="relative overflow-hidden px-4 flex-1 flex h-full">
                  <div className="absolute z-10">
                    <VitaPercent percent={item?.discountNumber} icon={true} />
                  </div>
                  <img
                    className="m-auto h-[270px] object-contain"
                    src={item?.imageUrl}
                    alt="proteinshake"
                  />
                </div>
                <h2 className="line-clamp-2 font-bold text-md text-blackPrimary-100 leading-[24px]">
                  {item.description}
                </h2>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
});
