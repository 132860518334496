import { ReactSVG } from "react-svg";
import { SearchBox } from "./SearchBox";
import { useEffect, useMemo, useState } from "react";
import { useClinicsSearchQuery } from "Hooks/Queries/useClinicsSearchQuery";
import { debounce } from "Helpers/Debounce";

export const SearchInput = ({
  isFocued,
  setIsFocused,
  inputRef,
  bodyRef,
}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState(search);

  const handler = debounce((value: string) => {
    setIsLoading(false);
    setDebouncedSearch(value);
  }, 500);

  const handleSearch = useMemo(() => debounce(handler, 1000), []);

  const { clinicResults, isLoadingClinicResults, isSuccessClinicResults } =
    useClinicsSearchQuery({
      page: 0,
      size: 100,
      key: debouncedSearch,
    });

  return (
    <div
      className={`${isFocued ? "w-[342px]" : "w-[302px]"} flex ${
        !isFocued && "hover:w-[334px]"
      } md:hidden relative transition-all bg-inputGray py-[14px] px-[20px] rounded-[12px] items-center`}
    >
      <ReactSVG src="/images/icons/search.svg" />
      <input
        onFocus={() => setIsFocused(true)}
        onChange={(e) => {
          setIsLoading(true);
          setSearch(e.target.value);
          handleSearch(e.target.value);
        }}
        value={search}
        className="w-full ml-[8px] bg-[transparent] outline-none font-regular text-rg placeholder:font-regular"
        type="text"
        placeholder="მოძებნე კლინიკები"
        ref={inputRef}
      />
      {search.length > 0 && isFocued && (
        <SearchBox
          close={() => {
            setIsFocused(false);
            setSearch("");
            setDebouncedSearch("");
          }}
          isLoading={isLoading}
          bodyRef={bodyRef}
          clinicResults={clinicResults}
          isLoadingClinicResults={isLoadingClinicResults}
          isSuccessClinicResults={isSuccessClinicResults}
          keyWord={search}
        />
      )}
    </div>
  );
};
