import { memo, useId, useMemo, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CustomCheckbox } from "Components/Checkbox/Checkbox";
import { TextFieldInput } from "Components/Inputs/TextField";
import { WrongInfo } from "Components/Messages/Warnings/WrongInfo";
import { Save } from "Components/Buttons/Save";
import { InputMessage } from "Components/Messages/InputValidations/InputMessage";
import { API_REQUEST } from "Request/API";
import { Button, debounce } from "@mui/material";
import { useAppDispatch, useAppSelector } from "Store/store";
import { createMember } from "Reducers/FamilyMembers";
import { isUniqueMember } from "Helpers/isUniqueMember";
import { toast } from "react-toastify";
import personalInfoScheme from "Scheme/PersonalInfoScheme";
import personalInfoSchemeForMember from "Scheme/PersonalInfoSchemeForMember";

export const Children = memo(function ChildrenComponent({
  close,
  additionalevent,
  type = "default",
}: {
  close?: any;
  additionalevent?: any;
  type?: string;
}) {
  const id = useId();
  const dispatch = useAppDispatch();
  const members = useAppSelector((state) => state.familymembers);
  const [canAddAsMember, setCanAddAsMember] = useState({
    status: true,
    error: "",
  });
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      personalId: "",
      foreignCitizen: false,
    },
    validationSchema: personalInfoSchemeForMember,
    onSubmit: (values) => {
      const newUser = { ...values, id: id };
      if (isUniqueMember(newUser, members?.members)) {
        dispatch(createMember(newUser));
        additionalevent && additionalevent();
        close && close();
      } else {
        toast("იგივე პირადი ნომრით წევრი უკვე დაამატეთ", {
          type: "error",
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          theme: "light",
        });
      }
    },
  });

  const handlePersonalId = (e: any) => {
    formik.handleChange(e);
    debouncedPersonIdChange(e);
    setCanAddAsMember((e) => ({
      status: false,
      error: "",
    }));
  };

  const debouncedPersonIdChange = useMemo(
    () =>
      debounce((e) => {
        API_REQUEST(
          "GET",
          "user/find-by-personalId",
          undefined,
          {
            personalId: e.target.value,
          },
          "fullresponse"
        )
          .then((res) => {
            if (res.status == 200) {
              if (res.data == "") {
                setCanAddAsMember({
                  status: true,
                  error: "",
                });
              } else {
                setCanAddAsMember({
                  status: res?.data?.canAddAsMember,
                  error: res?.data?.errorMassage,
                });
              }
            }
          })
          .catch((err) => console.log(err, "error"));
      }, 500),
    []
  );

  return (
    <div className="mt-[20px] h-full flex flex-col gap-[15px]">
      <form
        onSubmit={formik.handleSubmit}
        className="mt-[20px] flex flex-col gap-[15px]"
      >
        <div className="flex items-start gap-4">
          <div className="w-full">
            <TextFieldInput
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="bg-[#E8ECF44D!important]"
              label="სახელი"
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <InputMessage message={formik.errors.firstName} />
            ) : null}
          </div>
          <div className="w-full">
            <TextFieldInput
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="bg-[#E8ECF44D!important]"
              label="გვარი"
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <InputMessage message={formik.errors.lastName} />
            ) : null}
          </div>
        </div>
        <div className={`flex flex-col gap-1`}>
          {type === "default" && (
            <CustomCheckbox
              name="foreignCitizen"
              checked={formik.values.foreignCitizen}
              onChange={formik.handleChange}
              label="უცხო ქვეყნის მოქალაქე"
            />
          )}
          <TextFieldInput
            name="personalId"
            value={formik.values.personalId}
            onChange={handlePersonalId}
            onBlur={formik.handleBlur}
            className="bg-[#E8ECF44D!important]"
            label="პირადი ნომერი"
          />
          {formik.touched.personalId && formik.errors.personalId ? (
            <InputMessage message={formik.errors.personalId} />
          ) : null}
          {type === "createMember" && (
            <CustomCheckbox
              name="foreignCitizen"
              checked={formik.values.foreignCitizen}
              onChange={formik.handleChange}
              label="უცხო ქვეყნის მოქალაქე"
            />
          )}
        </div>
        {type === "default" && (
          <WrongInfo
            text={`არასწორი ინფორმაციის შეყვანის შემთხვევაში, გთხოვთ დარეკოთ ქოლ ცენტში <span class="text-blue-100">0322185577</span>`}
          />
        )}
        {canAddAsMember.status == false && canAddAsMember.error !== "" && (
          <WrongInfo text={"მოცემული პირადი ნომრით უკვე არსებობს იუზერი"} />
        )}
        <div className="flex justify-end mt-auto sm:mt-0">
          {canAddAsMember?.status ? (
            <Save
              type="submit"
              className="sm:w-full sm:min-w-full"
              variant="green"
              label="დამატება"
            />
          ) : (
            <Button
              aria-label="default"
              className={`bg-[#ddd] text-blackPrimary-100 max-w-[160px] text-nowrap whitespace-nowrap shadow-none px-[43px] py-[16px] font-bold text-rg leading-[21px] rounded-[12px]`}
              disabled
            >
              დამატება
            </Button>
          )}
        </div>
      </form>
    </div>
  );
});
