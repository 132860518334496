import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface userSlice {
  user: any;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
}

const initialState: userSlice = {
  user: {},
  isLoading: true,
  isError: false,
  isSuccess: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userData: (state, action: PayloadAction<any>) => {
      state.user = action.payload.data;
      state.isLoading = action.payload.isLoading;
      state.isError = action.payload.isError;
      state.isSuccess = action.payload.isSuccess;
    },
    removeUserData: (state) => {
      state.user = {};
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
    },
  },
});

export const { userData, removeUserData } = userSlice.actions;
export default userSlice.reducer;
