import { FormControlLabel, Skeleton } from "@mui/material";
import { RootState } from "@reduxjs/toolkit/query";
import { Save } from "Components/Buttons/Save";
import { BpCheckbox } from "Components/Checkbox/Checkbox";
import { stateForPharmacy } from "Helpers/stateForPharmacy";
import useNavigationWithQueryParams from "Hooks/useNavigationWithQueryParams";
import { useCustomQuery, useSlugs } from "Hooks/useQuery";
import { useAppSelector } from "Store/store";
import { memo, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";

export const ResultsFilter = memo(function ResultsFilterComponent({
  categories,
  category,
  regions,
  handleRegions,
  selectedRegions,
  handleFilter,
  clearFilter,
  close,
}: any) {
  const navigate = useNavigate();
  const query = useCustomQuery();

  const allcategories = useAppSelector((state) => state.categories);
  const { updateRegions } = useNavigationWithQueryParams();
  // initialize slugs
  const slugs = useSlugs();
  const { stateForParmacyCards } = stateForPharmacy(slugs);
  const slug = slugs[slugs.length - 1];
  const [state, setState] = useState({
    regions: true,
  });
  let catTitle = allcategories?.list?.filter(
    (e: any) => e.slug == category
  )?.[0]?.title;

  const addCategory = (cat: any) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("category", cat);
    searchParams.set("regionId", "");
    navigate(`/searchresults?${searchParams.toString()}`);
    clearFilter();
  };

  const handleButtonClick = () => {
    // moveToPage(1);
    // close();
    updateRegions(selectedRegions);
    handleFilter();
    close()
  };

  return (
    <div className="w-[282px] min-w-[282px] md:w-full md:bg-[transparent] bg-gray-30 rounded-[16px] py-[32px] px-[20px] md:pb-[80px]">
      <div
        className={`${
          !stateForParmacyCards && "pb-[20px] mb-[20px]"
        } border-solid border-b-[1px] border-b-gray-30 md:border-b-gray-100`}
      >
        <h3 className="font-bold text-rg text-blackPrimary-100 mb-2">
          {category ? catTitle : "მიმართულება"}
        </h3>
        <div className="w-full">
          <ul className="w-full flex flex-col items-start gap-3 mb-[12px]">
            {categories?.map(
              (item: any, index: number) =>
                item?.clinicCount !== 0 && (
                  <li
                    key={index}
                    className={`${
                      item.slug == query.get("category") && "text-blue-100"
                    } font-medium cursor-pointer hover:text-blue-100 transition-colors w-full text-rg text-blackPrimary-100`}
                  >
                    <div onClick={() => addCategory(item.slug)}>
                      {item.title}
                      <span className="text-blackPrimary-30">
                        {item?.clinicCount}
                      </span>
                    </div>
                  </li>
                )
            )}
          </ul>
          <Link
            to={`/clinics`}
          >
            <button
              aria-label="allclinic-btn"
              className="font-demi text-raspberry-100 text-rg"
            >
              ყველა კლინიკა
            </button>
          </Link>
        </div>
      </div>
      <div className="border-solid border-b-[1px] border-b-gray-30 md:border-b-gray-100 pb-[20px] mb-[20px]">
        <h3
          onClick={() =>
            setState((prev) => ({ ...prev, regions: !state.regions }))
          }
          className="flex cursor-pointer justify-between items-center font-bold text-rg text-blackPrimary-100 mb-2"
        >
          რეგიონები
          <ReactSVG
            style={{
              transform: state?.regions ? "rotate(0deg)" : "rotate(180deg)",
            }}
            src="/images/icons/arrow.svg"
          />
        </h3>
        {state.regions && (
          <div className="flex flex-col items-start gap-1 w-full max-h-[267px] overflow-y-auto">
            {regions
              ? regions?.regions?.map((region: any, index: number) => (
                  <FormControlLabel
                    key={index}
                    checked={selectedRegions.includes(region.region.toString())}
                    onChange={() => handleRegions(region.region.toString())}
                    className="flex flex-row-reverse mr-0 ml-0 w-full justify-between font-[14px!important] text-rg text-blackPrimary-100"
                    control={<BpCheckbox />}
                    label={region.region}
                  />
                ))
              : Array.from({ length: 5 }).map((_, i) => (
                  <div className="w-full flex justify-between" key={i}>
                    <Skeleton width={"60%"} height={24} />
                    <Skeleton
                      variant="rectangular"
                      className="min-w-[20px]"
                      width="20"
                      height={20}
                    />
                  </div>
                ))}
          </div>
        )}
      </div>
      <Save
        onClick={handleButtonClick}
        label="გაფილტრე"
        variant="green"
        className="max-w-full w-full md:fixed md:bottom-[16px] md:left-[50%] md:translate-x-[-50%] md:w-[calc(100%-32px)]"
      />
    </div>
  );
});
