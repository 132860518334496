import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

export const UserList = ({
  liStyle = "",
  current = "",
  activeListItem = "",
  spanStyle = "",
  close,
  hasUserCard = false,
}: any) => {
  return (
    <>
      <li
        onClick={close}
        className={`${liStyle} ${current == "user" && activeListItem}`}
      >
        <Link to="/user">
          <span className={spanStyle}>
            <ReactSVG src="/images/icons/usertool/user.svg" />
            ჩემი პროფილი
          </span>
        </Link>
      </li>
      <li
        onClick={close}
        className={`${liStyle} ${
          current == "card" &&
          (hasUserCard ? activeListItem : "after:w-1 transition-all after:transition-all after:content-[''] after:absolute after:h-full after:bg-green-100 after:top-0 after:rounded-r-[4px]")
        }`}
      >
        <Link to="/user/card">
          <span className={`${spanStyle} ${current == "card" && 'text-green-100'} `}>
            <ReactSVG className={`${current == "card" && 'useractiveSvg'}`} src="/images/icons/usertool/vitalogo.svg" />
            ვიტა ბარათი
            {hasUserCard ? (
              <span className="font-demi text-sm text-green-100 bg-green01 rounded-[5px] ml-auto px-[8px] py-[6px]">
                აქტიური
              </span>
            ) : (
              <div className="font-demi text-sm text-raspberry-100 bg-raspberry-10 rounded-[5px] ml-auto px-[8px] py-[6px]">
                არააქტიური
              </div>
            )}
          </span>
        </Link>
      </li>
      <li
        onClick={close}
        className={`${liStyle} ${current == "bankcards" && activeListItem}`}
      >
        <Link to="/user/bankcards">
          <span className={spanStyle}>
            <ReactSVG src="/images/icons/usertool/cards.svg" />
            ჩემი საბანკო ბარათები
          </span>
        </Link>
      </li>
      <li
        onClick={close}
        className={`${liStyle} ${current == "notifications" && activeListItem}`}
      >
        <Link to="/user/notifications">
          <span className={spanStyle}>
            <ReactSVG src="/images/icons/usertool/notification.svg" />
            შეტყობინებების მართვა
          </span>
        </Link>
      </li>
    </>
  );
};
