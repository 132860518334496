import { useQuery } from "@tanstack/react-query";
import { API_REQUEST } from "Request/API";

export const useParentServicesQuery = (id: string) => {
  const {
    data: parentServices,
    isError: isErrorParentServices,
    isLoading: isLoadingParentServices,
    isSuccess: isSuccessParentServices,
  } = useQuery({
    queryKey: [`clinic-parent-services-${id}`],
    queryFn: () =>
      API_REQUEST("GET", `clinic/parent-services`, null, {
        clinicId: id,
      }),
    retry: 0,
    enabled: id !== undefined,
    refetchOnWindowFocus: false,
  });

  return {
    parentServices,
    isErrorParentServices,
    isLoadingParentServices,
    isSuccessParentServices,
  };
};
