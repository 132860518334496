import { useQuery } from "@tanstack/react-query";
import { API_REQUEST } from "Request/API";

export const useClinicsQuery = (
  clinicProps: any,
  slug: any,
  pageProps: any,
  regionIds: any,
  municipalityIds: any
) => {
  const { data, isError, isLoading, isSuccess } = useQuery({
    queryKey: [
      `clinics`,
      [
        clinicProps.page,
        clinicProps.size,
        clinicProps.slug,
        clinicProps.discount,
        regionIds,
        municipalityIds,
      ],
    ],
    queryFn: () => {
      const params = new URLSearchParams();
      if (slug !== "clinics") params.set("slug", slug);
      params.set("page", (clinicProps.page - 1).toString());
      params.set("size", clinicProps.size.toString());
      clinicProps.discount &&
        params.set("sort", clinicProps.discount.toString());
      if (clinicProps.regions) {
        clinicProps.regions.forEach((regionId: any) =>
          params.append("regionId", regionId.toString())
        );
      }
      if (clinicProps.municipalities) {
        clinicProps.municipalities.forEach((municipalityId: any) =>
          params.append("municipalityId", municipalityId.toString())
        );
      }
      return API_REQUEST("GET", `clinic/clinics?${params.toString()}`, null);
    },
    retry: 0,
    enabled: pageProps.isSlugReady,
    refetchOnWindowFocus: false,
  });

  return { data, isError, isLoading, isSuccess };
};
