import { Save } from "Components/Buttons/Save";
import { ReactSVG } from "react-svg";

export const NotFound = () => {
  return (
    <div className="h-[calc(100dvh-120px)] flex items-center justify-center">
      <div className="flex relative flex-col items-center">
        <ReactSVG className="mb-[25px] relative z-20" src="/images/icons/sadEmojy.svg" />
        <span className="block mb-[40px] text-md font-bold relative z-20">
          სამწუხაროდ, გვერდი ვერ მოიძებნა
        </span>
        <Save
          className="relative z-20 max-w-[255px!important]"
          variant="green"
          label="მთავარზე დაბრუნება"
        />
        <div className="absolute z-10 top-[50%] translate-y-[-40%] left-[50%] translate-x-[-50%] bg-errorbg bg-contain w-[456px] h-[288px]"></div>
      </div>
    </div>
  );
};
