import { BasicBreadcrumbs } from "Components/Breadcrumbs/Breadcrumbs";
import { ClinicCard } from "Components/Cards/ClinicCard";
import { SelectInputComponent } from "Components/Inputs/SelectInput";
import { FilterModal } from "Components/Modals/Clinics/FilterModal";
import { Filter } from "Components/PageSections/Clinics/Filter/Filter";
import { ResultsFilter } from "Components/PageSections/Clinics/Filter/ResultsFilter";
import { ClinicSkeleton } from "Components/PageSections/Clinics/Skeletons/ClinicSkeleton";
import { collectCategories } from "Helpers/CollectCategories";
import { collectRegions } from "Helpers/CollectRegions";
import { useClinicsSearchQuery } from "Hooks/Queries/useClinicsSearchQuery";
import { useArrayQueries, useCustomQuery } from "Hooks/useQuery";
import useWindowSize from "Hooks/useWindowSize";
import { useCallback, useEffect, useState } from "react";
import { ReactSVG } from "react-svg";

export const SearchResults = () => {
  const query = useCustomQuery();
  const customQuery = useArrayQueries();
  const regionIds = customQuery.regionId;
  const { width } = useWindowSize();
  const { clinicResults, isLoadingClinicResults, isSuccessClinicResults } =
    useClinicsSearchQuery({
      page: 0,
      size: 10,
      key: query.get("key"),
    });
  const [data, setData] = useState([]);
  const [filltredOnlyCats, setFilltredOnlyCats] = useState([]);
  const [categories, setCategories] = useState<any>([]);
  const [regions, setRegions] = useState<any>([]);
  const [selectedRegions, setSelectedRegions] = useState<any>([]);
  const [sort, setSort] = useState(null);
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const handleRegionsCallback = useCallback((regionId: any) => {
    setSelectedRegions((prevProps: any) => {
      if (prevProps.includes(regionId)) {
        return [...prevProps.filter((region: any) => region !== regionId)];
      }

      return [...prevProps, regionId];
    });
  }, []);

  const filter = () => (
    <ResultsFilter
      regions={{
        regions: regions,
        isErrorRegions: false,
        isLoadingRegions: false,
        isSuccessRegions: true,
      }}
      selectedRegions={selectedRegions}
      handleRegions={handleRegionsCallback}
      categories={categories}
      handleFilter={handleFilterByRegion}
      clearFilter={() => {
        setData(filltredOnlyCats);
        setSelectedRegions([]);
        setSort(null);
      }}
      close={() => setIsOpenFilter(false)}
    />
  );

  const handleFilterByRegion = (propRegions = selectedRegions) => {
    const modifyData = filltredOnlyCats?.filter((e: any) =>
      propRegions.some((x: any) => x == e.address.region)
    );
    if (propRegions?.length !== 0) setData(modifyData);
    else setData(filltredOnlyCats);
  };

  useEffect(() => {
    // console.log("sms");
    setData(clinicResults?.content);
  }, [clinicResults, isSuccessClinicResults]);

  useEffect(() => {
    let clcategories = collectCategories(
      isSuccessClinicResults,
      clinicResults?.content
    );
    setCategories(clcategories);
  }, [data]);

  useEffect(() => {
    const filterClinicsByCategory = clinicResults?.content?.filter((e: any) =>
      e.clinicCategory.some((x: any) => x.slug == query.get("category"))
    );
    if (query.get("category")) {
      setFilltredOnlyCats(filterClinicsByCategory);
      setData(filterClinicsByCategory);
    } else {
      setFilltredOnlyCats(clinicResults?.content);
      setData(clinicResults?.content);
    }
    // collect regions
    let clregions = collectRegions(
      query.get("category") ? filterClinicsByCategory : clinicResults?.content
    );
    setRegions(clregions);
  }, [query.get("category"), clinicResults]);

  useEffect(() => {
    const regions = Array.isArray(regionIds)
      ? regionIds
      : regionIds
      ? [regionIds]
      : [];
    setSelectedRegions(regions);
    Array.isArray(regions) && handleFilterByRegion(regions);
  }, [regionIds, clinicResults, filltredOnlyCats]);

  const sortFunc = (sort: any) => {
    let sortedData = [...data].sort((a: any, b: any) => {
      if (sort === "discount:desc") {
        return b.discount - a.discount;
      } else {
        return a.discount - b.discount;
      }
    });
    setData(sortedData);
  };
  if (query.get("key") == undefined) {
    return <div>asdas</div>;
  }

  return (
    <div className="container md:mt-4">
      {width < 832 && (
        <FilterModal
          open={isOpenFilter}
          close={() => setIsOpenFilter(false)}
          filter={filter()}
          handleSortCallback={sortFunc}
        />
      )}
      <BasicBreadcrumbs
        breadcrumbs={[
          {
            title: (
              <div>
                "მოიძებნა კლინიკა
                <span className="text-green-100">"{query.get("key")}"</span>
              </div>
            ),
            link: "/",
          },
        ]}
      />
      <div className="flex md:flex-col my-[40px] sm:mt-[17px] items-start gap-[30px] pb-12">
        <div className="md:hidden">{filter()}</div>
        <div className="hidden md:flex justify-end w-full">
          <button
            onClick={() => setIsOpenFilter(true)}
            className="hidden bg-gray-100 px-4 pl-2 rounded-[8px] py-1 md:flex items-center gap-1 text-blackPrimary-60 text-blackPrimary-100 font-bold text-sm"
          >
            <ReactSVG src="/images/icons/filterbtn.svg" />
            ფილტრი
          </button>
        </div>
        <div className="w-full">
          <div className="flex items-center justify-between mb-[32px]">
            <h2 className="text-[24px] font-bold">
              ძიების შედეგი ({data?.length}): "{query.get("key")}"
            </h2>
            <SelectInputComponent
              value={sort || ""}
              className="bg-gray-30 w-[282px] md:hidden font-bold"
              label="სორტირება"
              onChange={(e: any) => {
                setSort(e); // update the sort state
                sortFunc(e); //
              }}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              options={[
                {
                  value: "discount:desc",
                  title: "მაღალი პროცენტი",
                },
                {
                  value: "discount:asc",
                  title: "დაბალი პროცენტი",
                },
              ]}
            />
          </div>
          <div className="w-full grid grid-cols-4 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1 gap-x-[30px] gap-y-[30px]">
            {isLoadingClinicResults
              ? Array.from({ length: 8 }).map((_, i) => (
                  <ClinicSkeleton key={i} />
                ))
              : data?.map((clinic: any, i: number) => (
                  <ClinicCard clinic={clinic} key={i} />
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};
