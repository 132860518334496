export const collectCategories = (
  isSuccessClinicResults: boolean,
  clinicResults: any
) => {
  if (isSuccessClinicResults) {
    const clinics = clinicResults;
    let categories: any = [];

    for (let clinic of clinics) {
      let clinicCategories = clinic.clinicCategory;
      for (let category of clinicCategories) {
        if (categories.some((e: any) => e.id == category.id)) {
          continue;
        }
        categories.push(category);
      }
    }

    const parents = categories.filter((e: any) => e.parentCategoryId == null);
    const childrens = categories.filter((e: any) => e.parentCategoryId != null);

    const collectList = [];

    for (const [index, category] of parents.entries()) {
      collectList.push(category);
      for (let child of childrens) {
        if (category?.id == child.parentCategoryId) {
          if (collectList[index]["childrens"] == undefined) {
            collectList[index]["childrens"] = [];
          }
          collectList[index]["childrens"].push(child);
        }
      }
    }

    return collectList;
  }
};
