import { VitaButton } from "Components/Buttons/VitaButton";
import { memo } from "react";

export const VitaSummer = memo(function VitaSummerComponent() {
  return (
    <div className="container flex flex-col items-center mb-[140px] lg:mb-[60px]">
      <h4 className="font-bold text-[24px] lg:text-[20px] md:text-md leading-[45px] md:leading-[26px] text-blackPrimary-100 text-center max-w-[1259px] m-auto">
        VITA ბარათით წარმატებით ისარგებლა უკვე 15 000+ მომხმარებელმა.
        <br />
        გახდი VITA-ს მფლობელი შენც, VITA იზრუნებს შენს ჯანმრთელობასა და
        კეთილდღეობაზე!
      </h4>
      <span className="text-raspberry-100 block text-center m-auto font-bold text-[24px] md:text-md my-[24px] md:my-[16px]">
        მხოლოდ 15 ლარად
      </span>
      <VitaButton className="md:w-full md:max-w-full" span="md:justify-center" />
    </div>
  );
});
