import { ReactSVG } from "react-svg";

export const MobileSearchInput = ({ search, onChange, clear, close }: any) => {
  return (
    <div className="w-full h-[36px] px-[12px] gap-2 flex items-center bg-gray-40 border-solid border-[1px] border-gray-100 rounded-[8px]">
      <img
        onClick={close}
        width={20}
        height={20}
        src="/images/icons/searchloop.svg"
      />
      <input
        value={search}
        onChange={onChange}
        className="bg-[transparent] w-full text-sm font-regular outline-none"
        name="search"
        placeholder="მოძებნე კლინიკა"
      />
      {search?.length > 0 && (
        <img
          onClick={clear}
          width={20}
          height={20}
          src="/images/icons/close.svg"
        />
      )}
    </div>
  );
};
