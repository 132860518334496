import { memo, useCallback, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { VitaPercent } from "Components/Buttons/VitaPercent";
import { LeftArrow } from "Components/SwiperButtons/LeftArrow";
import { RightArrow } from "Components/SwiperButtons/RightArrow";
import { ReactSVG } from "react-svg";
import { medObjects } from "JSON/BESTPHARMA";
import useWindowSize from "Hooks/useWindowSize";
import { Tab, Tabs } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: 16,
          marginRight: 16,
          borderRadius: 12,
          backgroundColor: "#E8ECF44D", // Background color of Tabs
          minHeight: "58px", // Minimum height of the Tabs container
        },
        flexContainer: {
          height: '58px'
        },
        scroller: {
          margin: 0,
          height: '58px',
        },
        indicator: {
          backgroundColor: "#EF357D", // Color of the selected tab indicator
        },
        scrollButtons: {
          width: "30px",
          // color: "#3f51b5", // Color of the scroll buttons
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: 'avenir-demi',
          height: '100%',
          minWidth: "120px", // Minimum width of each tab
          color: "#000", // Text color of tabs
          margin: 0,
          "&.Mui-selected": { color: "#EF357D!important" },
          "&:hover": {
            color: "#1a73e8", // Text color on hover
          },
          selected: {
            color: "#EF357D!important",
          },
        },
      },
    },
  },
});

export const MedObjects = memo(function MedObjects() {
  const sliderRef = useRef<any>(null);
  const { width } = useWindowSize();
  const [state, setState] = useState("bamba");

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="flex sm:px-[16px] justify-between mb-[44px] sm:mb-[20px] items-center">
        <ReactSVG
          beforeInjection={(svg) => {
            svg.setAttribute("style", "width: 36px; height: 36px;");
          }}
          src="/images/pharmacy/needle.svg"
        />
        <h2 className="mr-auto ml-[6px] font-bold text-[24px] sm:text-rg lg:text-sloganLgSize md:text-sloganMdSize leading-[56px] lg:leading-[34px] md:leading-[24px] text-blackPrimary-100">
          სამედიცინო დანიშნულების საგნები
        </h2>
        <div className="flex items-center gap-5 md:hidden">
          <LeftArrow handlePrev={handlePrev} />
          <RightArrow handleNext={handleNext} />
        </div>
      </div>
      <div className="flex sm:flex-col h-[383px] lg:h-auto gap-8">
        {width > 640 && (
          <div className="relative w-[328px] min-w-[328px] gap-7 rounded-[12px] p-[32px] flex flex-col items-start bg-gray-30">
            <button
              onClick={() => setState("bamba")}
              className="relative z-10 w-full font-demi text-md text-blackPrimary-100 text-left min-w-full flex items-center justify-between"
            >
              ბამბები და ბინტები <ReactSVG src="/images/icons/arrowright.svg" />
            </button>
            <button
              onClick={() => setState("baiani")}
              className="w-full relative z-10  font-demi text-md text-blackPrimary-100 text-left min-w-full flex items-center justify-between"
            >
              შპრიცები და კათეტერები{" "}
              <ReactSVG src="/images/icons/arrowright.svg" />
            </button>
            <button
              onClick={() => setState("cneva")}
              className="w-full relative z-10  font-demi text-md text-blackPrimary-100 text-left min-w-full flex items-center justify-between"
            >
              გლუკომეტრები და წნევის აპარატები{" "}
              <ReactSVG src="/images/icons/arrowright.svg" />
            </button>
            <img
              src="/images/pharmacy/vitabg.svg"
              className="absolute bottom-0 left-[50%] translate-x-[-50%]"
            />
          </div>
        )}
        {width <= 640 && (
          <div className="relative overflow-hidden">
            <ThemeProvider theme={theme}>
              <Tabs
                value={value}
                onChange={handleChange}
                scrollButtons={true}
                allowScrollButtonsMobile={true}
                variant="scrollable"
                aria-label="scrollable force tabs example"
                className="relative z-10"
              >
                <Tab
                  onClick={() => setState("bamba")}
                  label="ბამბები და ბინტები"
                />
                <Tab
                  onClick={() => setState("baiani")}
                  label="შპრიცები და კათეტერები"
                />
                <Tab
                  onClick={() => setState("cneva")}
                  label="გლუკომეტრები და წნევის აპარატები"
                />
              </Tabs>
            </ThemeProvider>
            <img
              src="/images/pharmacy/vitabg.svg"
              className="absolute bottom-0 left-[50%] translate-x-[-50%]"
            />
          </div>
        )}
        <Swiper
          ref={sliderRef}
          spaceBetween={12}
          breakpoints={{
            300: {
              slidesPerView: 1.4,
            },
            600: {
              slidesPerView: 2,
            },
            700: {
              slidesPerView: 3,
            },
            1080: {
              slidesPerView: 2,
            },
            1180: {
              slidesPerView: 3,
            },
            1380: {
              slidesPerView: 4,
            },
          }}
          className="w-full sm:px-[16px]"
        >
          {medObjects[state]?.map((item: any, i: number) => {
            return (
              <SwiperSlide
                key={i}
                className="min-h-[303px] sm:bg-gray-30 hover:bg-gray-30 rounded-[12px] p-[24px] bg-[#fff] customxlsize:min-h-[300px] xl:min-h-[260px] lg:min-h-[300px] flex flex-col gap-1"
              >
                <div className="relative overflow-hidden flex-1 flex h-full">
                  <div className="absolute z-10">
                    <VitaPercent
                      className="text-[#fff!important] bg-raspberry-100"
                      percent={item?.discount}
                      icon={true}
                    />
                  </div>
                  <img
                    className="m-auto object-contain"
                    src={item?.src}
                    alt="proteinshake"
                  />
                </div>
                <h2 className="line-clamp-2 font-bold text-md text-blackPrimary-100 leading-[24px]">
                  {item.name}
                </h2>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
});
