import { memo } from "react";

export const VitaStatistics = memo(function VitaStatisticsComponent() {
  return (
    <div className="container sm:w-full md:max-w-full lg:mb-[60px] mb-[120px] md:px-[32px] sm:px-0">
      <img
        className="cover w-full cursor-pointer sm:hidden"
        src="/images/vitabanner.png"
        alt="vitabanner"
        loading="lazy"
      />
      <img
        className="cover w-full cursor-pointer hidden sm:block"
        src="/images/vitabannermobile.webp"
        alt="vitamobilebanner"
      />
    </div>
  );
});
