import { SeeAll } from "Components/Buttons/SeeAll";
import { VitaPercent } from "Components/Buttons/VitaPercent";
import { OfferType1 } from "Components/Offers/OfferType1";
import { memo } from "react";
import { Link } from "react-router-dom";

export const Offers = memo(function OffersComponent({ offer }: any) {
  return (
    <div className="bg-gray-30 py-[64px] relative after:content-[''] after:bg-elementarrowtop after:top-0 after:left-0 after:absolute after:w-[475px] md:after:w-[200px] after:bg-contain after:h-[321px] after:bg-no-repeat before:content-[''] before:bg-elementarrowbottom before:bottom-0 before:right-10 before:absolute before:w-[506px] md:before:w-[288px] md:before:h-[92px] md:before:left-0 md:before:right-[inherit] before:bg-contain  before:h-[193px] before:bg-no-repeat">
      <div className="container md:w-full md:max-w-full relative z-10">
        <div className="md:px-[32px] sm:px-[16px]">
          <h2 className="text-[40px] lg:text-sloganLgSize md:text-sloganMdSize font-bold text-blackPrimary-100 leading-[56px] lg:leading-[34px] md:leading-[24px] mb-[24px] lg:mb-[16px]">
            {offer?.offer?.title}
          </h2>
          <h4 className="text-[20px] lg:text-md md:text-rg font-medium text-blackPrimary-60 leading-[30px] md:leading-[21px] md:mb-[20px] mb-[44px]">
            {offer?.offer?.description}
          </h4>
        </div>
        <div className="hidescroll grid gap-[32px] grid-cols-4 md:flex md:flex-nowrap md:w-full md:overflow-y-auto mb-5 lg:gap-4 md:gap-2 md:px-[32px] sm:px-[16px]">
          {offer?.offerItems?.map(
            (offer: any, i: number) =>
              i < 4 && (
                <Link
                  to={offer?.clinicId ? `/detailed/${offer?.clinicId}` : "#"}
                  key={i}
                >
                  <OfferType1
                    imgUrl={offer.imgUrl}
                    discount={offer.discount}
                    title={offer.title}
                    description={offer.description}
                    sortNum={i}
                  />
                </Link>
              )
          )}
        </div>
        {offer?.offerItems?.length > 4 && (
          <div className="flex justify-end md:justify-center md:px-[32px]">
            <Link to={`/offers/${offer.offer?.id}`}>
              <SeeAll />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
});
