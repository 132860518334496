import { ClinicCard } from "Components/Cards/ClinicCard";
import { ClinicMessage } from "Components/Messages/Clinic/ClinicMessage";
import { memo } from "react";
import { ReactSVG } from "react-svg";
import { Services } from "../Services/Services";
import useWindowSize from "Hooks/useWindowSize";
import { OffersMobileCard } from "Components/Cards/OffersMobileCard";

export const ServicesSection = memo(function ServicesSectionComponent({
  clinic,
  clinicSlug,
}: {
  clinic: any;
  clinicSlug: string;
}) {
  const { width } = useWindowSize();

  return (
    <div className="mt-[30px] sm:mt-0 mb-[60px] sm:px-[0] px-[44px] py-[24px] md:px-[24px] rounded-[12px] bg-gray-30 sm:bg-[transparent] flex flex-col gap-11 sm:gap-[32px]">
      {width > 1080 && <ClinicMessage />}
      {clinic?.clinicDetails?.offer?.map((offer: any, index: number) => (
        <div key={index}>
          <div className="flex sm:px-[16px] items-center gap-2 mb-[16px]">
            <ReactSVG src="/images/icons/offersicon.svg" />
            <h2 className="font-bold text-[18px] sm:text-rg text-blackPrimary-100 leading-[36px]">
              {offer?.offer.title}
            </h2>
            <ReactSVG
              className="sm:hidden"
              src="/images/icons/offersicon.svg"
            />
          </div>
          <div className="bg-[#fff] hidescroll sm:px-[16px] grid grid-cols-4 sm:flex sm:flex-nowrap sm:overflow-auto xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 gap-[16px] px-[32px] py-[24px] rounded-[12px] sm:p-0">
            {offer?.offerItems?.map((clinic: any, i: number) => {
              const modifydata = {
                name: clinic?.title,
                discount: clinic?.discount,

                ...clinic,
              };
              return width > 869 ? (
                <ClinicCard clinic={modifydata} key={i} isOffer={true} />
              ) : (
                <OffersMobileCard
                  title={clinic?.title}
                  discount={clinic?.discount}
                  key={i}
                />
              );
            })}
          </div>
        </div>
      ))}

      <Services clinic={clinic} />
    </div>
  );
});
