import { memo } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ThemeProvider } from "@emotion/react";
import { theme } from "./TextFieldTheme";
import dayjs from "dayjs";
import "dayjs/locale/ka";

export const DatePickerInput = memo(function DatePickerInputComponent({
  value,
  className = "bg-[#fff!important]",
  onChange,
}: {
  value?: any;
  className?: string;
  onChange?: any;
}) {
  const maxDate = new Date("2020-12-31");

  return (
    <LocalizationProvider adapterLocale="ka" dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <DatePicker
          onChange={onChange}
          slotProps={{ textField: { variant: "filled" } }}
          label={<>დაბადების თარიღი</>}
          className={`${className} w-full`}
          value={dayjs(value)}
          maxDate={dayjs(maxDate)}
        />
      </ThemeProvider>
    </LocalizationProvider>
  );
});
