import { useQuery } from "@tanstack/react-query";
import { API_REQUEST } from "Request/API";

export const useBranchesQuery = (page: number, size: number, id: string) => {
  const {
    data: branches,
    isError: isErrorBranches,
    isLoading: isLoadingBranches,
    isSuccess: isSuccessBranches,
  } = useQuery({
    queryKey: [`branches-${id}`, [page, size, id]],
    queryFn: () =>
      API_REQUEST("GET", `clinic/clinics`, null, {
        page: page,
        size: size,
        parentClinicId: id,
      }),
    retry: 0,
    enabled: page !== undefined && size !== undefined && id !== undefined,
    refetchOnWindowFocus: false,
  });

  return { branches, isErrorBranches, isLoadingBranches, isSuccessBranches };
};
