import { Button } from "@mui/material";
import { memo } from "react";
import { SaveType } from "types/ButtonTypes";

export const Save = memo(function SaveComponent({
  icon,
  variant,
  label = "შენახვა",
  onClick,
  className,
  type = "button",
  disabled = false,
}: SaveType) {
  const btnClass =
    variant == "green"
      ? "text-[#fff] bg-green-100 hover:bg-green-60 hover:shadow-none"
      : variant == "gray"
      ? "bg-gray-100 text-blackPrimary-60"
      : "bg-[#fff] text-blackPrimary-100";
  return (
    <Button
      type={type || "button"}
      aria-label="default"
      onClick={onClick}
      className={`${btnClass} max-w-[160px] text-nowrap whitespace-nowrap shadow-none px-[43px] py-[16px] font-bold text-rg leading-[21px] rounded-[12px] ${className}`}
      variant="contained"
      disabled={disabled}
    >
      {icon}
      {label}
    </Button>
  );
});
