export const SeeAll = ({
  className,
  onClick,
}: {
  className?: string;
  onClick?: any;
}) => {
  return (
    <span
      onClick={onClick}
      className={`${className} text-raspberry-100 underline font-demi text-[20px] lg:text-md md:text-rg cursor-pointer`}
    >
      იხილე მეტი
    </span>
  );
};
