import React, { memo } from "react";
import { ReactSVG } from "react-svg";

export const UserSectionContainer = memo(
  function UserSectionContainerComponent({
    children,
  }: {
    children: string | JSX.Element | JSX.Element[] | any;
  }) {
    return (
      <div className="bg-gray-30 py-[32px] md:p-[16px] px-[44px] rounded-[12px] relative sm:mt-[16px]">
        <div className="absolute top-0 right-0">
          <ReactSVG src="/images/icons/vitaiconbg.svg" />
        </div>
        {children}
      </div>
    );
  }
);
