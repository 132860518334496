import { VitaButton } from "Components/Buttons/VitaButton";
import { memo } from "react";
import { Helmet } from "react-helmet";

export const VitaHighlit = memo(function VitaHighlitComponent() {
  return (
    <div className="relative w-full mt-[100px] lg:mt-[44px]">
      <Helmet>
        <link rel="preload" href="/images/cardsimage.webp" as="image" />
      </Helmet>
      <div className="container">
        <div className="max-w-[66.29%] lg:max-w-full">
          <h1 className="font-bold text-sloganSize text-blackPrimary-100 mb-[48px] lg:mb-[12px] lg:text-[28px] lg:text-center sm:text-[20px]">
            VITA ჯანდაცვის ბარათი
          </h1>
          <span className="block font-mzeqala text-sloganSize text-blackPrimary-60 mb-[48px] lg:text-[28px] sm:text-[20px] lg:text-center">
            ,,შენი ჯანმრთელობის თილისმა’’
          </span>
          <div className="w-full hidden lg:flex lg:justify-center lg:mb-10 sm:w-full">
            <img
              width={375}
              height={60}
              className="w-[602px]"
              src="/images/cardsimage.png"
              alt="vita cards"
            />
          </div>
          <h2 className="font-bold text-sloganSize mb-[44px] lg:mb-[24px] lg:flex lg:flex-col lg:items-center lg:text-[28px] sm:text-[20px]">
            შეიძინე ახლავე, თვეში{" "}
            <span className="text-raspberry-100">მხოლოდ 15 ლარად</span>
          </h2>
          <span className="block w-[calc(100%-20px)] text-primarySize text-blackPrimary-60 font-regular mb-[60px] lg:mb-[40px] sm:text-[14px] lg:text-center">
            VITA ბარათით თქვენ მიიღებთ მუდმივმოქმედ ფასდაკლებას 200-ზე მეტ,
            კლინიკასა და სააფთიაქო ქსელში ულიმიტოდ და მთელი საქართველოს
            მასშტაბით.
          </span>
          <div className="lg:flex lg:justify-center">
            <VitaButton
              span="sm:justify-center"
              className="sm:w-full sm:flex sm:justify-center"
            />
          </div>
        </div>
      </div>
      <div className="absolute max-w-[823px] w-[40.86%] top-0 right-0 lg:hidden">
        <img
          className="w-full"
          src="/images/vitacards.png"
          alt="vita cards"
          width={602}
          height={419}
        />
      </div>
    </div>
  );
});

// 52.29
