import { memo, useRef } from "react";
import { Modal, ThemeProvider, createTheme } from "@mui/material";
import { Save } from "Components/Buttons/Save";
import { ReactSVG } from "react-svg";

const theme = createTheme({
  components: {
    MuiPopover: {
      defaultProps: {
        container: document.getElementById("root"),
      },
    },
    MuiPopper: {
      defaultProps: {
        container: document.getElementById("root"),
      },
    },
    MuiDialog: {
      defaultProps: {
        container: document.getElementById("root"),
      },
    },
    MuiModal: {
      defaultProps: {
        container: document.getElementById("root"),
      },
    },
  },
});

export const CongratsModal = memo(function CongratsModalComponent({
  open,
  close,
}: {
  open: boolean;
  close: () => void;
}) {
  const containerRef = useRef(null);
  return (
    <>
      <div ref={containerRef}></div>
      <ThemeProvider theme={theme}>
        <Modal
          open={open}
          onClose={close}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          container={containerRef.current}
          className="flex items-center justify-center"
        >
          <div className="max-w-[492px] bg-[#fff] rounded-[12px] w-full flex flex-col items-center py-[60px] gap-7 px-[42px]">
            <h2 className="font-bold text-blackPrimary-60 text-center">
              გილოცავთ ოჯახის წევრი წარმატებით დაემატა
            </h2>
            <ReactSVG src="/images/icons/partingface.svg" />
            <Save onClick={close} variant="green" label="დახურვა" />
          </div>
        </Modal>
      </ThemeProvider>
    </>
  );
});
