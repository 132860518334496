import { useEffect, useState } from "react";

/*
    THIS HOOK IS IMPORTANT FOR CATEGORIES PLACEMENT:
    in UI, we have clinics card, there are categories list
    categories length may be *N.(we dont know)
    we must show *x length of categories, only as much as will fit in the div
    so, in this hook we do very easy things. 
    when component renders, we show every categories for see how much every categories div width. 
    but we must hide this categories becouse block structure is disturbed
    when we know every categories div with, 
    now we can detect if we can show one or two categories div by adding them to each other
    if the value we get by adding two div is less then clinic card div width, we can show two categories
    otherwise we must show only one category div.
*/

export const useCategoriesPlacement = (
  cattypes: string[],
  blockref: React.RefObject<HTMLDivElement> | null,
  itemRefs: (React.RefObject<HTMLDivElement> | null)[]
) => {
  const [numOfCats, setNumOfCats] = useState(cattypes?.length);

  useEffect(() => {
    const calculateDimensions = () => {
      if (
        !blockref?.current ||
        !itemRefs[0]?.current ||
        !itemRefs[1]?.current
      ) {
        return;
      }

      const blockWidth = blockref.current.getBoundingClientRect().width;

      if (cattypes?.length > 1) {
        const totalWidth =
          itemRefs[0]?.current?.getBoundingClientRect().width +
          itemRefs[1]?.current?.getBoundingClientRect().width +
          45;

        blockWidth >= totalWidth ? setNumOfCats(2) : setNumOfCats(1);
      }
    };

    calculateDimensions();
  }, [itemRefs, cattypes]);

  return { numOfCats: numOfCats || 0 };
};
