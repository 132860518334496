import { useQuery } from "@tanstack/react-query";
import { API_REQUEST } from "Request/API";

export const useNotificationAccess = (isLogin: boolean) => {
  const {
    data: notifications,
    isError: isErrorNotifications,
    isLoading: isLoadingNotifications,
    isSuccess: isSuccessNotifications,
    refetch
  } = useQuery({
    queryKey: [`USER-Notfication-access`],
    queryFn: () => API_REQUEST("GET", `user/notification-access-type`),
    retry: 0,
    enabled: isLogin,
    refetchOnWindowFocus: false,
  });

  return {
    notifications,
    isErrorNotifications,
    isLoadingNotifications,
    isSuccessNotifications,
    refetch
  };
};
