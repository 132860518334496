import { memo, useCallback, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { VitaPercent } from "Components/Buttons/VitaPercent";
import { ReactSVG } from "react-svg";
import { LeftArrow } from "Components/SwiperButtons/LeftArrow";
import { RightArrow } from "Components/SwiperButtons/RightArrow";
import { Link } from "react-router-dom";

export const VitaSummerOffers = memo(function VitaSummerOffersComponents({
  offer,
}: any) {
  const sliderRef = useRef<any>(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <div className="container md:w-full md:max-w-full mt-[90px] lg:mt-[20px] mb-[160px] lg:mb-[60px]">
      <div className="flex md:px-[32px] sm:px-[16px] justify-between mb-[44px] items-center">
        <h2 className="font-bold text-[40px] lg:text-sloganLgSize md:text-sloganMdSize leading-[56px] lg:leading-[34px] md:leading-[24px] text-blackPrimary-100">
          {offer?.offer?.title}
        </h2>
        <div className="flex items-center gap-5 md:hidden">
          <LeftArrow handlePrev={handlePrev} />
          <RightArrow handleNext={handleNext} />
        </div>
      </div>
      <div className="flex h-[383px] lg:h-auto items-center gap-8">
        <div className="w-[336px] h-[383px] min-w-[336px] rounded-[16px] overflow-hidden lg:hidden">
          <img
            className="preventImgSelect object-cover h-full"
            src={offer?.offer?.imgUrl}
            alt="vita banner"
          />
        </div>
        <Swiper
          ref={sliderRef}
          spaceBetween={12}
          breakpoints={{
            300: {
              slidesPerView: 1.4,
            },
            600: {
              slidesPerView: 2.4,
            },
            700: {
              slidesPerView: 3.2,
            },
            832: {
              slidesPerView: 3,
            },
            1080: {
              slidesPerView: 4,
            },
          }}
          className="md:px-[32px] w-full"
        >
          {offer?.offerItems?.map((item: any, i: number) => {
            return (
              <SwiperSlide>
                <Link
                  className="min-h-[330px] customxlsize:min-h-[300px] xl:min-h-[260px] lg:min-h-[300px] flex flex-col gap-1"
                  to={item?.clinicId ? `/detailed/${item?.clinicId}` : "#"}
                  key={i}
                >
                  <div className="relative overflow-hidden  flex-1 flex h-full">
                    <div className="absolute z-10">
                      <VitaPercent percent={item?.discount} icon={true} />
                    </div>
                    <img
                      className="m-auto h-[270px] object-contain"
                      src={item?.imgUrl}
                      alt="proteinshake"
                    />
                  </div>
                  <h2 className="line-clamp-2 h-[47px] px-2 font-bold text-md text-blackPrimary-100 leading-[24px]">
                    {item.title}
                  </h2>
                </Link>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
});
