import { handleMember } from "Reducers/FamilyMembers";
import { useAppDispatch } from "Store/store";
import { memo } from "react";
import { ReactSVG } from "react-svg";

export const MemberCard = memo(function MemberCardComponent({
  member,
  handleRemoveMember,
}: {
  member?: any;
  handleRemoveMember?: () => void;
}) {
  const dispatch = useAppDispatch();

  return (
    <div className="bg-gray-30 md:flex-row md:justify-start md:gap-3 relative rounded-[12px] p-3 flex flex-col justify-center items-center">
      <div
        onClick={() => {
          dispatch(handleMember(member));
          handleRemoveMember && handleRemoveMember();
        }}
        className="absolute right-0 top-0 m-[12px] cursor-pointer md:top-[50%] md:translate-y-[-50%] md:m-0 md:mr-3"
      >
        <ReactSVG src="/images/icons/close.svg" />
      </div>
      <div className="bg-green-100 w-[48px] h-[48px] rounded-[50%] flex items-center justify-center overflow-hidden p-3">
        <ReactSVG src="/images/icons/avatar.svg" />
      </div>
      <div className="flex flex-col gap-1 mt-[6px]">
        <h3 className="text-rg font-demi text-blackPrimary-100 text-center">
          {member?.firstName}
        </h3>
        <span className="text-rg font-regular text-blackPrimary-60">
          {member?.phone}
        </span>
      </div>
    </div>
  );
});
