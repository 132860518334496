import { useQuery } from "@tanstack/react-query";
import { API_REQUEST } from "Request/API";

export const useOffersQuery = () => {
  const {
    data: offers,
    isError: isErrorOffers,
    isLoading: isLoadingOffers,
    isSuccess: isSuccessOffers,
  } = useQuery({
    queryKey: [`offers-section`],
    queryFn: () => API_REQUEST("GET", `clinic/offers`),
    retry: 0,
    refetchOnWindowFocus: false,
  });

  return {
    offers,
    isErrorOffers,
    isLoadingOffers,
    isSuccessOffers,
  };
};
