import { memo, useRef } from "react";
import { Modal } from "@mui/material";
import { BasicModalTypes } from "types/MembersModal";

export const SubscriptionInfo = memo(function SubscriptionInfoComponent({
  open,
  close,
}: BasicModalTypes) {
  const containerRef = useRef(null);

  return (
    <>
      <div className="absolute" ref={containerRef}></div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        container={containerRef.current}
        className="h-[100dvh] bottom-0 z-[9999] fixed w-full overflow-y-auto left-0"
      >
        <div className="max-h-[90%] outline-none h-auto rounded-t-[16px] absolute bottom-0 z-40 w-full overflow-y-auto bg-[#fff]">
          <div className="flex flex-col gap-6 rounded-[12px] py-[32px] px-[24px]">
            მომხმარებელი შეიძენს ვიტა ბარათის ყოველთვიურ გამოწერას. ამისათვის
            საჭიროა ყიდვის ინფორმაციის მხოლოდ ერთხელ შევსება და ბარათის
            დამახსოვრება. სისტემა ყოველ 30 კალენდარულ დღეში ავტომატურად ჩამოჭრის
            ვიტა ბარათის ღირებულებას. ვიტას გამოწერის გაუქმება შესაძლებელია
            ნებისმიერ დროს მომხმარებლის პროფილიდან.
          </div>
        </div>
      </Modal>
    </>
  );
});
