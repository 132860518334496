import { memo } from "react";
import { ReactSVG } from "react-svg";

export const CorporateBenefit = memo(function CorporateBenefitComponent() {
  return (
    <div className="flex gap-[5.625vw] relative max-w-[1920px] lg:container lg:px-[32px] sm:px-[16px] lg:pt-4 md:pt-0 m-auto items-center mb-[120px] md:mb-[60px]">
      <div className="w-[34.219vw] min-w-[657px] xl:min-w-[500px] lg:hidden md:hidden">
        <img
          className="object-cover w-full"
          src="/images/vitacorpbanner.png"
          alt="corporate_banner"
        />
      </div>
      <div>
        <h2 className="text-sloganSize lg:text-sloganLgSize md:text-sloganMdSize font-bold text-blackPrimary-100 leading-[56px]">
          ძირითადი სარგებელი
        </h2>
        <div className="mr-[6.26vw] xl:mr-[48px] md:mr-0">
          <div className="flex gap-4 sm:flex-col sm:gap-1 items-center md:items-start md:mb-[24px]">
            <div className="w-[44px] min-w-[44px] min-h-[44px] h-[44px] md:w-[24px] md:min-w-[24px] md:h-[24px] md:min-h-[24px]">
              <ReactSVG
                beforeInjection={(svg) => {
                  svg.setAttribute("style", "width: 100%; height: 100%;");
                }}
                src="/images/icons/corporatecheck.svg"
              />
            </div>
            <p className="font-medium text-lg lg:text-rg text-blackPrimary-100 leading-[25px] border-b-gray-100 border-b-[solid] md:border-0 border-b-[1px] py-[36px] md:py-0 lg:py-[36px] xl:py-[16px]">
              ორგანიზაციის თანამშრომლებს ვიტა უმარტივებს წვდომას ფიზიკური და
              მენტალური ჯანმრთელობისა და სილამაზის ხანგრძლივად შენარჩუნებისთვის
              საჭირო აუცილებელი სერვისებისა და პროდუქტებისკენ.
            </p>
          </div>
          <div className="flex gap-4 sm:flex-col sm:gap-1 items-center md:items-start">
            <div className="w-[44px] min-w-[44px] min-h-[44px] h-[44px] md:w-[24px] md:min-w-[24px] md:h-[24px] md:min-h-[24px]">
              <ReactSVG
                beforeInjection={(svg) => {
                  svg.setAttribute("style", "width: 100%; height: 100%;");
                }}
                src="/images/icons/corporatecheck.svg"
              />
            </div>
            <p className="font-medium text-lg lg:text-rg text-blackPrimary-100 leading-[25px] border-b-gray-100 md:border-0 border-b-[solid] border-b-[1px] py-[36px] md:py-0 lg:py-[36px] xl:py-[16px]">
              თანამშრომელი იღებს განსაკუთრებულ, 50 %-მდე ფასდაკლებებს 200+
              კლინიკის მომსახურებებსა და აფთიაქების პროდუქტებზე, ულიმიტოდ და
              მთელი საქართველოს მასშტაბით.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});
