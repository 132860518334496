import { memo } from "react";

export const ClinicMessage = memo(function ClinicMessage() {
  return (
    <div className="sm:mx-[16px] bg-raspberry-5 warningBox text-raspberry-100 text-rg sm:text-[12px] font-medium rounded-[12px] p-[24px]">
      გთხოვთ გაითვალისწინოთ, რომ ფასები მოწოდებულია კლინიკის მიერ და შესაძლოა არ
      ასახავდეს ბოლო განახლებას. ამიტომ, ჩაწერისას გადაამოწმეთ კლინიკაშიც.
      ნებისმიერ შემთხვევაში კლინიკის ფასებზე ყოველთვის იმოქმედებს ფასდაკლების ის
      პროცენტი, რაც VITA ბარათით არის განსაზღვრული.
    </div>
  );
});
