import { memo } from "react";
import { MenuItem } from "./MenuItem";
import { useTranslation } from "react-i18next";

export const Menu = memo(function MenuComponent() {
  const { t } = useTranslation("common");
  const menu = [
    {
      title: t("menu.all providers"),
      id: 0,
      href: "/",
    },
    {
      title: t("menu.For the medical sector"),
      id: 1,
      href: "/medsector",
    },
    {
      title: t("menu.corporate"),
      id: 2,
      href: "/corporate",
    },
  ];

  return (
    <div>
      <ul className="flex items-center gap-[14px] md:hidden">
        {menu?.map((item: any) => (
          <MenuItem item={item} key={item.id} />
        ))}
      </ul>
    </div>
  );
});
