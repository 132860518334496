import { memo } from "react";
import { ReactSVG } from "react-svg";

export const RightArrow = memo(function RightArrowComponent({
  handleNext,
}: {
  handleNext?: () => void;
}) {
  return (
    <div
      className="bg-[#fff] cursor-pointer rounded-[50%] w-[40px] h-[40px] swiperButtonShadow flex items-center justify-center"
      onClick={handleNext}
    >
      <ReactSVG src="/images/icons/arrowright.svg" />
    </div>
  );
});
