import { useQuery } from "@tanstack/react-query";
import { API_REQUEST } from "Request/API";

export const useMedicamentsQuery = (id: any) => {
  const {
    data: medicaments,
    isError: isErrorMedicaments,
    isLoading: isLoadingMedicaments,
    isSuccess: isSuccessMedicaments,
  } = useQuery({
    queryKey: [`medicaments-${id}`, [id]],
    queryFn: () => API_REQUEST("GET", `clinic/${id}/offer-item-products`, null),
    retry: 0,
    enabled: id !== null,
    refetchOnWindowFocus: false,
  });

  return {
    medicaments,
    isErrorMedicaments,
    isLoadingMedicaments,
    isSuccessMedicaments,
  };
};
