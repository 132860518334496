import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetchMembers } from "./fetchMembers";

export interface familyMemberSlice {
  members: any;
  memberId: any;
}

const initialState: familyMemberSlice = {
  members: [],
  memberId: null,
};

export const familyMembersSlice = createSlice({
  name: "familymemebrs",
  initialState,
  reducers: {
    createMember: (state, action) => {
      state.members = [...state.members, action.payload];
    },
    removeMember: (state, action) => {
      const filterMembers = state.members.filter(
        (e: any) => e.id !== action.payload
      );
      state.members = filterMembers;
    },
    handleMember: (state, action) => {
      state.memberId = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(fetchMembers.fulfilled, (state: any, action: any) => {
      state.members = action.payload.map((e: any) => ({
        ...e,
        isAproved: true,
        // ak booleans vartkavt users radgan ganvasxvavot jer ar daseivebuli memberebisgan
        // roca isAproved true aris da magis cashla gvinda, vgzavnit bekshi requests
        // tu user jer ar arsebobs, mag shemtxvevashi vikenebt removeMember funkcias da vshlit mxolod reduxis storidan
      }));
    });
  },
});

export const { createMember, removeMember, handleMember } =
  familyMembersSlice.actions;
export default familyMembersSlice.reducer;
