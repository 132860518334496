import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useCustomQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const useSlugs = () => {
  const { pathname } = useLocation();

  return useMemo(() => {
    // Remove leading and trailing slashes and split the pathname into slugs
    const slugs = pathname.replace(/^\/+|\/+$/g, "").split("/");
    return slugs;
  }, [pathname]);
};

export const useArrayQueries = () => {
  const { search } = useLocation();

  return useMemo(() => {
    const searchParams: any = new URLSearchParams(search);
    const customQuery: any = {};

    for (const [key, value] of searchParams.entries()) {
      if (customQuery[key]) {
        if (Array.isArray(customQuery[key])) {
          customQuery[key].push(value);
        } else {
          customQuery[key] = [customQuery[key], value];
        }
      } else {
        customQuery[key] = value;
      }
    }

    return customQuery;
  }, [search]);
};
