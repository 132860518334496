import { ThemeProvider } from "@emotion/react";
import { memo, useEffect, useState } from "react";
import { theme } from "./TextFieldTheme";
import { InputAdornment, TextField } from "@mui/material";

export const MobileInput = memo(function MobileInputComponent({
  className = "bg-[#fff!important]",
  value: defaultValue,
  onChange,
  onBlur,
  disabled = false,
}: {
  className?: string;
  value?: string;
  onChange?: any;
  onBlur?: any;
  disabled?: boolean;
}) {
  const [value, setValue] = useState(defaultValue); // Initial value without spaces

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value.replace(/\D/g, "");
    if (inputValue.length > 9) {
      inputValue = inputValue.slice(0, 9);
    }
    let formattedValue = "";
    for (let i = 0; i < inputValue.length; i += 3) {
      formattedValue += inputValue.substr(i, 3) + " ";
    }
    formattedValue = formattedValue.trim();
    // insert onchange function from parent element
    setValue(formattedValue);
    onChange(inputValue.trim());
  };

  useEffect(() => {
    if (defaultValue == "") {
      setValue("");
    }
  }, [defaultValue]);

  return (
    <ThemeProvider theme={theme}>
      <TextField
        label="მობილურის ნომერი"
        variant="filled"
        placeholder="XXX XXX XXX"
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        InputProps={{
          startAdornment: (
            <div className="mt-[7px] ml-2 text-rg mr-[-10px] z-10">+995</div>
          ),
        }}
        className={`${className}`}
        disabled={disabled}
      />
    </ThemeProvider>
  );
});
