import { memo, useRef } from "react";
import { Modal } from "@mui/material";
import { ReactSVG } from "react-svg";
import { VitaPercent } from "Components/Buttons/VitaPercent";

export const MedicamentsById = memo(function MedicamentsByIdComponent({
  open,
  close,
  closeEveryModal,
  list,
}: {
  open: boolean;
  close: () => void;
  closeEveryModal: () => void;
  list?: any;
}) {
  const containerRef = useRef(null);
  return (
    <>
      <div ref={containerRef}></div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        container={containerRef.current}
        className="flex items-center justify-center"
      >
        <div className="w-[644px] sm:w-[100dvh] sm:max-w-full sm:max-h-full sm:rounded-[0px] sm:h-[100dvh] overflow-hidden flex flex-col p-[24px] sm:p-[12px] relative max-w-[90%] max-h-[90%] h-[604px] bg-[#fff] rounded-[12px] outline-none">
          <div className="mb-[24px] flex justify-between items-center">
            <div
              onClick={close}
              className="cursor-pointer w-[44px] h-[44px] bg-gray-30 rounded-[50%] flex items-center justify-center"
            >
              <ReactSVG src="/images/pharmacy/closemodal.svg" />
            </div>
            <h2 className="mr-auto ml-4 font-bold text-[20px] text-blackPrimary-100">
              {list?.data[0]?.headerText}
            </h2>
            <div
              onClick={closeEveryModal}
              className="cursor-pointer w-[44px] h-[44px] bg-gray-30 sm:bg-gray-100 sm:rounded-[8px] rounded-[50%] flex items-center justify-center"
            >
              <ReactSVG src="/images/icons/close.svg" />
            </div>
          </div>
          <div className="customScrolllbar overflow-y-auto h-[100vh-40px]">
            {list?.data?.map((item: any, index: number) => (
              <div
                className="flex items-center gap-4 py-[16px] border-b-[1px] border-solid border-b-gray-30"
                key={index}
              >
                <div className="rounded-[12px] w-[64px] min-w-[64px] h-[64px] bg-[#fff] border-[1px] border-solid border-[#070A141A] flex items-center justify-center">
                  <img className="contain" src={item?.image} />
                </div>
                <div className="flex gap-1 flex-wrap">
                  <h2 className="text-blackPrimary-60 font-demi text-md">
                    {item?.title}
                  </h2>
                </div>
                <div className="ml-auto">
                  <VitaPercent icon={false} percent={item?.discountNumber} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
});
