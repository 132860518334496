import { memo } from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

export const BasicBreadcrumbs = memo(function BreadCrumbsComponent({
  breadcrumbs,
}: {
  breadcrumbs: { title: any; link: string }[];
}) {
  return (
    <div role="presentation" className="mb-[16px] sm:mb-0">
      <Breadcrumbs
        separator={<ReactSVG src="/images/icons/breadcrumbicon.svg" />}
        aria-label="breadcrumb"
      >
        <Link
          className="font-demi sm:text-sm text-blackPrimary-60"
          color="inherit"
          to="/"
        >
          მთავარი
        </Link>
        {breadcrumbs?.map((item, i) =>
          i >= breadcrumbs.length - 1 ? (
            <Typography
              key={i}
              className="font-demi sm:text-sm text-blackPrimary-100"
              color="text.primary"
            >
              {item.title}
            </Typography>
          ) : (
            <Link
              className="font-demi sm:text-sm text-blackPrimary-60"
              key={i}
              color="inherit"
              to={item.link}
            >
              {item.title}
            </Link>
          )
        )}
      </Breadcrumbs>
    </div>
  );
});
